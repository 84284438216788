<template>
	<div id="lastUpdateTime" class="last-update-time">
		<img id="cloud-save" :src="getSaveIcon" alt="" />
		<b-popover target="cloud-save" triggers="hover" placement="top" custom-class="cloud-saved-information">
			<template #title
				><div class="title-saved">
					<span>Todas alterações foram salvas na nuvem em {{ convertTime(LastSavedTime) }}</span>
				</div></template
			>
			<span v-if="getLastAutomaticBackup" style="font-weight: bold">
				Última versão disponível para restauração foi salva automaticamente em
				{{ convertTime(getLastAutomaticBackup) }} <br />
			</span>
			<span v-if="getSaveState == 'local_saved'">
				'As alterações foram salvas neste dispositivo e serão sincronizadas na nuvem quando você se reconectar. É
				possível recuperar versões antigas em: <br />Menu > Arquivo >
				<span class="link" @click="openBackups"><span>Ver histórico de versões</span></span
				>'.
			</span>
			<span v-else>
				É possível recuperar versões antigas deste trabalho através do menu: <br />Menu > Arquivo ><span
					@click="openBackups"
					>Ver histórico de versões</span
				>. <br />Uma nova versão do seu progresso será salva automaticamente a cada 10 minutos. Se você preferir, também
				pode salvar manualmente através do mesmo menu.'
			</span>
		</b-popover>
	</div>
</template>

<script>
// Images
import LoadingSaved from '../../../public/assets/loading_save.png';
import LocalSaved from '../../../public/assets/local_saved.png';
import CloudSaved from '../../../public/assets/cloud_saved.png';

export default {
	data() {
		return {
			connected: false,
		};
	},
	computed: {
		LastSavedTime() {
			return (
				this.$store.getters['firebase/getLastTimeSaved'] || this.$store.getters['firebase/getTask'].last_saved_date
			);
		},
		getLastAutomaticBackup() {
			return this.$store.getters['getLastAutomaticBackup'];
		},
		getRequestToSave() {
			return this.$store.getters['getRequestToSave'];
		},
		getSaveIcon() {
			if (this.getSaveState == 'cloud_saved') return CloudSaved;
			if (this.getSaveState == 'local_saved') return LocalSaved;
			if (this.getSaveState == 'loading_saved') return LoadingSaved;
			return CloudSaved;
		},
		getSaveState() {
			if (this.getRequestToSave) return 'loading_saved';
			else return this.connected == true ? 'cloud_saved' : 'local_saved';
		},
		getMsg() {
			if (this.getSaveState == 'cloud_saved') return this.saveInCloudMsg;
			if (this.getSaveState == 'local_saved') return this.saveInLocalMsg;
			else return this.saveInCloudMsg;
		},
	},
	async mounted() {
		this.connected = await this.checkOnlineStatus();
		setInterval(async () => {
			this.connected = await this.checkOnlineStatus();
		}, 10000); // probably too often, try 30000 for every 30 seconds
	},
	methods: {
		convertTime(time) {
			if (!time) return 'N/D';
			let date = new Date(time);
			return date.toLocaleString();
		},
		async checkOnlineStatus() {
			try {
				const online = await fetch('/assets/verifyConnectionRequest.png');
				return online.status >= 200 && online.status < 300;
			} catch (err) {
				return false;
			}
		},
		openBackups() {
			this.$bvModal.show('backups-modal');
		},
	},
};
</script>

<style lang="scss" scoped>
.last-update-time {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: auto;
	height: 100%;
	margin-right: 20px;
	min-width: 50px;
	cursor: pointer;
	i {
		margin-right: 10px;
		color: rgb(240, 240, 240);
	}
}

.cloud-saved-information {
	min-width: 450px !important;
	min-height: 200px !important;
	border-radius: 5px;
	text-align: left !important;

	.title-saved {
		width: auto;
		height: 100%;
		background: #dabdff;
		border-radius: 3px;
		padding: 5px 5px;
		color: #481b82;
		font-weight: bold;
	}
}
</style>
