<template>
	<div class="segments-resume">
		<span v-b-tooltip.hover title="Segmentos confirmados">
			<i class="fa fa-check-circle" aria-hidden="true"></i>
			<span>{{ getConfirmedSegments }} / {{ getSubtitlesLength }}</span>
		</span>

		<span v-b-tooltip.hover title="Segmentos com avisos">
			<i class="fa fa-exclamation-circle" aria-hidden="true"></i>
			<span>{{ getWarningSegments }}</span>
		</span>

		<span v-b-tooltip.hover title="Segmentos com erros críticos">
			<i class="fa fa-exclamation-circle red" aria-hidden="true"></i>
			<span>{{ getCriticalSegments }}</span>
		</span>
	</div>
</template>

<script>
export default {
	computed: {
		getAllSegments() {
			return this.$store.getters['firebase/getAllSubtitles'];
		},
		getConfirmedSegments() {
			return this.getAllSegments.filter((segment) => {
				return segment.is_saved;
			}).length;
		},
		getWarningSegments() {
			return this.getAllSegments.filter((segment) => {
				if (segment.errors != undefined) {
					return segment.errors.type == 'warning';
				} else {
					return false;
				}
			}).length;
		},
		getCriticalSegments() {
			return this.getAllSegments.filter((segment) => {
				if (segment.errors != undefined) {
					return segment.errors.type == 'danger';
				} else {
					return false;
				}
			}).length;
		},
		getSubtitlesLength() {
			return this.$store.getters['firebase/getSubtitlesLength'];
		},
	},
};
</script>

<style lang="scss" scoped>
.segments-resume {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: auto;

	span {
		margin-right: 10px;
		width: auto;
		i {
			margin: 0px 5px;
		}
	}

	.fa-check-circle {
		color: #4bad3e;
	}

	.red {
		color: #e43e3e !important;
	}

	.fa-exclamation-circle {
		color: #dee04b;
	}
}
</style>
