<template>
	<div id="caracters-per-line" :class="getStyle">
		<div v-for="caracterInLine in calculateCaracteresPerLine" :key="caracterInLine.line">
			<span :id="`lineCalculate-${caracterInLine.line}`" :class="getErrorClass(caracterInLine.totalCaracteres)">
				L{{ caracterInLine.line }}: {{ caracterInLine.totalCaracteres }}
				<b-tooltip
					v-if="caracterInLine.totalCaracteres > MAX_CARACTERES_PER_LINE"
					:target="'lineCalculate-' + caracterInLine.line"
					:title="`A quantidade de caracteres na linha é maior que o recomendado.\nCaracteres por linha recomendado: ${MAX_CARACTERES_PER_LINE}`"
					triggers="hover"
					placement="top"
				/>
			</span>
		</div>
	</div>
</template>

<script>
import textTools from '../../utils/textTools';
import { MAX_CARACTERES_PER_LINE } from '../../configs/rules';

export default {
	props: {
		text: {
			type: String,
			default: 'teste',
		},
		className: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			MAX_CARACTERES_PER_LINE: MAX_CARACTERES_PER_LINE,
		};
	},
	computed: {
		getStyle() {
			return this.className;
		},
		calculateCaracteresPerLine() {
			return textTools.totalCaractersPerLine(this.text).slice(0, 2);
		},
	},
	methods: {
		getErrorClass(caracterInLine) {
			if (caracterInLine > MAX_CARACTERES_PER_LINE) {
				return 'invalid';
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../app.scss';

span {
	@include glass;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	width: 100%;
	height: 25%;
	margin-bottom: 10%;
	font-size: 11px;
	color: $font-color;
	font-weight: bold;
	margin-left: 3px;

	&:hover {
		cursor: pointer;
	}
}

.invalid {
	background-color: $invalid;
}
</style>
