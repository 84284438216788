<template>
	<div>
		<div id="segmento-id" class="segment-id-info">
			<span>Id: {{ segment.id }}</span>
		</div>

		<div class="save-segment-info">
			<button id="bookmark" :class="getBookmarkStyle" aria-hidden="true" @click="setBookmark"></button>
			<button id="confirm-button" :class="getConfirmButtonStyle" aria-hidden="true" @click="setConfirmSelect">
				<b-tooltip
					id="confirm-button"
					target="confirm-button"
					:title="segment.is_saved ? 'Desconfirmar segmento' : 'Confirmar segmento'"
					triggers="hover"
					placement="top"
				/>
			</button>
			<button
				v-if="getIsReviewQA && getReviewdByQa"
				id="report-error"
				class="fa fa-bug desactive"
				aria-hidden="true"
				@click="openFeedbackModal"
			>
				<b-tooltip id="report-error" target="report-error" :title="'Reportar erro'" triggers="hover" placement="top" />
			</button>

			<b-tooltip
				id="bookmark"
				target="bookmark"
				:title="segment.is_bookmark ? 'Deletar do bookmark' : 'Adicionar ao bookmark'"
				triggers="hover"
				placement="top"
			/>
		</div>

		<div class="save-segment-info">
			<SelectSegmentPosition v-if="getIsEmbedSubtitles" />
			<add-watermark v-if="getIsEmbedSubtitles && getReviewdByQa" />
		</div>
	</div>
</template>

<script>
import SelectSegmentPosition from './SelectSegmentPosition.vue';
import AddWatermark from './AddWatermark.vue';

export default {
	components: {
		SelectSegmentPosition,
		AddWatermark,
	},
	props: {
		segment: {
			type: Object,
			required: true,
		},
	},
	computed: {
		getBookmarkStyle() {
			return this.segment.is_bookmark ? 'fa fa-bookmark active' : 'fa fa-bookmark desactive';
		},
		getConfirmButtonStyle() {
			return this.segment.is_saved ? 'fa fa-check-circle active-confirm' : 'fa fa-check-circle desactive';
		},
		getErrorButtonStyle() {
			return this.segment.is_saved ? 'fa fa-check-circle active-confirm' : 'fa fa-check-circle desactive';
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getIsReviewQA() {
			return this.$store.getters['firebase/getIsReviewQA'];
		},
		getReviewdByQa() {
			return this.$store.getters['firebase/getReviewdByQa'];
		},
		getIsEmbedSubtitles() {
			return this.$store.getters['firebase/getisEmbedSubtitles'];
		},
		getShortcuteEvent: {
			get() {
				return this.$store.getters['controller/getShortcuteEvent'];
			},
			deep: true,
		},
	},
	watch: {
		getShortcuteEvent(event) {
			if (event.eventKey == 'addBookmark') {
				this.setBookmark();
			}
		},
	},
	methods: {
		setBookmark() {
			const bookmark = !this.segment.is_bookmark;
			this.$store.dispatch('firebase/setBookmarkSegment', {
				segment: this.segment,
				bookmark,
			});
		},
		setConfirmSelect() {
			this.$store.dispatch('controller/saveSegment');
		},
		openFeedbackModal() {
			this.$store.dispatch('feedback/openFeedbackModal');
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin glass {
	background: rgba(99, 97, 97, 0.411);
	border-radius: 5px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(7.6px);
	-webkit-backdrop-filter: blur(7.6px);
	border: 1px solid rgba(0, 0, 0, 0.97);
}

.segment-id-info {
	display: flex;
	justify-content: flex-start;
	box-sizing: border-box;
	align-items: center;
	font-weight: bold;
	width: 80%;
	height: 20%;
	font-size: 12px;
	padding: 5%;
	@include glass;
}

.save-segment-info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 25%;
	margin-top: 5%;
	padding: 0px 0px 0px 5px;

	button {
		@include glass;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30%;
		height: 100%;
		margin-right: 5px;

		&::before {
			transform: scale(1.2);
		}
	}

	.active {
		color: #ffc107;
	}

	.active-confirm {
		color: #4bad3e;
	}

	.desactive {
		color: rgb(255, 255, 255);
	}
}
</style>
