import Vue from 'vue';
import VueRouter from 'vue-router';

const LayoutManager = () => import('../components/LayoutManager.vue');
const Unauthorized = () => import('../components/Unauthorized.vue');
const Finalize = () => import('../components/Finalize.vue');
const Page404 = () => import('../components/404.vue');

Vue.use(VueRouter);

const routes = [
	{ path: '/' },
	{ path: '/editor', component: LayoutManager },
	{ path: '/unauthorized', component: Unauthorized },
	{ path: '/finalize', component: Finalize },
	{ path: '*', component: Page404 },
];

const router = new VueRouter({
	mode: 'history',
	routes, // short for `routes: routes`
});

export default router;
