<template>
	<div class="player-wrapper">
		<div v-if="loading" class="loading">
			<b-spinner variant="light" class="mb-3"></b-spinner>
			<span class="text-light"> Carregando o vídeo... </span>
		</div>

		<div v-show="!loading">
			<video
				id="videoRef"
				ref="video"
				oncontextmenu="return false;"
				controls
				controlsList="nodownload"
				:src="media_url"
			></video>
		</div>
	</div>
</template>

<script>
export default {
	components: {},

	props: {
		media_url: {
			type: String,
			required: true,
		},
		playing: {
			type: Boolean,
			default: false,
		},
		/**
		 * Current video time, in milliseconds.
		 */
		currentTime: {
			type: Number,
			default: 0,
		},
		subtitle: {
			type: Object,
			default: null,
		},
		current_language: {
			type: String,
			default: '',
		},
		is_translate: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			loading: false,
			track: null,
			cue: null,
			speed: '1.0',
			playPromise: null,
		};
	},

	computed: {
		video() {
			if (!this.$refs.video) return null;
			return this.$refs.video;
		},
	},

	watch: {
		playing(newValue) {
			if (newValue && this.video.paused) {
				this.playPromise = this.video.play();
				this.playPromise.finally(() => {
					this.playPromise = null;
				});
			} else if (!newValue) {
				if (this.playPromise) {
					this.playPromise.then(() => {
						this.video.pause();
					});
				} else {
					this.video.pause();
				}
			}
		},

		currentTime(newValue) {
			if (this.video) this.video.currentTime = parseInt(newValue) / 1000;
		},

		subtitle(newValue) {
			if (newValue) this.insertNewCuePoint(newValue);
		},
	},

	mounted() {
		this.loading = true;
		// TODO treats error on londed or some other video event
		this.$refs.video.addEventListener(
			'loadeddata',
			(e) => {
				this.loading = false;
				this.$store.commit('video/setDuration', this.$refs.video.duration);

				this.track = this.video.addTextTrack('subtitles', this.current_language, this.current_language);
				this.track.mode = 'showing';

				this.video.currentTime = this.currentTime / 1000;

				this.$emit('loadeddata', e);
			},
			false
		);

		this.$refs.video.addEventListener('timeupdate', (e) => {
			this.$emit('timeupdate', e);
		});

		this.$refs.video.addEventListener('seeking', (e) => {
			this.$emit('seeking', e);
		});
		this.$refs.video.addEventListener('seeked', (e) => {
			this.$emit('seeked', e);
		});

		this.$refs.video.addEventListener('waiting', (e) => {
			this.$emit('waiting', e);
		});
		this.$refs.video.addEventListener('seeking', (e) => {
			this.$emit('seeking', e);
		});
		this.$refs.video.addEventListener('playing', (e) => {
			this.$emit('play', e);
		});
		this.$refs.video.addEventListener('pause', (e) => {
			this.$emit('pause', e);
		});
	},
	methods: {
		insertNewCuePoint(subtitle) {
			if (this.cue) {
				this.track.removeCue(this.cue);
				this.cue = null;
			}

			if (!this.track) return;

			const startSec = subtitle.start / 1000;
			const endSec = subtitle.end / 1000;
			const text = this.is_translate ? subtitle.translate_text : subtitle.text;

			this.cue = new VTTCue(startSec, endSec, text);
			this.cue.line = -3;

			this.track.addCue(this.cue);
		},

		changeSpeed() {
			this.video.playbackRate = this.speed;
		},
	},
};
</script>

<style lang="scss" scoped>
.player-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	max-height: 90%;

	.loading {
		min-height: 360px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.overlay >>> div {
		margin-bottom: 60px !important;
	}
}

video {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-height: 360px;
}

video::cue {
	line-height: 20px;
	margin-bottom: 20px;
}

.vid-speed {
	background-color: #202837;
	color: white;
	font-weight: bold;
	font-size: 12px;
}

option {
	background-color: #202837;
	color: white;
	font-weight: bold;
	font-size: 12px;
}

.select,
span {
	padding-left: 10px;
}

.video-control {
	display: flex;
	justify-content: flex-end;
	padding: 5px 15px;
	background-color: #293855;
	color: #f5f5f5;
}
</style>
