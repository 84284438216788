<template>
	<div id="layout-modal" class="row p-0 m-0">
		<div class="col-12">
			<b-row class="mt-5"> <span class="title-settings">Seleção do layout base: </span> </b-row>
			<b-row class="mt-3">
				<b-col>
					<b-img
						thumbnail
						fluid
						src="../../../public/static/layout-1.png"
						alt="Image 1"
						:class="layoutSelect == 1 ? 'selected' : ''"
						@click="setLayoutSelect(1)"
					></b-img>
					<span>Layout 1</span><br />
				</b-col>
				<b-col>
					<b-img
						thumbnail
						fluid
						src="../../../public/static/layout-2.png"
						alt="Image 2"
						:class="layoutSelect == 2 ? 'selected' : ''"
						@click="setLayoutSelect(2)"
					></b-img>
					<span>Layout 2</span><br />
				</b-col>
				<b-col>
					<b-img
						thumbnail
						fluid
						src="../../../public/static/layout-3.png"
						alt="Image 3"
						:class="layoutSelect == 3 ? 'selected' : ''"
						@click="setLayoutSelect(3)"
					></b-img>
					<span>Layout 3</span><br />
				</b-col>
			</b-row>
			<div id="save-area" class="row">
				<button @click="save">Salvar</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			layoutSelect: 1,
		};
	},
	computed: {
		userPreferences() {
			return this.$store.getters['firebase/getUserPreference'];
		},
	},
	mounted() {
		this.layoutSelect = this.userPreferences !== null ? this.userPreferences.layoutSelect : 1;
	},
	methods: {
		setLayoutSelect(layoutId) {
			this.layoutSelect = layoutId;
		},
		save() {
			// save in localStorage
			let userPreferences = {
				...this.userPreferences,
				layoutSelect: this.layoutSelect,
			};

			let response = this.$store.dispatch('firebase/setUserPreferences', userPreferences);
			if (response == false) {
				alert('Erro ao salvar preferências!');
			} else {
				alert('Dados salvos com sucesso.');
				this.$emit('aplyLayout', this.layoutSelect);
			}
		},
	},
};
</script>

<style lang="scss">
@import '../../app.scss';

#layout-modal {
	color: #fff;
	height: 100%;
}

.col {
	text-align: center;
	font-size: 16px;
	font-weight: bold;
}

.alert {
	font-size: 12px;
	background-color: $color-auxiliary;
	color: #fff;
}

.img-thumbnail {
	background-color: transparent !important;
	border: none !important;

	&:hover {
		cursor: pointer;
	}
}

.selected {
	background-color: $background-scrollbar !important;
}
</style>
