<template>
	<div class="screen">
		<div id="tutorial-aux" class="msg-tutorial"></div>
		<b-tooltip target="tutorial-aux" :placement="toolTipsPosition" :delay="0" triggers="">
			<div class="step-tutorial">
				<div class="step-tutorial-title">
					{{ title }}
				</div>

				<div class="step-tutorial-msg">
					{{ textStep }}
				</div>
				<div class="step-tutorial-btn">
					<div class="step-tutorial-btn-left">
						<button @click="closeTutorial">
							<i class="fa fa-times-circle" aria-hidden="true"></i>
							Sair
						</button>
					</div>

					<div class="step-tutorial-btn-rigth">
						<button v-if="currentStep > 0" @click="before">
							<i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
							Voltar
						</button>

						<button v-if="currentStep < steps.length - 1" @click="next">
							Próximo
							<i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
						</button>
						<button v-if="currentStep == steps.length - 1" @click="closeTutorial">
							Finalizar
							<i class="fa fa-check-circle" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			</div>
		</b-tooltip>

		<canvas id="tutorial"></canvas>
	</div>
</template>

<script>
import Steps from './steps.js';

export default {
	components: {},
	data() {
		return {
			ctx: null,
			canvas: null,
			divSelect: null,
			textStep: '',
			title: '',
			toolTipsPosition: 'top',
			steps: Steps,
			currentStep: -1,
			showTutorial: false,
		};
	},
	watch: {
		async currentStep() {
			let currentStep = this.steps[this.currentStep];

			if (currentStep.function) {
				await new Promise((render) => setTimeout(render, 600)); // Create delay to render hidden components
				await currentStep.function(this.$store);
			}

			this.textStep = currentStep.text;
			this.title = currentStep.title;
			this.toolTipsPosition = currentStep.tooltip;

			this.selectDiv(currentStep.divId);
			this.drawScreen();
		},
	},
	mounted() {
		this.canvas = document.getElementById('tutorial');
		this.ctx = this.canvas.getContext('2d');

		// set canvas size
		this.canvas.width = window.innerWidth;
		this.canvas.height = window.innerHeight;
		this.currentStep = 0;

		this.drawScreen();
	},
	methods: {
		drawScreen() {
			this.clearScreen();
			this.drawDivToShow();
			setTimeout(() => {
				this.$root.$emit('bv::show::tooltip', 'tutorial-aux');
			}, 50);
		},
		clearScreen() {
			this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.ctx.fillStyle = 'rgba(0, 0, 0, 0.6)';
			this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
		},
		drawDivToShow() {
			if (this.divSelect == null) return;

			this.ctx.fillStyle = 'rgba(231, 17, 17, 0.856)';
			this.ctx.fillRect(
				this.divSelect.left - 5,
				this.divSelect.top - 5,
				this.divSelect.width + 10,
				this.divSelect.height + 10
			);
			this.ctx.clearRect(this.divSelect.left, this.divSelect.top, this.divSelect.width, this.divSelect.height);
			this.ctx.strokeRect(this.divSelect.left, this.divSelect.top, this.divSelect.width, this.divSelect.heigh);
		},
		selectDiv(divId) {
			let divAux = document.getElementById('tutorial-aux');
			let divToSelect = document.querySelector(`${divId}`);
			let rect = divToSelect.getBoundingClientRect();
			let rectObject = {
				top: rect.top,
				left: rect.left,
				width: rect.width,
				height: rect.height,
			};
			this.divSelect = rectObject;

			divAux.style.top = rectObject.top + 'px';
			divAux.style.left = rectObject.left + 'px';
			divAux.style.width = rectObject.width + 'px';
			divAux.style.height = rectObject.height + 'px';
		},
		next() {
			if (this.currentStep < this.steps.length - 1) {
				this.currentStep++;
			}
		},
		before() {
			if (this.currentStep > 0) {
				this.currentStep--;
			}
		},
		closeTutorial() {
			this.$emit('close');
		},
	},
};
</script>

<style lang="scss" scoped>
.screen {
	width: 100vw;
	height: 100vh;
	padding: 0;
	overflow: hidden;
	top: 0;
	left: 0;
	position: fixed;
	z-index: 100;

	canvas {
		z-index: 100;
	}
}

.msg-tutorial {
	display: none;
	display: flex;
	flex-direction: column;
	width: 100px;
	height: 100px;
	padding: 0;
	overflow: hidden;
	top: 0;
	left: 0;
	position: absolute;
	z-index: 101;
}

.step-tutorial {
	display: flex;
	height: auto;
	width: auto;
	max-width: 300px;
	flex-direction: column;
	border: 1px solid rgba(204, 204, 204, 0.377);
	border-radius: 5px;
	padding: 10px;
	z-index: 101;

	.step-tutorial-title {
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.step-tutorial-msg {
		text-align: left;
		word-wrap: break-word;
		font-size: 14px;
	}

	.step-tutorial-btn {
		bottom: 0;
		display: flex;
		flex-direction: row;
		padding: 5px;
		text-align: left;
		font-size: 14px;
		margin-top: 20px;

		button {
			border: none;
			background-color: transparent;
			color: rgb(191, 193, 196);
			font-weight: bold;
			&:hover {
				color: rgb(231, 17, 17);
				transform: scale(1.2);
			}
		}

		.step-tutorial-btn-left {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			min-width: 30%;
		}

		.step-tutorial-btn-rigth {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			min-width: 70%;
			button {
				margin-left: 10px;
			}
		}
	}
}
</style>
