<template>
	<div class="automatic-save-container"></div>
</template>

<script>
import { BACKUPS_INTERVAL } from '../configs/rules';
export default {
	data() {
		return {
			actualBackup: 0,
		};
	},
	computed: {
		getSubtitles() {
			return this.$store.getters['firebase/getSubtitles'];
		},
		getCurrentIndex() {
			return this.$store.getters['controller/getCurrentIndex'];
		},
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getManualBackupFlag() {
			return this.$store.getters['getManualBackupFlag'];
		},
	},
	watch: {
		getManualBackupFlag() {
			this.generateManualBackup();
		},
	},
	mounted() {
		let DB_NAME = this.getTask.id;
		let request = window.indexedDB.open(DB_NAME, 1);
		request.onupgradeneeded = function (event) {
			let db = event.target.result;
			if (!db.objectStoreNames.contains('backups')) {
				db.createObjectStore('backups', { keyPath: 'id' });
			}
			if (!db.objectStoreNames.contains('manualBackups')) {
				db.createObjectStore('manualBackups', { keyPath: 'id', autoIncrement: true });
			}
		};

		request.onsuccess = async (event) => {
			let db = event.target.result;
			window.db_task = db;

			setInterval(() => {
				this.generateAutomaticBackup(db);
			}, 60000 * BACKUPS_INTERVAL);
		};

		request.onerror = function () {
			console.error('Não foi possivel abrir o indexDB');
		};
	},
	methods: {
		async generateAutomaticBackup(db) {
			let currentSegment = this.getCurrentIndex;
			let allSegments = this.getSubtitles;

			let timeOfBackup = Date.now();
			let backup = {
				id: String(Date.now()),
				currentSegment,
				allSegments,
				time: timeOfBackup,
			};

			await this.$store.dispatch('firebase/saveAutomaticBackup', backup);

			let transaction = db.transaction('backups', 'readwrite');
			let objectStore = transaction.objectStore('backups');
			let request = objectStore.put(backup);
			request.onsuccess = () => {
				this.sendFlagToUpdateModal();
				this.$store.dispatch('setLastAutomaticBackup', timeOfBackup);
			};
			request.onerror = function () {
				console.error('Não foi possivel gerar o backup...');
			};
		},
		generateManualBackup() {
			let currentSegment = this.getCurrentIndex;
			let allSegments = this.getSubtitles;

			let timeOfBackup = Date.now();
			let backup = {
				currentSegment,
				allSegments,
				time: timeOfBackup,
			};

			let transaction = window.db_task.transaction('manualBackups', 'readwrite');
			let objectStore = transaction.objectStore('manualBackups');
			let request = objectStore.put(backup);
			request.onsuccess = () => {
				this.sendFlagToUpdateModal();
				alert('Backup salvo!');
			};
			request.onerror = function () {
				console.error('Não foi possivel gerar o backup...');
			};
		},
		sendFlagToUpdateModal() {
			this.$store.dispatch('updateBackups');
		},
	},
};
</script>

<style></style>
