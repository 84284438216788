<template>
	<div id="synchronyMode-button" class="synchrony-mode">
		<div class="synchrony-mode-button" @click="setSynchronyMode">
			<span>Modo sincronia</span>
			<div :class="buttonStyle"></div>
		</div>
		<div v-if="getRevisionMode" class="synchrony-mode-button" @click="setRevisionMode">
			<b-button>Sair do modo revisão (teatro)</b-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			buttonStyle: 'circleDesactive',
			startTimeSave: null,
			endTimeSave: null,
			subtitleFind: null,
		};
	},
	computed: {
		getSynchronyMode() {
			return this.$store.getters['controller/getSynchronyMode'];
		},
		getRevisionMode() {
			return this.$store.getters['controller/getRevisionMode'];
		},
		current_video_time: function () {
			return this.$store.getters['video/getUpdateTime'];
		},
		getCurrentIndex() {
			return this.$store.getters['controller/getCurrentIndex'];
		},
		getSubtitles() {
			return this.$store.getters['firebase/getSubtitles'];
		},
	},
	watch: {
		current_video_time(time) {
			if (!this.getSynchronyMode) return;

			//if time beetween start and end time, return false
			if (time >= this.startTimeSave && time <= this.endTimeSave) {
				return this.subtitleFind;
			}

			let sub = this.getSubtitles.find((subtitle) => {
				let subtitleFind = subtitle.start < time && subtitle.end > time;
				if (subtitleFind) {
					this.startTimeSave = subtitle.start;
					this.endTimeSave = subtitle.end;
					this.subtitleFind = subtitle;
				}
				return subtitleFind;
			});

			if (sub) {
				let currentSegment = this.$store.getters['controller/getCurrentSegment'];
				if (sub.id == currentSegment.id) return;
				this.$store.commit('controller/setCurrentIndex', sub.id);
			}
		},
		getSynchronyMode(value) {
			if (value) {
				this.buttonStyle = 'circleActive';
			} else {
				this.buttonStyle = 'circleDesactive';
			}
		},
	},
	methods: {
		setSynchronyMode() {
			this.buttonStyle == 'circleDesactive'
				? (this.buttonStyle = 'circleActive')
				: (this.buttonStyle = 'circleDesactive');
			this.$store.dispatch('controller/setSynchronyMode');
		},
		setRevisionMode() {
			this.$store.dispatch('controller/setRevisionMode');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../app.scss';

.synchrony-mode {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;
	width: 100%;
	z-index: 5;
}

.synchrony-mode-button {
	@include fontConfig;
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 100%;
	margin-left: 10px;
	margin-right: 10px;
	background: none;
	border: none;

	span {
		width: auto;
	}

	&:hover {
		cursor: pointer;
	}
}

.circleActive {
	width: 25px;
	max-width: 25px;
	height: 25px;
	max-height: 25px;
	border-radius: 50%;
	background-color: rgb(236, 45, 45);
	margin-left: 10px;
	box-shadow: 0 0 3px #dbcece, inset 0 0 3px;
	animation: onButton 0.5s;
}

.circleDesactive {
	width: 25px;
	max-width: 25px;
	height: 25px;
	max-height: 25px;
	border-radius: 50%;
	background-color: #8685858a;
	margin-left: 10px;
	box-shadow: 0 0 3px #a7a4a442, inset 0 0 3px;
	animation: offButton 0.5s;
}

// create css animation to fade background color
@keyframes onButton {
	from {
		background-color: #8685858a;
	}
	to {
		background-color: rgb(236, 45, 45);
	}
}

@keyframes offButton {
	from {
		background-color: rgb(236, 45, 45);
	}
	to {
		background-color: #8685858a;
	}
}
</style>
