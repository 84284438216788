const state = {
	index: 0,
	snapshots: [],
};

const getters = {
	getSnapshots(state) {
		return state.snapshots.length > 0 ? state.snapshots[state.index] : null;
	},
	getActualIndex(state) {
		return state.index;
	},
};

const mutations = {
	addSnapshots(state, snapshot) {
		state.snapshots.push(snapshot);
	},
	setIndex(state, index) {
		state.index = index;
	},
};

const actions = {
	setSnapshot({ commit, rootGetters }) {
		if (state.index < state.snapshots.length - 1) {
			let totalToRemove = state.snapshots.length - state.index + 1;
			state.snapshots.splice(state.index + 1, totalToRemove);
		}
		let snapshot = rootGetters['firebase/getAllSubtitles'];
		let segmentRef = rootGetters['controller/getCurrentIndex'];
		const snapshotStringfy = JSON.stringify({ subtitles: snapshot, lastUpdate: segmentRef });
		commit('addSnapshots', snapshotStringfy);
		commit('setIndex', state.snapshots.length - 1);
	},
	getCtrlZ({ getters, commit }) {
		const actualIndex = getters.getActualIndex;
		if (actualIndex !== 0) commit('setIndex', actualIndex - 1);

		const snapshot = JSON.parse(getters.getSnapshots);
		if (snapshot == null) return;
		const subtitles = snapshot.subtitles;
		const lastUpdate = snapshot.lastUpdate;

		commit('firebase/setSubtitles', subtitles, { root: true });
		commit('controller/setCurrentIndex', lastUpdate, { root: true });
	},
	getCtrlY({ getters, commit }) {
		const actualIndex = getters.getActualIndex;
		if (actualIndex !== state.snapshots.length - 1) commit('setIndex', actualIndex + 1);

		const snapshot = JSON.parse(getters.getSnapshots);
		if (snapshot == null) return;
		const subtitles = snapshot.subtitles;
		const lastUpdate = snapshot.lastUpdate;

		commit('firebase/setSubtitles', subtitles, { root: true });
		commit('controller/setCurrentIndex', lastUpdate, { root: true });
	},
};

const namespaced = true;

export default {
	namespaced,
	state,
	mutations,
	actions,
	getters,
};
