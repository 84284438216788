<template>
	<div class="project-language">
		<div id="source-language" class="source-language">
			<span>{{ sourceLanguage }}</span>
			<b-tooltip
				target="source-language"
				title="Idioma de origem do vídeo"
				triggers="hover"
				:delay="{ show: 1000, hide: 100 }"
			/>
		</div>

		<i v-if="isTranslateTask" class="fa fa-arrow-right mx-3"></i>

		<div v-if="isTranslateTask" id="target-language" class="target-language">
			<span>{{ targetLanguage }}</span>
			<b-tooltip
				target="target-language"
				title="Idioma destino do vídeo"
				triggers="hover"
				:delay="{ show: 1000, hide: 100 }"
			/>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isTranslateTask: false,
			sourceLanguage: '',
			targetLanguage: '',
		};
	},
	computed: {
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
	},
	mounted() {
		this.sourceLanguage = this.getTask.source_language.split('-')[0].toUpperCase();
		if (this.getTask.translate_language == null) {
			this.setTranslateTask(false);
		} else {
			this.targetLanguage = this.getTask.translate_language.split('-')[0].toUpperCase();
			this.setTranslateTask(true);
		}
	},
	methods: {
		setTranslateTask(isTranslateTask) {
			this.isTranslateTask = isTranslateTask;
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin tag-language {
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	height: auto;
	max-height: 50px;
	border-radius: 5px;
	padding: 5px 10px;
	font-weight: bold;
	border-radius: 10px;
}

.project-language {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: auto;
	height: 100%;

	.source-language {
		@include tag-language;
		border: 1px solid #e563fa;
	}

	.target-language {
		@include tag-language;
		border: 1px solid #f0a22c;
	}

	.fa-arrow-right {
		margin-left: 3px;
		margin-right: 3px;
	}
}
</style>
