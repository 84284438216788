<template>
	<div class="find-and-replace">
		<div class="input-area">
			<input
				ref="inputToSearch"
				v-model="textToFind"
				type="text"
				placeholder="Busque aqui..."
				@click="setSelectInput('inputToSearch')"
			/>
			<div v-if="!loading" class="search-find-result">{{ actualChange ? actualChange : 0 }}/{{ textFindedNumber }}</div>
			<div v-else class="search-find-result">
				<b-spinner></b-spinner>
			</div>
			<button @click="goToSegmentUp"><i class="fa fa-angle-up" aria-hidden="true"></i></button>
			<button @click="goToSegmentDown"><i class="fa fa-angle-down" aria-hidden="true"></i></button>
		</div>
		<div class="caseSensitiveBtn">
			<b-form-checkbox
				v-model="caseSensitive"
				v-b-tooltip.hover.bottom
				placement="bottom"
				switch
				size="sm"
				:title="caseSensitive ? 'Desativar case-sensitive' : 'Ativar case-sensitive'"
				>Aa</b-form-checkbox
			>
			<div v-if="searching">
				<button id="change-all-to-italic" class="ml-3" @click="substituteAllReferencesToItalic">
					<i class="fas fa-italic"></i>
				</button>
				<b-tooltip
					target="change-all-to-italic"
					:title="'Alterar todas ocorrências para itálico'"
					triggers="hover"
					placement="bottom"
				/>
			</div>
		</div>
		<div v-if="!searching" class="tips">Precione "enter" para pesquisar</div>
		<div v-else class="input-area">
			<input
				ref="inputToChange"
				v-model="textToChange"
				type="text"
				placeholder="Alterar para..."
				@click="setSelectInput('inputToChange')"
			/>
			<button id="change-this-only" @click="substituteOnlyReference">
				<i class="fa fa-exchange" aria-hidden="true"></i>
			</button>
			<b-tooltip target="change-this-only" :title="'Alterar somente esse'" triggers="hover" placement="bottom" />
			<button id="change-all" @click="substituteAllReferences">
				<i class="fa fa-list" aria-hidden="true"></i>
			</button>
			<b-tooltip target="change-all" :title="'Alterar todas ocorrências'" triggers="hover" placement="bottom" />
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
export default {
	data() {
		return {
			textToFind: '',
			textToChange: '',
			textFindedNumber: 0,
			actualChange: null,
			lastMarkReference: null,
			listOfChanges: [],
			searching: false,
			loading: false,
			inputSelect: 'inputToSearch',
			caseSensitive: true,
		};
	},
	computed: {
		getAllSubtitles() {
			return this.$store.getters['firebase/getAllSubtitles'];
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getCurrentIndex() {
			return this.$store.getters['controller/getCurrentIndex'];
		},
		debouncedStartFindAndReplace: function () {
			return _.debounce(this.startFindAndReplace, 300); // Ajuste o valor de delay conforme necessário
		},
		getShortcuteEvent: {
			get() {
				return this.$store.getters['controller/getShortcuteEvent'];
			},
			deep: true,
		},
	},
	watch: {
		async actualChange(newValue) {
			if (newValue == null) return;
			this.selectTextInSegment();
			setTimeout(async () => {
				this.setMark();
			}, 300);
		},
		textToFind() {
			this.debouncedStartFindAndReplace();
		},
		caseSensitive() {
			this.debouncedStartFindAndReplace();
		},
		getShortcuteEvent(event) {
			if (event.eventKey == 'invertExclamation') {
				this.addInvertExclamation();
			}
			if (event.eventKey == 'invertInterrogation') {
				this.addInvertInterrogation();
			}
		},
	},
	mounted() {
		this.$refs.inputToSearch.focus();
		const element = this.$refs.inputToSearch;
		element.addEventListener('keydown', async (e) => {
			let code = e.keyCode;
			if (code == 13 && e.shiftKey) {
				if (this.listOfChanges.length > 0) this.goToSegmentUp();
				return;
			}

			if (code == 13) {
				if (this.listOfChanges.length > 0) this.goToSegmentDown();
				return;
			}
		});
	},
	beforeDestroy() {
		this.clearLastMarkReference();
	},
	methods: {
		async startFindAndReplace() {
			// Reset default parameters
			this.actualChange = null;
			this.textFindedNumber = 0;
			this.listOfChanges = [];
			this.clearLastMarkReference();
			if (!this.textToFind || this.textToFind == '') return;

			// Set loading and open
			this.searching = true;
			this.loading = true;

			// Find all texts to change in all segments
			await this.findAllTextToChange(this.textToFind);

			this.loading = false;

			// Select first change if has
			if (this.listOfChanges.length > 0) this.actualChange = this.findClosestSubtitleIndex();
		},
		findAllTextToChange(textToFind) {
			if (this.caseSensitive) {
				this.getAllSubtitles.forEach(async (subtitle) => {
					// choice text or translate
					let text;
					if (this.getIsTranslate) {
						text = subtitle.translate_text;
					} else {
						text = subtitle.text;
					}
					// Busca pela ocorrência do texto no segmento
					let existTextInSegment = parseInt(text.indexOf(textToFind));
					let startPosition = existTextInSegment;
					let finalPosition = 0;
					let textAux;

					while (existTextInSegment != -1) {
						this.textFindedNumber += 1;
						finalPosition = startPosition + textToFind.length;

						this.listOfChanges.push({
							subtitleIndex: subtitle.id,
							initialPosition: startPosition,
							finalPosition: finalPosition,
							text,
						});
						// Procura por mais ocorrências a partir da ocorrência anterior, se tiver retorna a posição inicial de onde está
						textAux = text.substring(finalPosition);
						existTextInSegment = parseInt(textAux.indexOf(textToFind));
						if (existTextInSegment != -1) {
							startPosition = existTextInSegment + finalPosition;
						}
					}
				});
			} else {
				// textToFind = textToFind.toLowerCase();
				this.getAllSubtitles.forEach(async (subtitle) => {
					// choice text or translate
					let text;
					if (this.getIsTranslate) {
						text = subtitle.translate_text;
					} else {
						text = subtitle.text;
					}
					// text = text.toLowerCase();

					// Busca pela ocorrência do texto no segmento
					let existTextInSegment = parseInt(text.toLowerCase().indexOf(textToFind.toLowerCase()));
					let startPosition = existTextInSegment;
					let finalPosition = 0;
					let textAux;

					while (existTextInSegment != -1) {
						this.textFindedNumber += 1;
						finalPosition = startPosition + textToFind.length;

						this.listOfChanges.push({
							subtitleIndex: subtitle.id,
							initialPosition: startPosition,
							finalPosition: finalPosition,
							text,
						});
						// Procura por mais ocorrências a partir da ocorrência anterior, se tiver retorna a posição inicial de onde está
						textAux = text.substring(finalPosition);
						existTextInSegment = parseInt(textAux.indexOf(textToFind));
						if (existTextInSegment != -1) {
							startPosition = existTextInSegment + finalPosition;
						}
					}
				});
			}
		},
		selectTextInSegment() {
			if (this.actualChange > 0 && this.actualChange < this.listOfChanges.length + 1) {
				this.$store.dispatch('controller/setCurrentIndex', this.listOfChanges[this.actualChange - 1].subtitleIndex);
			}
		},
		setMark() {
			setTimeout(() => {
				this.clearLastMarkReference();
				let { subtitleIndex, initialPosition, finalPosition, text } = this.listOfChanges[this.actualChange - 1];
				let row = document.getElementById(`rowSegment-${subtitleIndex}`);

				let textArea;
				if (this.getIsTranslate) {
					textArea = row.getElementsByClassName('text-segment')[1].getElementsByTagName('p')[0];
				} else {
					textArea = row.getElementsByClassName('text-segment')[0].getElementsByTagName('p')[0];
				}

				let textToMark = text.substring(initialPosition, finalPosition);
				text = this.setMarkToText(text, initialPosition, textToMark);
				text = text.replace('\n', '<br>');
				textArea.innerHTML = text;
				this.lastMarkReference = textArea;
			}, 300);
		},
		setMarkToText(inputString, startIndex, textToMark) {
			// Divida a string original em partes antes e depois do índice onde o texto a ser marcado está presente. Isso é feito para manter a quebra do \n original.
			const indexOfBreakLine = inputString.indexOf('\n');

			let firstLine = inputString.substring(0, indexOfBreakLine);
			let secondLine = inputString.substring(indexOfBreakLine, inputString.length);

			if (startIndex < indexOfBreakLine) {
				const firstLinePart1 = firstLine.substring(0, startIndex);
				const firstLinePart2 = firstLine.substring(startIndex, firstLine.length);
				firstLine = firstLinePart1 + firstLinePart2.replace(textToMark, `<mark>${textToMark}</mark>`);
			} else {
				startIndex = startIndex - firstLine.length;
				const secondLinePart1 = secondLine.substring(0, startIndex);
				const secondLinePart2 = secondLine.substring(startIndex, secondLine.length);
				secondLine = secondLinePart1 + secondLinePart2.replace(textToMark, `<mark>${textToMark}</mark>`);
			}

			return firstLine + secondLine;
		},
		clearLastMarkReference(textToClear = null) {
			if (this.lastMarkReference == null) return;
			let text = textToClear || this.lastMarkReference.innerHTML;
			text = text.replace('<mark>', '');
			text = text.replace('</mark>', '');

			if (textToClear) {
				return textToClear;
			} else {
				this.lastMarkReference.innerHTML = text;
			}
		},
		substituteOnlyReference() {
			let reference = this.listOfChanges[this.actualChange - 1];
			this.substituteReference(reference);
		},
		async substituteAllReferences() {
			if (this.listOfChanges.length == 0) return;
			let i = this.listOfChanges.length;
			while (i != 0) {
				let reference = this.listOfChanges[0];
				await this.substituteReference(reference, true);
				i--;
			}
		},
		async substituteAllReferencesToItalic() {
			if (this.listOfChanges.length == 0) return;
			let i = this.listOfChanges.length;
			while (i != 0) {
				let reference = this.listOfChanges[0];
				await this.substituteReferenceToItalic(reference, true);
				i--;
			}
		},
		async substituteReference(reference, isChangeAllReferences = false) {
			let { subtitleIndex, initialPosition, finalPosition } = reference;
			let subtitle = this.$store.getters['firebase/getSubtitle'](subtitleIndex);

			let textArea;
			if (this.getIsTranslate) {
				textArea = subtitle.translate_text;
			} else {
				textArea = subtitle.text;
			}

			let textToReplace = textArea.substring(initialPosition, finalPosition);

			let initialPart = textArea.substring(0, initialPosition);
			let midPart = textArea.substring(initialPosition, finalPosition);
			midPart = midPart.replace(textToReplace, this.textToChange);
			let finalPart = textArea.substring(finalPosition, textArea.length);

			textArea = initialPart + midPart + finalPart;
			textArea = textArea.replace('<br>', '\n');

			if (this.getIsTranslate) {
				subtitle.translate_text = textArea;
			} else {
				subtitle.text = textArea;
			}

			await this.$store.dispatch('firebase/updateSegment', { segment: subtitle });

			// update list
			let index = this.listOfChanges.indexOf(reference);
			this.listOfChanges.splice(index, 1);
			if (this.listOfChanges.length == 0) this.searching = false;

			if (this.textFindedNumber == 1) {
				this.textFindedNumber = 1;
			} else {
				this.textFindedNumber -= 1;
			}

			if (!isChangeAllReferences)
				this.actualChange = this.actualChange + 1 < this.listOfChanges.length ? this.actualChange + 1 : 1;
		},
		async substituteReferenceToItalic(reference) {
			let { subtitleIndex, initialPosition, finalPosition } = reference;
			let subtitle = this.$store.getters['firebase/getSubtitle'](subtitleIndex);

			let textArea;
			if (this.getIsTranslate) {
				textArea = subtitle.translate_text;
			} else {
				textArea = subtitle.text;
			}

			let textToReplace = textArea.substring(initialPosition, finalPosition);

			let initialPart = textArea.substring(0, initialPosition);
			let midPart = textArea.substring(initialPosition, finalPosition);
			let newText = '<i>' + this.textToFind + '</i>';
			midPart = midPart.replace(textToReplace, newText);
			let finalPart = textArea.substring(finalPosition, textArea.length);

			textArea = initialPart + midPart + finalPart;
			textArea = textArea.replace('<br>', '\n');

			if (this.getIsTranslate) {
				subtitle.translate_text = textArea;
			} else {
				subtitle.text = textArea;
			}

			await this.$store.dispatch('firebase/updateSegment', { segment: subtitle });

			// update list
			let index = this.listOfChanges.indexOf(reference);
			this.listOfChanges.splice(index, 1);
			if (this.listOfChanges.length == 0) this.searching = false;

			if (this.textFindedNumber == 1) {
				this.textFindedNumber = 1;
			} else {
				this.textFindedNumber -= 1;
			}

			this.actualChange = this.actualChange + 1 < this.listOfChanges.length ? this.actualChange + 1 : 1;
		},
		goToSegmentUp() {
			if (this.actualChange > 1) {
				this.actualChange--;
			} else {
				this.actualChange = this.listOfChanges.length;
			}
		},
		goToSegmentDown() {
			if (this.actualChange < this.listOfChanges.length) {
				this.actualChange++;
			} else {
				this.actualChange = 1;
			}
		},
		findClosestSubtitleIndex() {
			let nearestIndex = 0;
			for (let i = 0; i < this.listOfChanges.length; i++) {
				const subtitleIndex = this.listOfChanges[i].subtitleIndex;
				if (subtitleIndex <= this.getCurrentIndex) {
					nearestIndex = i;
				}
			}
			return nearestIndex == 0 ? 1 : nearestIndex;
		},
		setSelectInput(input) {
			this.inputSelect = input;
		},
		addInvertExclamation() {
			try {
				let text;
				let selectionCut;

				selectionCut = this.$refs[this.inputSelect].selectionStart;
				if (this.inputSelect == 'inputToSearch') {
					text = this.textToFind;
					this.textToFind = text.substring(0, selectionCut) + '¡' + text.substring(selectionCut);
				} else {
					text = this.textToChange;
					this.textToChange = text.substring(0, selectionCut) + '¡' + text.substring(selectionCut);
				}

				// add invert exclamation in selectionCut position
			} catch (err) {
				console.error('Error [SegmentEditor/addInvertExclamation]', err);
			}
		},
		addInvertInterrogation() {
			try {
				let text;
				let selectionCut;

				selectionCut = this.$refs[this.inputSelect].selectionStart;
				if (this.inputSelect == 'inputToSearch') {
					text = this.textToFind;
					this.textToFind = text.substring(0, selectionCut) + '¿' + text.substring(selectionCut);
				} else {
					text = this.textToChange;
					this.textToChange = text.substring(0, selectionCut) + '¿' + text.substring(selectionCut);
				}
			} catch (err) {
				console.error('Error [SegmentEditor/addInvertInterrogation]', err);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.find-and-replace {
	display: flex;
	height: auto;
	position: absolute;
	flex-direction: column;
	width: 30%;
	min-height: 5%;
	left: 0;
	top: 100%;
	z-index: 2;
	background-color: #2e2e2e;
	border: 3px solid #1e1e1e;
	border-top: none;
	border-radius: 0px 0px 5px 5px;

	.input-area {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;
		width: 100%;
		height: 40px;
		padding: 3%;

		input {
			width: 60%;
			height: 100%;
			margin-right: 1%;
			padding: 5px;
			font-size: 16px;

			&:focus {
				outline: 0;
			}
		}

		.search-find-result {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 15%;
			height: 100%;
			font-size: 16px;
			text-align: center;

			.spinner-border {
				width: 1rem;
				height: 1rem;
			}
		}

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			margin-left: 1%;
			border-radius: 5px;
			height: 100%;
		}
	}

	.caseSensitiveBtn {
		display: flex;
		justify-content: flex-start;
		margin-left: 4%;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			margin-left: 1%;
			border-radius: 5px;
			height: 100%;
		}
	}

	.tips {
		font-size: 11px;
		font-family: Arial, Helvetica, sans-serif;
	}

	.buttons-area {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		height: 5%;
		width: 100%;
		font-size: 12px;
	}
}
</style>
