import Vue from 'vue';

import { H } from 'highlight.run';

// Initialize Highlight.IO only in production mode
const init = Math.floor(Math.random() * 10);
if (init < 5)
	// Entre 0 e 4 aguarda 30 segundos para iniciar
	window.setTimeout(initHighlight, 30000);
else if (init >= 5 && init < 8)
	// Entre 5 e 7 inicia imediatamente
	initHighlight();

function initHighlight() {
	H.init(process.env.VUE_APP_HIGHLIGHT_IO_PROJECT_ID, {
		environment: `${process.env.NODE_ENV || 'development'}`,
		version: `${process.env.PACKAGE_VERSION}`,
		networkRecording: {
			enabled: false,
		},
		disableConsoleRecording: process.env.NODE_ENV === 'production',
		disableBackgroundRecording: true,
		consoleMethodsToRecord: ['info', 'warn', 'error'],
		enableCanvasRecording: false,
		samplingStrategy: {
			canvas: 15,
			canvasQuality: 'low',
			canvasMaxSnapshotDimension: 480,
		},
		enablePerformanceRecording: false,
		enableSegmentIntegration: false,
		manualStart: true,
	});
}

import App from './App.vue';
import Vuex from 'vuex';
import store from './store';
import VueQuillEditor from 'vue-quill-editor';

import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme

// Bootstrap vue configs
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { DraggablePlugin } from '@braks/revue-draggable';

import router from './router';

Vue.use(VueQuillEditor /* { default global options } */);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// Vuex Module
Vue.use(Vuex);
// Drag and Drop Plugin
Vue.use(DraggablePlugin);

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* add icons to the library */
library.add(fas);
// library.add(far);
// library.add(fab);

/* add font awesome icon component */
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

import './app.scss';

Vue.config.productionTip = false;

new Vue({
	store,
	router,
	render: (handler) => handler(App),
}).$mount('#app');
