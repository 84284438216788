<template>
	<div class="report-feedback">
		<div class="text-compare">
			<p>{{ getText[0] }}</p>
			<p>{{ getText[1] }}</p>
		</div>
		<div class="add-feedback">
			<b-form-select
				v-model="categorySelected"
				placeholder="CTRL+1 para selecionar a categoria"
				:options="getCategoryOptions"
				class="input-subcategory"
			></b-form-select>
			<b-form-select
				v-if="categorySelected != null"
				v-model="subCategorySelected"
				:options="getSubCategoryOptions"
				class="input-subcategory"
			></b-form-select>
			<div class="button-area">
				<b-button class="add-feedback" :disabled="!categorySelected || !subCategorySelected" @click="addFeedback"
					>Adicionar</b-button
				>
				<b-button class="clear-feedback" @click="clearFeedback">Limpar</b-button>
			</div>
			<div class="segments-feedback">
				<div class="content">
					<div v-for="(feedback, index) in feedbacks" :key="index" class="feedback">
						<span class="feedback-title"
							>{{ index + 1 }} - {{ feedback.category }} <button @click="removeFeedback(index)">X</button></span
						>
						<span class="feedback-text">{{ feedback.subCategory }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReportFeedbackPerSegment',
	data() {
		return {
			categorySelected: '',
			subCategorySelected: '',
			feedbacks: [],
			open: false,
		};
	},
	computed: {
		getFeedbackIsOpen() {
			return this.$store.getters['feedback/feedbackIsOpen'] || false;
		},
		getCurrentSegment() {
			return this.$store.getters['controller/getCurrentSegment'] || { id: 0 };
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getCategories() {
			return [
				{
					category: 'Erros ortográficos (escrita incorreta)',
					subCategory: [
						{
							text: 'Atenção com caixa alta (ou não) pós pontuação\nSe o segmento continua a ideia do anterior, é necessário começar com letra minúscula. Caso o anterior tenha sido finalizado com ponto final/exclamação/interrogação, o seguinte deve ser iniciado com maiúscula.',
						},
						{ text: 'Erro de digitação' },
						{ text: 'Erro de ortografia' },
					],
				},
				{
					category: 'Erros gramaticais (acentuação e pontuação)',
					subCategory: [{ text: 'Erros de pontuação' }, { text: 'Problemas na acentuação de palavras' }],
				},
				{
					category: 'Erros de tradução',
					subCategory: [
						{
							text: 'Padronização de termos \nExemplo: há casos de palavras que possuem duas ou mais grafias, mas quando optamos por uma, devemos usá-la em todo o texto, sem ficar alternando.',
						},
						{ text: 'Traduções incorretas' },
					],
				},
				{
					category: 'Conteúdo incompleto',
					subCategory: [
						{ text: 'Erro de transcrição' },
						{
							text: 'Trechos não correspondem ao que é dito originalmente pelo locutor \nA legenda pode (e em casos que o locutor fala muito rápido até deve) ser sintetizada, ou seja, colocar o mesmo conteúdo, mas em menos palavras, mas é importante que nada essencial seja omitido. O sentido da frase deve continuar o mesmo.',
						},
					],
				},
				{
					category: 'Erros técnicos de legendagem',
					subCategory: [
						{ text: 'Todas as citações à obras e pessoas (mesmo que ao próprio locutor) devem estar entre aspas.' },
						{
							text: 'Números de zero a nove deve estar escritos por extenso. Acima disso, é necessário usar o numeral. Milhares, milhões, bilhões e por aí vai devem conter inicialmente o numeral e depois a definição. Exemplo: “2 milhões”, “3 bilhões”, “14 trilhões”.',
						},
						{
							text: 'Falta polir o texto, removendo vícios linguísticos.\nVícios como “né”, “tipo”, “então”, “assim” e outros que forem identificados durante o processo de legendagem devem ser retirados quando não fizerem parte do contexto da frase. Recomenda-se também “consertar” alguns vícios, como quando alguém ala “que que eu quero dizer com isso?”, que deve ficar como “o que eu quero dizer com isso?”',
						},
						{
							text: 'Remover repetições de palavras quando locutor erra e já se corrige. Só é preciso estar na legenda o que estiver realmente correto.',
						},
						{ text: 'Erro de quebra de linha' },
						{ text: 'Segmentos fora de sincronia' },
						{ text: 'Erro de sincronia' },
					],
				},
			];
		},
		getCategoryOptions() {
			return this.getCategories.map((obj) => {
				return { value: obj.category, text: obj.category };
			});
		},
		getSubCategoryOptions() {
			let subCategoryList = [];
			this.getCategories.forEach((obj) => {
				if (obj.category == this.categorySelected) {
					obj.subCategory.forEach((subCategory) => {
						subCategoryList.push({ value: subCategory.text, text: subCategory.text });
					});
				}
			});
			return subCategoryList;
		},
		getText() {
			let subtitleQA = this.$store.getters['firebase/getSubtitleQAByIdReference'](this.getCurrentSegment.id_reference);
			let lines;
			if (this.getIsTranslate) {
				lines = subtitleQA ? subtitleQA.translate_text.split('\n') : ['', ''];
			} else {
				lines = subtitleQA ? subtitleQA.text.split('\n') : ['', ''];
			}
			return lines;
		},
	},
	watch: {
		getCurrentSegment() {
			if (this.getCurrentSegment.feedback) {
				this.feedbacks = this.getCurrentSegment.feedback;
			} else {
				this.feedbacks = [];
			}
		},
		getFeedbackIsOpen(newValue) {
			this.open = newValue;
		},
	},
	methods: {
		addFeedback() {
			if (this.categorySelected == '' || this.subCategorySelected == '') return;
			this.feedbacks.push({ category: this.categorySelected, subCategory: this.subCategorySelected });
			this.saveFeedback();
		},
		removeFeedback(index) {
			this.feedbacks.splice(index, 1);
		},
		clearFeedback() {
			this.categorySelected = '';
			this.subCategorySelected = '';
		},
		saveFeedback() {
			this.$store.dispatch('firebase/updateFeedbackSegment', {
				segment: this.getCurrentSegment,
				feedback: this.feedbacks,
			});
			this.clearFeedback();
		},
		closeModal() {
			this.$store.dispatch('feedback/closeFeedbackModal');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/app.scss';

.report-feedback {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 10px;

	.text-compare {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		width: 100%;
		height: 20%;
		background-color: $secondary;
		padding: 10px;

		p {
			margin-bottom: 0.4em;
		}
	}

	.add-feedback {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		height: 80%;

		.input-subcategory {
			width: 100%;
			height: 10%;
			margin-top: 3%;
		}

		.button-area {
			width: 100%;
			height: 10%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			margin-top: 10px;

			button {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				min-width: 20%;
				max-width: 20%;
				font-size: 12px;
				text-align: center;
			}
			.add-feedback {
				background-color: green;
				margin-right: 10px;
			}

			.clear-feedback {
				background-color: rgb(231, 48, 48);
			}
		}

		.segments-feedback {
			width: 100%;
			max-height: 60%;
			margin-top: 20px;
			background-color: rgba(161, 155, 155, 0.456);
			border-radius: 5px;
			overflow-y: auto;

			.content {
				width: 100%;
				height: auto;
				padding: 10px;

				.feedback {
					margin-top: 20px;

					.feedback-title {
						display: flex;
						flex-direction: row;
						width: 100%;
						font-size: 16px;
						font-weight: bold;

						button {
							margin-left: 10px;
							display: flex;
							justify-content: center;
							align-items: center;
							width: 20px;
							height: 20px;
							color: #fff;
							border-radius: 10px;
							font-size: 12px;
							font-weight: bold;
						}
					}

					.feedback-text {
						margin-left: 10px;
						font-size: 14px;
					}
				}
			}
		}
	}
}
</style>
