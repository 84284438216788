<template>
	<div v-if="getIsReviewQA" class="reviewer-tag">
		<div v-if="getJoinPoints.length > 0" class="title">
			<i id="tags-info" class="fa fa-question-circle mr-2" aria-hidden="true"></i>
			<span>Tags:</span>
			<b-tooltip class="tooltip-job-interval" :target="'tags-info'" triggers="hover" placement="top">
				<div>
					As tags coloridas ao lado referem-se a um revisor em questão. Você pode visualizar os segmentos de
					responsabilidade desse revisor atráves da cor da borda lateral esquerda na tabela de segmentos.
				</div>
			</b-tooltip>
		</div>
		<div class="tags-area">
			<div
				v-for="(item, index) in getJoinPoints"
				:id="`tag-${index}`"
				:key="index"
				class="tags"
				:style="getStyle(index)"
				@click="setVideoTime(item.start)"
			>
				Revisor {{ index + 1 }}
				<b-tooltip class="tooltip-job-interval" :target="`tag-${index}`" triggers="hover" placement="bottom">
					<div>
						{{ item.start | convertMilisecondsToTime }}
					</div>
				</b-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	filters: {
		convertMilisecondsToTime(ms) {
			let hours = Math.floor(ms / 3600000); // 1 hora = 3600000 milissegundos
			let minutes = Math.floor((ms % 3600000) / 60000); // 1 minuto = 60000 milissegundos
			let seconds = Math.floor(((ms % 3600000) % 60000) / 1000);
			let milliseconds = Math.floor(((ms % 3600000) % 60000) % 1000);

			return (
				(hours < 10 ? '0' + hours : hours) +
				':' +
				(minutes < 10 ? '0' + minutes : minutes) +
				':' +
				(seconds < 10 ? '0' + seconds : seconds) +
				',' +
				(milliseconds < 10 ? '00' + milliseconds : milliseconds < 100 ? '0' + milliseconds : milliseconds)
			);
		},
	},
	computed: {
		getIsReviewQA() {
			return this.$store.getters['firebase/getIsReviewQA'];
		},
		getJoinPoints() {
			return this.$store.getters['firebase/getJoinPoints'];
		},
		getTagColors() {
			return this.$store.getters['firebase/getTagColors'];
		},
	},
	methods: {
		getStyle(index) {
			return {
				backgroundColor: this.getTagColors[index],
			};
		},
		setVideoTime(milliseconds) {
			this.$store.dispatch('video/setCurrentTimeManual', milliseconds);
		},
	},
};
</script>

<style lang="scss" scoped>
.reviewer-tag {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 10%;
	font-size: 12px;

	.title {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: auto;
		height: 100%;
		padding-left: 10px;

		#tags-info {
			margin-right: 1%;
		}
	}

	.tags-area {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 10px 2px;

		.tags {
			width: 10%;
			height: 100%;
			display: flex;
			margin: 0px 5px;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			color: black;
			font-size: 12px;

			&:hover {
				cursor: pointer;
			}
		}
	}
}
</style>
