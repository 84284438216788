<template>
	<button id="segment-position-button" class="fa fa-th" aria-hidden="true">
		<b-tooltip id="segment-position-button" target="segment-position-button" triggers="hover" placement="right">
			<div class="row m-0 p-0">
				<p>Selecione a posição da legenda:</p>
			</div>
			<div class="col monitor-limits">
				<div class="row">
					<div class="col">
						<button
							:class="segmentPosition === '{\\an7}' ? 'segment-position-button-selected' : ''"
							@click="setSegmentPosition('{\\an7}')"
						></button>
					</div>
					<div class="col">
						<button
							:class="segmentPosition === '{\\an8}' ? 'segment-position-button-selected' : ''"
							@click="setSegmentPosition('{\\an8}')"
						></button>
					</div>
					<div class="col">
						<button
							:class="segmentPosition === '{\\an9}' ? 'segment-position-button-selected' : ''"
							@click="setSegmentPosition('{\\an9}')"
						></button>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col">
						<button
							:class="segmentPosition === '{\\an4}' ? 'segment-position-button-selected' : ''"
							@click="setSegmentPosition('{\\an4}')"
						></button>
					</div>
					<div class="col">
						<button
							:class="segmentPosition === '{\\an5}' ? 'segment-position-button-selected' : ''"
							@click="setSegmentPosition('{\\an5}')"
						></button>
					</div>
					<div class="col">
						<button
							:class="segmentPosition === '{\\an6}' ? 'segment-position-button-selected' : ''"
							@click="setSegmentPosition('{\\an6}')"
						></button>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col">
						<button
							:class="segmentPosition === '{\\an1}' ? 'segment-position-button-selected' : ''"
							@click="setSegmentPosition('{\\an1}')"
						></button>
					</div>
					<div class="col">
						<button
							:class="segmentPosition === '{\\an2}' ? 'segment-position-button-selected' : ''"
							@click="setSegmentPosition('{\\an2}')"
						></button>
					</div>
					<div class="col">
						<button
							:class="segmentPosition === '{\\an3}' ? 'segment-position-button-selected' : ''"
							@click="setSegmentPosition('{\\an3}')"
						></button>
					</div>
				</div>
			</div>
		</b-tooltip>
	</button>
</template>

<script>
export default {
	data() {
		return {
			segmentPosition: '{\\an2}',
		};
	},
	computed: {
		getCurrentSegment() {
			return this.$store.getters['controller/getCurrentSegment'];
		},
	},
	watch: {
		getCurrentSegment(value) {
			if (value) {
				if (value.position) {
					this.segmentPosition = value.position;
				} else {
					this.segmentPosition = '{\\an2}';
				}
			}
		},
	},
	methods: {
		async setSegmentPosition(position) {
			this.segmentPosition = position;
			await this.$store.dispatch('controller/saveSegmentPosition', position);
		},
	},
};
</script>

<style lang="scss" scoped>
#segment-position-button {
	color: #fff;

	.monitor-limits {
		border: 1px solid #fff;
		padding: 5px;
	}

	button {
		width: 20px;
		height: 20px;
		border-radius: 100%;
	}
}

.segment-position-button-selected {
	background-color: red;
}

.tooltip {
	min-width: 600px;
}
</style>
