<template>
	<b-modal
		id="delete-segment-modal"
		:visible="isRemoveSegmentModalActive"
		refs="removeSegmentModal"
		title="Deseja deletar este segmento?"
		cancel-title="Cancelar"
		ok-title="Sim"
		@ok="removeSegment"
		@close="closeRemoveSegmentModal"
		@hide="closeRemoveSegmentModal"
	>
		Deseja deletar o segmento {{ indexToDelete }}? <br />
		<span class="critical"><b>Ao deletar você não poderá recuperar o segmento!</b></span>
	</b-modal>
</template>

<script>
export default {
	data() {
		return {
			indexToDelete: null,
		};
	},
	computed: {
		isRemoveSegmentModalActive() {
			return this.$store.getters['isRemoveSegmentModalActive'];
		},
		getSegmentToDelete() {
			return this.$store.getters['getSegmentToDelete'];
		},
	},
	watch: {
		getSegmentToDelete() {
			this.indexToDelete = this.getSegmentToDelete;
		},
	},
	methods: {
		removeSegment() {
			this.$store.dispatch('controller/removeSegment', this.indexToDelete);
		},
		closeRemoveSegmentModal() {
			this.$store.dispatch('setRemoveSegmentModalActive', {
				status: false,
				payload: null,
			});
		},
	},
};
</script>

<style lang="scss" scoped></style>
