<template>
	<div id="menuSuspend-button" class="menu-nav-bar">
		<div
			v-if="getTask.isSelfReviewing"
			style="padding: 0 10% 0"
			class="menu-dropdown"
			@click="expandMenuButton = !expandMenuButton"
		>
			<img :src="logoLegendaAi" alt="" srcset="" />
			<!-- <img
				v-else
				:src="expandMenuButton ? logoWhiteSkySub : logoSkySub"
				alt=""
				srcset=""
				style="height: 50%; padding-right: 0%"
			/> -->
			<i class="fa fa-caret-down" aria-hidden="true"></i>
			<div v-if="expandMenuButton" class="menu-options">
				<div v-for="(option, index) in options" :key="index">
					<div
						v-if="!option.disable"
						:class="option.disable ? 'option-button disabled' : 'option-button'"
						@mouseover="optionSelectd = index"
						@click="option.onclick"
					>
						<div class="text-button">
							<span
								>{{ option.name }} <i v-if="option.subItens.length > 0" class="fa fa-caret-right" aria-hidden="true"></i
							></span>
						</div>
						<div v-if="optionSelectd == index && option.subItens.length > 0" class="sub-option">
							<div v-for="(suboption, subindex) in option.subItens" :key="subindex">
								<div
									v-if="!suboption.disable"
									:class="suboption.disable ? 'sub-option-item disabled' : 'sub-option-item'"
									@click="suboption.onclick"
								>
									<div class="text-button">
										<span>
											{{ suboption.name }}
											<i
												v-if="suboption.isSelected != undefined ? suboption.isSelected() : false"
												class="fa fa-check"
												aria-hidden="true"
											></i>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="option.section != undefined || option.section" class="divider"></div>
				</div>
			</div>
		</div>
		<div v-else class="menu-dropdown" style="width: 100%" @click="expandMenuButton = !expandMenuButton">
			<img :src="expandMenuButton ? logoWhiteSkySub : logoSkySub" alt="" srcset="" style="height: 50%" />
			<i class="fa fa-caret-down" aria-hidden="true"></i>
			<div v-if="expandMenuButton" class="menu-options">
				<div v-for="(option, index) in options" :key="index">
					<div
						v-if="!option.disable"
						:class="option.disable ? 'option-button disabled' : 'option-button'"
						@mouseover="optionSelectd = index"
						@click="option.onclick"
					>
						<div class="text-button">
							<span
								>{{ option.name }} <i v-if="option.subItens.length > 0" class="fa fa-caret-right" aria-hidden="true"></i
							></span>
						</div>
						<div v-if="optionSelectd == index && option.subItens.length > 0" class="sub-option">
							<div v-for="(suboption, subindex) in option.subItens" :key="subindex">
								<div
									v-if="!suboption.disable"
									:class="suboption.disable ? 'sub-option-item disabled' : 'sub-option-item'"
									@click="suboption.onclick"
								>
									<div class="text-button">
										<span>
											{{ suboption.name }}
											<i
												v-if="suboption.isSelected != undefined ? suboption.isSelected() : false"
												class="fa fa-check ml-2"
												aria-hidden="true"
											></i>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="option.section != undefined || option.section" class="divider"></div>
				</div>
			</div>
		</div>

		<JoinPoints v-if="modalsOpens.joinPoints" @close="modalsOpens.joinPoints = false" />
		<Backups />
		<TutorialManager v-if="showTutorial" @close="showTutorial = false" />

		<b-modal
			id="confirm-uppercase-convert"
			:visible="uppercaseConfirm"
			refs="confirmuppercasee"
			title="Deseja converter todos os textos dos segmentos para maíusculo ?"
			cancel-title="Cancelar"
			ok-title="Sim"
			@ok="convertSegmentTouppercase"
			@close="uppercaseConfirm = false"
			@hide="uppercaseConfirm = false"
		>
			<span class="critical"><b>Atenção: Essa ação não poderá ser desfeita!</b></span>
		</b-modal>
	</div>
</template>

<script>
import JoinPoints from '@/components/Modals/JoinPoints';
import TutorialManager from '@/components/Navbar/Tutorial/TutorialManager.vue';
import Backups from '@/components/Modals/Backups.vue';
// import logoLegendaAi from '../../../public/assets/legendaai.png';
// import logoWhiteLegendaAi from '../../../public/assets/legendaai-light.png';
import logoSkySub from '../../../public/assets/Skylar_Horizontal_LightColor-20230208-103056.png';
import logoWhiteSkySub from '../../../public/assets/Skylar_Horizontal_White-20230208-103008.png';
import logoLegendaAi from '../../../public/assets/Legenda-ai-web-Simbolo-sem-fundo.png';
export default {
	components: {
		JoinPoints,
		TutorialManager,
		Backups,
	},
	data() {
		return {
			logoSkySub: logoSkySub,
			logoLegendaAi: logoLegendaAi,
			logoWhiteSkySub: logoWhiteSkySub,
			// logoWhiteLegendaAi: logoWhiteLegendaAi,
			expandMenuButton: false,
			optionSelectd: -1,
			expandMenu: false,
			modalsOpens: {
				joinPoints: false,
			},
			showTutorial: false,
			options: [],
			uppercaseConfirm: false,
		};
	},
	computed: {
		getIsReviewQA() {
			return this.$store.getters['firebase/getTask'].reviewed_by_qa;
		},
		isTxtMode() {
			return this.$store.getters['firebase/getTask'].type == 'txt' ? true : false;
		},
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getSynchronyMode() {
			return this.$store.getters['controller/getSynchronyMode'];
		},
		getRevisionMode() {
			return this.$store.getters['controller/getRevisionMode'];
		},
	},
	watch: {
		getSynchronyMode() {
			this.reloadButtonsOption();
		},
		getRevisionMode() {
			this.reloadButtonsOption();
		},
	},
	mounted() {
		this.options = [
			// {
			// 	name: 'Voltar',
			// 	onclick: () => {
			// 		window.history.back();
			// 	},
			// 	subItens: [],
			// 	disable: false,
			// 	section: true,
			// },
			{
				name: 'Arquivo',
				onclick: () => {},
				subItens: [
					{
						name: 'Salvar versão atual',
						onclick: () => {
							this.$store.dispatch('addManualBackup');
						},
						subItens: [],
						disable: false,
					},
					{
						name: 'Ver historico de versões',
						onclick: () => {
							this.$store.dispatch('updateBackups');
							this.$bvModal.show('backups-modal');
						},
						subItens: [],
						disable: false,
					},
				],
				disable: this.isTxtMode ? true : false,
			},
			{
				name: 'Ferramentas',
				onclick: () => {},
				subItens: [
					{
						name: 'Localizar e substituir',
						onclick: () => {
							this.$store.dispatch('setFindAndReplace');
						},
						disable: this.isTxtMode ? true : false,
					},
					{
						name: 'Gerenciar locutores',
						onclick: () => {
							this.$bvModal.show('announcer-modal');
						},
						disable: false,
					},
					{
						name: 'Gerar texto corrido \n(Ctrl + Q)',
						onclick: () => {
							this.$store.dispatch('controller/sendShortcuteEvent', 'generateTaskTxt');
						},
						disable: this.getIsReviewQA === true ? false : true,
					},
					{
						name: 'Converter segmentos para maiúsculo',
						onclick: async () => {
							this.uppercaseConfirm = true;
						},
						disable: this.getIsReviewQA === true ? false : true,
					},
					{
						name: 'Gerenciar pontos de junção',
						onclick: () => {
							this.modalsOpens.joinPoints = true;
						},
						disable: this.isTxtMode ? true : false,
					},
				],
			},
			{
				name: 'Visualizar',
				onclick: () => {},
				subItens: [
					{
						name: 'Modo Edição',
						onclick: () => {
							this.$store.commit('controller/setSynchronyMode', false);
							this.$store.commit('controller/setRevisionMode', false);
						},
						isSelected: () => {
							return this.getSynchronyMode ? false : true;
						},
						disable: this.isTxtMode ? true : false,
					},
					{
						name: 'Modo Sincronia',
						onclick: () => {
							this.$store.commit('controller/setSynchronyMode', true);
							this.$store.commit('controller/setRevisionMode', false);
						},
						isSelected: () => {
							return this.getSynchronyMode && !this.getRevisionMode ? true : false;
						},
						disable: this.isTxtMode ? true : false,
					},
					{
						name: 'Modo Revisão (Teatro)',
						onclick: () => {
							this.$store.commit('controller/setRevisionMode', true);
							this.$store.commit('controller/setSynchronyMode', true);
						},
						isSelected: () => {
							return this.getRevisionMode ? true : false;
						},
						disable: this.isTxtMode ? true : false,
					},
				],
			},
			{
				name: 'Preferências',
				onclick: () => {},
				subItens: [
					{
						name: 'Configurações',
						onclick: () => {
							this.$store.dispatch('setPreferenceModalIsOpen');
						},
						disable: false,
					},
					{
						name: 'Definir tema',
						onclick: () => {},
						disable: true,
					},
				],
			},
			{
				name: 'Ajuda',
				onclick: () => {},
				subItens: [
					{
						name: 'Tutorial editor',
						onclick: () => {
							this.showTutorial = true;
						},
						disable: false,
					},
					{
						name: 'Manual do revisor',
						onclick: () => {
							window.open(
								'https://pecege-my.sharepoint.com/:b:/p/skylar/Ec5rSs6SEeBBrZi9X8H06KcBWXcqf0cenpX9HtWso2MNkw?e=3AN4Oi'
							);
						},
						disable: false,
					},
					{
						name: 'Reportar bug',
						onclick: () => {},
						disable: true,
					},
					{
						name: 'Precisa de ajuda?',
						onclick: () => {},
						disable: true,
					},
				],
				section: true,
			},
			{
				name: 'Novidades',
				onclick: () => {},
				subItens: [],
				disable: true,
			},
		];
	},
	methods: {
		reloadButtonsOption() {
			let options = this.options;
			this.options = [];
			this.options = options;
		},
		async convertSegmentTouppercase() {
			let segments = this.$store.getters['firebase/getAllSubtitles'];
			for (let i = 0; i < segments.length; i++) {
				if (this.getIsTranslate) {
					segments[i].translate_text = segments[i].translate_text.toUpperCase();
					segments[i].translate_text = this.transformTagsUppercaseToLowerCase(segments[i].translate_text);
				} else {
					segments[i].text = segments[i].text.toUpperCase();
					segments[i].text = this.transformTagsUppercaseToLowerCase(segments[i].text);
				}
				await this.$store.dispatch('firebase/updateSegment', { segment: segments[i] });
			}
		},
		transformTagsUppercaseToLowerCase(textSegment) {
			textSegment = textSegment.replace('<I>', '<i>');
			textSegment = textSegment.replace('</I>', '</i>');
			textSegment = textSegment.replace('<B>', '<b>');
			textSegment = textSegment.replace('</B>', '</b>');
			return textSegment;
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin button-menu {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-height: 30px;
	position: relative;
	padding-left: 20px;
	min-width: 220px;

	.text-button {
		padding-left: 6px;
	}

	&:hover {
		background-color: #4f4c4c;
	}

	i {
		position: absolute;
		right: 10px;
	}
}

@mixin manu-area {
	background-color: #1a1a1a;
	box-shadow: 0px 0px 10px #000000;
	border-radius: 2px;
	position: absolute;
	min-width: 220px;
	height: auto;
}

.menu-nav-bar {
	width: 20%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.menu-dropdown {
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;
	justify-content: center;

	padding-left: 0%;
	height: 100%;
	cursor: pointer;

	&:hover {
		i {
			margin-top: 10px;
		}
	}

	&:active,
	select {
		background-color: #9747ff;
	}

	img {
		width: auto;
		height: 80%;
	}

	i {
		margin-left: 10px;
	}

	.menu-options {
		position: absolute;
		top: 100%;
		left: 5%;
		@include manu-area;

		.option-button {
			position: relative;
			@include button-menu;

			.sub-option {
				display: flex;
				flex-direction: column;
				min-width: 250px !important;
				left: 100%;
				top: 0;
				@include manu-area;

				.sub-option-item {
					@include button-menu;
				}
			}
		}
	}
}

.disabled {
	color: #3a3838;

	&:hover {
		background-color: #1a1a1a;
	}
}

.divider {
	width: 100%;
	height: 2px;
	background-color: #505050;
}
</style>
