import { MAX_TOTAL_CARACTERES, MAX_ROWS_PER_SEGMENT, REVIEW_MODE_CC } from '../configs/rules';

/**
 * Recorta o texto e retorna dois textos, um com o texto antes da seleção e outro com o texto depois da seleção.
 * @param {String} text Texto a ser cortado
 * @param {String} selectionStart "posição inicial do cursor"
 * @returns {Object} {firstText, secondText}
 */
function splitTextBySelectionStart(text, selectionStart, persistFormat = false) {
	if (!verifyIfTextIsValid(text)) return;
	let firstText = text.substring(0, selectionStart);
	if (!persistFormat) {
		firstText = firstText.replace(/\n/g, ' ');
	}

	const secondText = text.substring(selectionStart, text.length).replace(/\n/g, ' ');
	return { firstText, secondText };
}

/**
 * Remove espaçamentos consecutivos e desnecessários após quebra de linha
 * @param {String} text Texto a ser tratado
 * @returns {String} Texto tratado
 */
function removeConsecutiveSpaces(text) {
	if (!verifyIfTextIsValid(text)) return;
	return text.replace(/ {2,}/g, ' ').replace(/\n /g, '\n');
}

/**
 * Remove espçaos no começo e no final da string
 * @param {String} text Texto a ser tratado
 * @returns {String} Texto sem espaços no começo
 */
function removeSpaceInTheBeginningAndEnd(text) {
	if (!verifyIfTextIsValid(text)) return;
	return text.replace(/^\s+/, '');
}

/**
 * Transforma para upperCase a primeira letra após uma pontuação
 * @param {String} text Texto a ser inserido letras maiusculas
 * @returns {String} Texto tratado
 */
function upperCaseFirstLetterAfterPontuation(text) {
	if (!verifyIfTextIsValid(text)) return;
	let palavras = text.split(' ');
	let resultado = '';

	for (let i = 0; i < palavras.length; i++) {
		let palavra = palavras[i];

		// Verifica se a palavra é um link
		if (/^www\.[a-z]+\.[a-z]+$/i.test(palavra)) {
			resultado += palavra.toLowerCase() + ' ';
			continue;
		}

		// Converte a primeira letra da palavra em maiúscula, se necessário (pós pontuações .?!¡¿)
		if (i > 0 && /[.?!¡¿]\s*$/.test(palavras[i - 1])) {
			palavra = palavra.charAt(0).toUpperCase() + palavra.slice(1);
		}

		resultado += palavra + ' ';
	}

	return resultado.trim();
}

function upperCaseFirstLetterAfterLineBreaks(text) {
	if (text === '' || !text) return '';

	let lines = text.split(/\r?\n/);
	let result = '';

	for (let i = 0; i < lines.length; i++) {
		let line = lines[i];

		if (i > 0 && /[.?!¡¿]\s*$/.test(lines[i - 1])) {
			line = line.charAt(0).toUpperCase() + line.slice(1);
		}

		result += line + '\n';
	}

	return result.trim();
}

function changeCurlyQuotationToStraight(text) {
	if (text === '' || !text) return '';
	let straightQuotes = text.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
	return straightQuotes;
}

/**
 * Verifica se o segmento termina com ponto final
 * @param {String} text Texto a ser verificado
 * @returns {Boolean}
 */
function isSegmentTerminatedWithPunctuation(text) {
	if (!verifyIfTextIsValid(text)) return false;
	let punctuation = ['.', '?', '!', '¿', '¡'];
	let lastChar = text.substring(text.length - 1, text.length);
	return punctuation.includes(lastChar);
}

// convert text to 2 line
function convertTextTo2Line(text) {
	if (!verifyIfTextIsValid(text)) return;
	let lines = text.split('\n');

	let text2Line = '';
	for (let i = 0; i < lines.length; i++) {
		if (i == 0) text2Line += lines[i] + '\n';
		else text2Line += ' ' + lines[i];
	}
	return removeConsecutiveSpaces(text2Line);
}

/**
 * Altera a primeira letra do texto para maiuscula
 * @param {String} text Texto a ser verificado
 * @returns {Boolean}
 */
function upperCaseFirstLetter(text) {
	if (!verifyIfTextIsValid(text)) return '';
	return text.substring(0, 1).toUpperCase() + text.substring(1, text.length);
}

function verifyIfTextIsValid(text) {
	if (text == '' || text == undefined || text.length == 0) return false;
	return true;
}

function totalCaractersIsValid(text) {
	try {
		//remove all html tags from text
		// eslint-disable-next-line no-useless-escape
		if (!REVIEW_MODE_CC) text = text.replace(/<\/?[^>]+(>|$)|[.,\!^*;:`~?'"¿¡`´]/g, '');
		else text = text.replace(/<\/?[^>]+(>|$)/g, '');
		text = text.replace('\n', '');
		return text.length > MAX_TOTAL_CARACTERES ? false : true;
	} catch (err) {
		return true;
	}
}

function lineBreaksIsValid(text) {
	try {
		let breakLines = text.split('\n').length;
		return breakLines > MAX_ROWS_PER_SEGMENT ? false : true;
	} catch (err) {
		return true;
	}
}

// check de number of caracters in a line
function totalCaractersPerLine(text) {
	try {
		// eslint-disable-next-line no-useless-escape
		if (!REVIEW_MODE_CC) text = text.replace(/<\/?[^>]+(>|$)|[.,\!^*;:`~?'"¿¡`´]/g, '');
		else text = text.replace(/<\/?[^>]+(>|$)/g, '');
		let lines = text.split('\n');
		let caractersInLine = [];
		for (let i = 0; i < lines.length; i++) {
			caractersInLine.push({ line: i + 1, totalCaracteres: lines[i].length });
		}
		return caractersInLine;
	} catch (err) {
		return [];
	}
}

function calculateCPS(text, duration) {
	try {
		if (duration == 0 || !duration || text.length == 0) return 0;
		// eslint-disable-next-line no-useless-escape
		if (!REVIEW_MODE_CC) text = text.replace(/<\/?[^>]+(>|$)|[.,\!^*;:`~?'"¿¡`´]/g, '');
		else text = text.replace(/<\/?[^>]+(>|$)/g, '');
		let cps = Math.round(text.length / duration);
		return cps;
	} catch (err) {
		return 0;
	}
}

function validate(text) {
	if (!text) return text;

	let textValidate = text;
	let tags = textValidate.match(/<\/?\w+>/g); // Procura por todas as tags

	if (tags === null) {
		textValidate = replaceTags(textValidate);
		return textValidate;
	}

	// Se o numero for impar, existe erro nas tags
	if (tags.length % 2 !== 0) {
		// change < and > to &lt; and &gt;
		textValidate = replaceTags(textValidate);
		return textValidate;
	} else {
		let totalCaracters = 0;
		tags.forEach((tag) => {
			totalCaracters += tag.length;
		});

		// Se a quantidade de caracteres forem a mesmo das tags, não existe texto dentro das tags
		if (text.length === totalCaracters) {
			textValidate = replaceTags(textValidate);
			return textValidate;
		} else {
			return checkHTML(text) ? text : replaceTags(text); // Se for uma expressão html válida, então não precisa de alterações
		}
	}
}

const checkHTML = function (html) {
	let doc = document.createElement('div');
	doc.innerHTML = html;
	return doc.innerHTML === html;
};

// Replace < and > to &lt; and &gt;
/* eslint-disable */
function replaceTags(text) {
	let textValidate = text;
	textValidate = textValidate.replace(/&/g, '&amp;');
	textValidate = textValidate.replace(/</g, '&lt;');
	textValidate = textValidate.replace(/>/g, '&gt;');
	textValidate = textValidate.replace(/"/g, '&quot;');
	textValidate = textValidate.replace(/'/g, '&#39;');
	textValidate = textValidate.replace(/\//g, '&#47;');
	textValidate = textValidate.replace(/\\/g, '&#92;');
	textValidate = textValidate.replace(/\n/g, '<br>'); // Substitui quebras de linha por <br>
	textValidate = textValidate.replace(/#/g, '&#35;');
	textValidate = textValidate.replace(/%/g, '&#37;');
	textValidate = textValidate.replace(/\(/g, '&#40;');
	textValidate = textValidate.replace(/\)/g, '&#41;');
	textValidate = textValidate.replace(/\[/g, '&#91;');
	textValidate = textValidate.replace(/\]/g, '&#93;');
	textValidate = textValidate.replace(/\{/g, '&#123;');
	textValidate = textValidate.replace(/\}/g, '&#125;');
	textValidate = textValidate.replace(/:/g, '&#58;');
	textValidate = textValidate.replace(/;/g, '&#59;');
	textValidate = textValidate.replace(/=/g, '&#61;');
	textValidate = textValidate.replace(/~/g, '&#126;');
	textValidate = textValidate.replace(/\^/g, '&#94;');
	textValidate = textValidate.replace(/`/g, '&#96;');
	textValidate = textValidate.replace(/\|/g, '&#124;');
	textValidate = textValidate.replace(/!/g, '&#33;');
	textValidate = textValidate.replace(/\?/g, '&#63;');
	textValidate = textValidate.replace(/\+/g, '&#43;');
	textValidate = textValidate.replace(/\-/g, '&#45;');
	textValidate = textValidate.replace(/\*/g, '&#42;');
	textValidate = textValidate.replace(/_/g, '&#95;');
	textValidate = textValidate.replace(/@/g, '&#64;');
	textValidate = textValidate.replace(/\$/g, '&#36;');
	textValidate = textValidate.replace(/\#/g, '&#35;');
	// Adicione mais substituições conforme necessário para outros caracteres especiais

	return textValidate;
}

function convertKeyCodeToCode(keyCode) {
	if (keyCode < 65 || keyCode > 90) {
		switch (keyCode) {
			case 32:
				return 'Space';
			case 8:
				return 'Backspace';
			case 13:
				return 'Enter';
			case 16:
				return 'Shift';
			case 17:
				return 'Ctrl';
			case 18:
				return 'Alt';
			case 20:
				return 'CapsLock';
			case 27:
				return 'Esc';
			case 33:
				return 'PageUp';
			case 34:
				return 'PageDown';
			case 35:
				return 'End';
			case 36:
				return 'Home';
			case 37:
				return 'ArrowLeft';
			case 38:
				return 'ArrowUp';
			case 39:
				return 'ArrowRight';
			case 40:
				return 'ArrowDown';
			case 45:
				return 'Insert';
			case 46:
				return 'Delete';
			case 91:
				return 'Meta';
			case 93:
				return 'ContextMenu';
			case 144:
				return 'NumLock';
			case 145:
				return 'ScrollLock';
			case 186:
				return 'Semicolon';
			case 187:
				return 'Equal';
			case 188:
				return 'Comma';
			case 189:
				return 'Minus';
			case 190:
				return 'Period';
			case 191:
				return 'Slash';
			case 192:
				return 'Backquote';
			case 219:
				return 'BracketLeft';
			case 220:
				return 'Backslash';
			case 221:
				return 'BracketRight';
			case 222:
				return 'Quote';
			case 229:
				return 'Process';
			default:
				return '';
		}
	}
	return `Key${String.fromCharCode(keyCode).toUpperCase()}`;
}

function enforceSigleLineBreak(text, lineBreakPosition) {
	// if text is empty, do nothing
	if (text.length === 0) {
		return text;
	}

	// text should have \n at lineBreakPosition, and no other \n
	if (text[lineBreakPosition] !== '\n') {
		return text;
	}

	// if line break inserted at the end of text, do nothing
	if (lineBreakPosition === text.length - 1) {
		return text.slice(0, lineBreakPosition);
	}

	// if line break inserted at the beginning of text, return text without line break
	if (lineBreakPosition === 0) {
		return text.slice(1).replace(/\r\n|\r|\n/gm, ' ');
	}

	// if has only spaces in each line, return text without line break
	if (/^\s+\n\s+$/gm.test(text)) {
		return text.replace(/\r\n|\r|\n/gm, '');
	}

	let textBeforeLineBreak = text.slice(0, lineBreakPosition);
	let textAfterLineBreak = text.slice(lineBreakPosition + 1);

	const regex = /(\r\n|\r|\n)|(\s\s+)/gm; // detect line breaks and consecutive spaces
	textBeforeLineBreak = textBeforeLineBreak.replace(regex, ' ').trim();
	textAfterLineBreak = textAfterLineBreak.replace(regex, ' ').trim();

	// if textAfterLineBreak is empty, return textBeforeLineBreak
	if (textAfterLineBreak.length === 0) {
		return textBeforeLineBreak;
	}

	return `${textBeforeLineBreak}\n${textAfterLineBreak}`;
}

export default {
	splitTextBySelectionStart,
	removeConsecutiveSpaces,
	removeSpaceInTheBeginningAndEnd,
	upperCaseFirstLetterAfterPontuation,
	upperCaseFirstLetterAfterLineBreaks,
	changeCurlyQuotationToStraight,
	isSegmentTerminatedWithPunctuation,
	upperCaseFirstLetter,
	totalCaractersIsValid,
	totalCaractersPerLine,
	lineBreaksIsValid,
	calculateCPS,
	convertTextTo2Line,
	validate,
	verifyIfTextIsValid,
	convertKeyCodeToCode,
	enforceSigleLineBreak,
	replaceTags,
};
