import {
	MAX_CPS,
	MAX_TOTAL_CARACTERES,
	MAX_CARACTERES_PER_LINE,
	MAX_ROWS_PER_SEGMENT,
	CPS_RECOMMENDATION,
	MAX_DURATION_PER_SEGMENT,
	MAX_CARACTERES_PER_LINE_BLOCK,
} from '../configs/rules';
import TextTools from './textTools';

/**
 * Realiza tratamento nos textos. Remove espaçamentos duplos e espaços desnecessários.
 * Caso o segmento anterior seja terminado com pontuação, a primeira letra do segmento é maiuscula.
 * @param {Object} segment Segmento a ser tratado
 * @returns {Object} Segmentos tratados
 */
function clearSegmentText(segment) {
	try {
		if (segment.text !== '' && segment.text) {
			segment.text = TextTools.removeConsecutiveSpaces(segment.text);
			segment.text = TextTools.removeSpaceInTheBeginningAndEnd(segment.text);
			segment.text = TextTools.upperCaseFirstLetterAfterPontuation(segment.text);
			segment.text = TextTools.upperCaseFirstLetterAfterLineBreaks(segment.text);
			segment.text = TextTools.changeCurlyQuotationToStraight(segment.text);
		} else {
			segment.text = '';
		}

		if (segment.translate_text !== '' && segment.translate_text) {
			segment.translate_text = TextTools.removeConsecutiveSpaces(segment.translate_text);
			segment.translate_text = TextTools.removeSpaceInTheBeginningAndEnd(segment.translate_text);
			segment.translate_text = TextTools.upperCaseFirstLetterAfterPontuation(segment.translate_text);
			segment.translate_text = TextTools.upperCaseFirstLetterAfterLineBreaks(segment.translate_text);
			segment.translate_text = TextTools.changeCurlyQuotationToStraight(segment.translate_text);
		} else {
			segment.translate_text = '';
		}

		return segment;
	} catch (error) {
		console.error('Erro [clearSegmentText] ao realizar limpeza de texto do segmento', error);
	}
}

/**
 * Check todos os erros relacionados ao textos do segmento
 * @param {Object} segment Segmento de referência
 * @param {Object} nextSegment segmento a ser analisado
 * @returns {Object} nextSegment tratado
 */
function firstLatterToUpperCaseIfNeed(segment, nextSegment) {
	try {
		segment = clearSegmentText(segment);
		nextSegment = clearSegmentText(nextSegment);

		if (TextTools.isSegmentTerminatedWithPunctuation(segment.text)) {
			nextSegment.text = TextTools.upperCaseFirstLetter(nextSegment.text);
		}

		if (TextTools.isSegmentTerminatedWithPunctuation(segment.translate_text)) {
			nextSegment.translate_text = TextTools.upperCaseFirstLetter(nextSegment.translate_text);
		}

		return nextSegment;
	} catch (err) {
		console.error(
			'Erro [updateNextSegmentIfNeeded]: Não foi possível converter a primeira letra do segmento para maíusculo.',
			err
		);
		return null;
	}
}

function cutFirstWorldAndPasteOnActualSegment(currentSegment, nextSegment, isTranslate) {
	// Recorta primeira palavra do proximo segmento e coloca no atual
	if (!isTranslate) {
		let firstWord = nextSegment.text.split(' ')[0];
		nextSegment.text = nextSegment.text.replace(firstWord, '');
		currentSegment.text = currentSegment.text + ' ' + firstWord;
	} else {
		let firstWord = nextSegment.translate_text.split(' ')[0];
		nextSegment.translate_text = nextSegment.translate_text.replace(firstWord, '');
		currentSegment.translate_text = currentSegment.translate_text + ' ' + firstWord;
	}

	return { currentSegment, nextSegment };
}

function cutFirstWorldAndPasteOnActualSegmentWithTime(currentSegment, nextSegment, isTranslate) {
	// Recorta primeira palavra do proximo segmento e coloca no atual
	if (!isTranslate) {
		let firstWord = nextSegment.text.split(' ')[0];
		let selectionCut = firstWord.length;
		// Recalcula os tempos de acordo com o novo texto
		const durationCurrentSegment = nextSegment.end - nextSegment.start;
		const newEndTimeCurrentSegment = (durationCurrentSegment * selectionCut) / nextSegment.text.length;

		nextSegment.text = nextSegment.text.replace(firstWord, '');
		nextSegment.start = nextSegment.start + newEndTimeCurrentSegment;

		currentSegment.text = currentSegment.text + ' ' + firstWord;
		currentSegment.end = nextSegment.start;
	} else {
		let firstWord = nextSegment.translate_text.split(' ')[0];
		let selectionCut = firstWord.length;

		// Recalcula os tempos de acordo com o novo texto
		const durationCurrentSegment = nextSegment.end - nextSegment.start;
		const newEndTimeCurrentSegment = (durationCurrentSegment * selectionCut) / nextSegment.text.length;

		nextSegment.translate_text = nextSegment.translate_text.replace(firstWord, '');
		nextSegment.start = nextSegment.start + newEndTimeCurrentSegment;

		currentSegment.translate_text = currentSegment.translate_text + ' ' + firstWord;
		currentSegment.end = nextSegment.start;
	}

	return { nextSegment, currentSegment };
}

/**
 * Check todos os erros relacionados ao textos do segmento
 * @param {Object} segment Segmento a ser tratado
 * @param {Object} isTranslate Indica se deve ser exibido error da tradução ou transcrição
 * @returns {Object} errors:{type:String, transcriptions: {isCritical: boolean, list: []}, translations:  {isCritical: boolean, list: []}}
 */
function checkErrors(segment, isTranslate, options = {}) {
	const { cpsRecomendationWarning = true } = options || {};

	segment.errors = null;
	let idError = 0;
	let errors = {
		type: '',
		transcription: {
			isCritical: false,
			list: [],
		},
		translation: {
			isCritical: false,
			list: [],
		},
	};

	//console.time('checkErrors')
	const duration = (Number(segment.end) - Number(segment.start)) / 1000;
	//console.timeEnd('checkErrors')

	if (!isTranslate) {
		let cpsErrorPerLine = TextTools.totalCaractersPerLine(segment.text);

		// Check caracteres
		if (!TextTools.totalCaractersIsValid(segment.text)) {
			errors.transcription.isCritical = true;
			errors.transcription.list.push({
				errorId: idError++,
				isCritical: true,
				msg: `Caracteres total do segmento: ${segment.text.length} (recomendado: ${MAX_TOTAL_CARACTERES})`,
			});
		}

		// Check caracteres per line
		if (cpsErrorPerLine.length > 0) {
			cpsErrorPerLine.forEach((error) => {
				if (error.totalCaracteres <= MAX_CARACTERES_PER_LINE) return;
				errors.transcription.isCritical = MAX_CARACTERES_PER_LINE_BLOCK;
				errors.transcription.list.push({
					errorId: idError++,
					isCritical: MAX_CARACTERES_PER_LINE_BLOCK,
					msg: `Linha ${error.line}: ${error.totalCaracteres} caracteres (recomendado: ${MAX_CARACTERES_PER_LINE})`,
				});
			});
		}

		// Check the number of line breaks
		if (!TextTools.lineBreaksIsValid(segment.text)) {
			errors.transcription.isCritical = true;
			errors.transcription.list.push({
				errorId: idError++,
				isCritical: true,
				msg: `Total de linhas: ${segment.text.split('\n').length} (recomendado: ${MAX_ROWS_PER_SEGMENT})`,
			});
		}

		// Check if cps is valid
		const cps = TextTools.calculateCPS(segment.text, duration);
		if (cps > MAX_CPS) {
			errors.transcription.isCritical = true;
			errors.transcription.list.push({
				errorId: idError++,
				isCritical: true,
				msg: `CPS acima do permitido (máximo: ${MAX_CPS})`,
			});
		} else if (cpsRecomendationWarning && cps < CPS_RECOMMENDATION) {
			errors.transcription.list.push({
				errorId: idError++,
				isCritical: false,
				msg: `CPS muito baixo (recomendado: ${CPS_RECOMMENDATION})`,
			});
		}

		// check if start time is greater eath than the end time
		if (Number(segment.end) <= Number(segment.start)) {
			errors.transcription.isCritical = true;
			errors.transcription.list.push({
				errorId: idError++,
				isCritical: true,
				msg: `O tempo inicial do segmento é maior ou igual ao tempo final.`,
			});
		}

		// Check if segment has time minus than 1s
		if (Number(segment.end) - segment.start < 1000) {
			errors.transcription.list.push({
				errorId: idError++,
				isCritical: false,
				msg: `O segmento possui duração menor que um segundo.`,
			});
		}

		// check if duration is gratter than 8 seconds
		if (duration > MAX_DURATION_PER_SEGMENT) {
			errors.transcription.list.push({
				errorId: idError++,
				isCritical: false,
				msg: `A duração do segmento é maior que ${MAX_DURATION_PER_SEGMENT} segundos.`,
			});
		}

		if (errors.transcription.isCritical) errors.type = 'danger';
		else if (errors.transcription.list.length > 0) errors.type = 'warning';
		else errors.type = '';
	} else {
		let cpsErrorPerLineTranslation = TextTools.totalCaractersPerLine(segment.translate_text);
		// Translations Validation - Is the same as transcription, can be refactore
		if (!TextTools.totalCaractersIsValid(segment.translate_text)) {
			errors.translation.isCritical = true;
			errors.translation.list.push({
				errorId: idError++,
				isCritical: true,
				msg: `Caracteres total do segmento: ${segment.translate_text.length} (recomendado: ${MAX_TOTAL_CARACTERES})`,
			});
		}

		if (cpsErrorPerLineTranslation.length > 0) {
			cpsErrorPerLineTranslation.forEach((error) => {
				if (error.totalCaracteres <= MAX_CARACTERES_PER_LINE) return;
				errors.translation.isCritical = MAX_CARACTERES_PER_LINE_BLOCK;
				errors.translation.list.push({
					errorId: idError++,
					isCritical: MAX_CARACTERES_PER_LINE_BLOCK,
					msg: `Linha ${error.line}: ${error.totalCaracteres} caracteres (recomendado: ${MAX_CARACTERES_PER_LINE})`,
				});
			});
		}

		if (!TextTools.lineBreaksIsValid(segment.translate_text)) {
			errors.translation.isCritical = true;
			errors.translation.list.push({
				errorId: idError++,
				isCritical: true,
				msg: `Total de linhas: ${segment.translate_text.split('\n').length} (recomendado: ${MAX_ROWS_PER_SEGMENT})`,
			});
		}

		const cps = TextTools.calculateCPS(segment.translate_text, duration);
		if (cps > MAX_CPS) {
			errors.translation.isCritical = true;
			errors.translation.list.push({
				errorId: idError++,
				isCritical: true,
				msg: `CPS acima do permitido (máximo: ${MAX_CPS})`,
			});
		} else if (cpsRecomendationWarning && cps < CPS_RECOMMENDATION) {
			errors.translation.list.push({
				errorId: idError++,
				isCritical: false,
				msg: `CPS muito baixo (recomendado: ${CPS_RECOMMENDATION})`,
			});
		}

		// check if start time is greater than the end time
		if (Number(segment.end) <= Number(segment.start)) {
			errors.translation.isCritical = true;
			errors.translation.list.push({
				errorId: idError++,
				isCritical: true,
				msg: `O tempo inicial do segmento é maior ou igual ao tempo final.`,
			});
		}

		// check if segement time is minus than 1s
		if (Number(segment.end) - Number(segment.start) < 1000) {
			errors.translation.list.push({
				errorId: idError++,
				isCritical: false,
				msg: `O segmento possui duração menor que um segundo.`,
			});
		}

		// check if duration is gratter than 8 seconds
		if (duration > MAX_DURATION_PER_SEGMENT) {
			errors.translation.list.push({
				errorId: idError++,
				isCritical: false,
				msg: `A duração do segmento é maior que ${MAX_DURATION_PER_SEGMENT} segundos.`,
			});
		}

		if (errors.translation.isCritical) {
			errors.type = 'danger';
		} else if (errors.translation.list.length > 0) {
			errors.type = 'warning';
		} else errors.type = '';
	}

	segment.errors = errors;

	return segment;
}

function showIfSegmentHasErrors(segment, isTranslate = false) {
	if (isTranslate) {
		if (segment.errors == undefined) return false;
		if (segment.errors.translation.list.length > 0) return true;
		return false;
	} else {
		if (segment.errors == undefined) return false;
		if (segment.errors.transcription.list.length > 0) return true;
		return false;
	}
}

/**
 * Check se existe sobreposição de tempo do segmento a ser salvo com o segmento analisado
 * @param {Object} segment Segmento a ser salvo
 * @param {Object} beforeSegment Segmento a ser analisado
 * @param {Object} nextSegment Segmento a ser analisado
 * @returns {Object} {beforeSegmentUpdated: Object, nextSegmentUpdated: Object} Segmentos tratados
 */
function checkIfHasTimeOverlap(segment, beforeSegment = null, nextSegment = null, maxDuration, userPreferences) {
	// criar validação de sobreposição se activeSegmentOverlap == false
	if (userPreferences && !userPreferences.activeSegmentOverlap) {
		if (nextSegment != null) {
			if (Number(segment.end) >= Number(nextSegment.end)) {
				segment.end = Number(nextSegment.start);
			}
		}

		if (beforeSegment != null) {
			if (Number(segment.start) <= Number(beforeSegment.start)) {
				segment.start = Number(beforeSegment.end);
			}
		}
	}

	// Verifica se o tempo do segmento é maior que a duração do vídeo
	if (Number(segment.end) > maxDuration) {
		if (nextSegment == null) {
			segment.end = maxDuration;
		} else {
			segment.end = Number(nextSegment.start);
		}
	}

	// Verifica se o tempo do segmento é menor que 0;
	if (Number(segment.start) < 0) {
		if (beforeSegment == null) {
			segment.start = 0;
		} else {
			segment.start = Number(beforeSegment.end);
		}
	}

	// Ajuste o tempo do segmento anterior (overlap)
	if (beforeSegment != null) {
		if (Number(segment.start) < Number(beforeSegment.end)) beforeSegment.end = Number(segment.start);
	}

	// Ajusta o tempo do segmento posterior (overlap)
	if (nextSegment != null) {
		if (Number(segment.end) > Number(nextSegment.start)) nextSegment.start = Number(segment.end);
	}

	// Adiciona tempo final ao segmento caso menor que tempo minimo
	// let hasMinusThanOneSecond = segment.end - segment.start;
	// if (hasMinusThanOneSecond < 1000) {
	// 	let timeToAdd = Number(segment.end) + (1000 - hasMinusThanOneSecond);
	// 	segment.end = timeToAdd;
	// 	if (Number(segment.end) > Number(nextSegment.start)) nextSegment.start = Number(segment.end);
	// }

	return {
		segmentUpdated: segment,
		beforeSegmentUpdated: beforeSegment,
		nextSegmentUpdated: nextSegment,
	};
}

export default {
	clearSegmentText,
	firstLatterToUpperCaseIfNeed,
	checkErrors,
	showIfSegmentHasErrors,
	checkIfHasTimeOverlap,
	cutFirstWorldAndPasteOnActualSegment,
	cutFirstWorldAndPasteOnActualSegmentWithTime,
};
