<template>
	<div id="cps-box" class="cps-segment">
		<div id="cps-calculate" :class="getCpsStyle">
			<span>CPS: {{ cps }}</span>
		</div>
		<template v-if="hasError">
			<b-tooltip
				target="cps-calculate"
				:title="`CPS acima do permitido.\n CPS máximo: ${MAX_CPS}`"
				triggers="hover"
				placement="top"
				delay="{show: 500, hide: 2000}"
			/>
		</template>
		<template v-else-if="hasWarning">
			<b-tooltip
				target="cps-calculate"
				:title="`CPS muito baixo.\n CPS recomendado: ${CPS_RECOMMENDATION}`"
				triggers="hover"
				placement="top"
				delay="{show: 500, hide: 2000}"
			/>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MAX_CPS, CPS_RECOMMENDATION } from '../../configs/rules';
import textTools from '../../utils/textTools';

export default {
	props: {
		text: {
			type: String,
			default: '',
		},
		duration: {
			type: Number,
			default: -1,
		},
	},
	data() {
		return {
			hasError: false,
			hasWarning: false,
		};
	},
	computed: {
		...mapGetters({
			settings: 'firebase/getSettings',
		}),

		getCpsStyle() {
			if (this.hasError) return ['cps-calculate', 'invalid'];
			if (this.hasWarning) return ['cps-calculate', 'warning'];
			return 'cps-calculate';
		},
		cps() {
			return textTools.calculateCPS(this.text, this.duration);
		},
		CPS_RECOMMENDATION() {
			return CPS_RECOMMENDATION;
		},
		MAX_CPS() {
			return MAX_CPS;
		},
	},
	watch: {
		cps(value) {
			this.hasError = Boolean(value > MAX_CPS);
			this.hasWarning =
				!this.hasError && this.settings.cps_recomendation_warning && Boolean(value < CPS_RECOMMENDATION);
		},
		hasError(value) {
			if (value) {
				setTimeout(() => {
					this.$root.$emit('bv::show::tooltip', 'cps-calculate');
					setTimeout(() => {
						this.$root.$emit('bv::hide::tooltip', 'cps-calculate');
					}, 2000);
				}, 100);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@use '../../app.scss' as *;

@mixin glass {
	background: rgba(99, 97, 97, 0.411);
	border-radius: 5px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(7.6px);
	-webkit-backdrop-filter: blur(7.6px);
	border: 1px solid rgba(0, 0, 0, 0.97);
}

// Invalid
.cps-segment {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	height: 100%;

	.cps-calculate {
		@include glass;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80%;
		max-width: 80%;
		height: 80%;
		padding: 3px;
		margin-left: 5px;
	}

	i {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 20%;
		color: $invalid;
		margin-left: 3px;
	}

	.invalid {
		background-color: $invalid;
	}

	.warning {
		color: var(--dark) !important;
		background-color: $warning;
	}

	&:hover {
		cursor: pointer;
	}
}
</style>
