<template>
	<button id="finalizeRevisionButton" class="finalize-button" @click="openCloseModal">
		Finalizar
		<i class="fa fa-angle-right"></i>
		<AssessmentsFeedback v-if="getReviewdByQa && isAssessment" :open-modal="openModal" @finalizeTask="finalizeTask" />
		<JobFeedback v-else :open-modal="openModal" @finalizeTask="finalizeTask" />
	</button>
</template>

<script>
import JobFeedback from './Feedback/JobFeedback.vue';
import AssessmentsFeedback from './Feedback/AssessmentsFeedback.vue';

export default {
	components: {
		JobFeedback,
		AssessmentsFeedback,
	},
	data() {
		return {
			openModal: false,
		};
	},
	computed: {
		getSubtitlesNotConfirmed() {
			return this.$store.getters['firebase/getAllSubtitles'].filter((subtitle) => subtitle.is_saved === false);
		},
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getReviewdByQa() {
			if (
				this.$store.getters['firebase/getReviewdByQa'] ||
				this.getTask.status == 'evaluating_transcription' ||
				this.getTask.status == 'evaluating_translation'
			) {
				return true;
			} else {
				return false;
			}
		},
		isAssessment() {
			return this.getTask.assessment_id ? true : false;
		},
		getPerssionToSaveWithCriticalError() {
			return this.$store.getters['firebase/getPerssionToSaveWithCriticalError'];
		},
	},
	methods: {
		openCloseModal() {
			// Verifica se o job é vindo do SkySub
			if (this.getTask.isSelfReviewing == false) {
				if (!this.verifyIfCanFinishTask()) {
					this.$store.dispatch('controller/setErrorNotification', {
						title: 'Erro ao finalizar a revisão',
						message:
							'Não foi possível finalizar a revisão. Confirme e trate os erros críticos de todos os segmentos antes de finalizar!',
					});
					return;
				}
				this.openModal = !this.openModal;
				// Jobs vindos do Legenda.Ai
			} else {
				if (!this.verifyIfCanFinishTask()) {
					this.$store.dispatch('controller/setErrorNotification', {
						title: 'Erro ao finalizar a revisão',
						message:
							"Salve todos os segmentos e trate os erros críticos ou altere suas configurações em 'Preferências' antes de finalizar",
					});
					return;
				}
				this.openModal = !this.openModal;
			}
		},
		verifyIfCanFinishTask() {
			if (this.getReviewdByQa) return true;
			if (this.getPerssionToSaveWithCriticalError) return true;
			if (this.getSubtitlesNotConfirmed.length > 0 && this.getTask.type != 'txt') {
				return false;
			}
			return true;
		},
		async finalizeTask(data = null) {
			this.$store.dispatch('setLoadingScreen', true);
			if (this.isAssessment) {
				await this.$store.dispatch('firebase/finalizeAssessment', { feedback: data }).then(() => {
					this.$store.dispatch('setLoadingScreen', false);
					this.$router.push('/finalize');
				});
			} else {
				await this.$store.dispatch('firebase/finalizeTask', { feedback: data }).then(() => {
					this.$store.dispatch('setLoadingScreen', false);
					this.$router.push('/finalize');
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
button {
	background: #9747ff;
}
</style>
