<template>
	<div v-show="settingsModalOpen" id="settings-modal">
		<div class="settings-area row m-0 p-0">
			<i id="settings-close" class="fa fa-times-circle" aria-hidden="true" @click="closeSettingsModal"></i>
			<div class="row w-100 m-0 p-0">
				<div class="col-3 p-0 m-0 settings-options">
					<div class="row align-items-start justify-content-center m-0">
						<div class="col-12 d-flex settings-menu">
							<p class="config-title mt-3 text-center"><i class="fa fa-cog" aria-hidden="true"></i>Configurações</p>
							<button :class="actualMenuSelected == 0 ? 'selected' : ''" @click="setActualMenu(0)">Geral</button>
							<button :class="actualMenuSelected == 1 ? 'selected' : ''" @click="setActualMenu(1)">Atalhos</button>
							<button :class="actualMenuSelected == 2 ? 'selected' : ''" @click="setActualMenu(2)">Layout</button>
						</div>
					</div>
				</div>
				<div class="col-9 m-0 p-0">
					<PreferencesModal v-show="actualMenuSelected == 0" />
					<ShortcutesModal v-show="actualMenuSelected == 1" />
					<LayoutPage v-show="actualMenuSelected == 2" @aplyLayout="setLayout" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ShortcutesModal from './ShortcutesModal.vue';
import LayoutPage from './LayoutModal.vue';
import PreferencesModal from './PreferencesModal.vue';

export default {
	components: {
		ShortcutesModal,
		LayoutPage,
		PreferencesModal,
	},
	data() {
		return {
			actualMenuSelected: 0,
		};
	},
	computed: {
		settingsModalOpen() {
			return this.$store.getters['getPreferenceModalIsOpen'];
		},
	},
	methods: {
		setActualMenu(value) {
			this.actualMenuSelected = value;
		},
		setLayout(event) {
			this.$emit('aplyLayout', event);
		},
		closeSettingsModal() {
			this.$store.dispatch('setPreferenceModalIsOpen');
		},
	},
};
</script>

<style lang="scss">
@import '@/app.scss';

#settings-modal {
	z-index: 10;
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.35);
	padding: 10px;

	.title-settings {
		font-size: 1rem !important;
		margin-left: 1.5rem;
	}

	.settings-area {
		width: 60vw;
		max-width: 60vw;
		min-width: 60vw;
		min-height: 70%;
		max-height: 70%;
		background-color: $secondary;
		border: 2px solid $color-auxiliary;
		border-radius: 10px;
		position: relative;

		.settings-options {
			background-color: $secondary;
			border-right: 2px solid rgba(255, 255, 255, 0.114);

			.config-title {
				color: #fff;
				font-size: 1.2rem !important;
				width: 100%;

				i {
					margin-right: 15px;
				}
			}

			.settings-menu {
				display: flex;
				flex-direction: column;
				color: #fff;
				padding: 0rem 1rem;

				button {
					min-height: 40px;
					margin-bottom: 0.6rem;
					text-align: left;
					color: #fff;
					background-color: transparent;
					border: none;
					border-radius: 5px;
					padding-left: 10px;

					&:hover {
						background-color: #2e2e2e;
					}
				}

				.selected {
					background-color: #2e2e2e;
				}
			}
		}

		#settings-close {
			position: absolute;
			top: 1rem;
			right: 1.5rem;
			color: #fff;
			font-size: 1.5rem;
			z-index: 3;

			&:hover {
				cursor: pointer;
				transform: scale(1.05);
			}
		}

		#save-area {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			position: absolute;
			bottom: 20px;
			padding: 1rem;
			width: 100%;

			button {
				font-size: 1rem;
				background-color: $color-auxiliary;
				color: #ffffff;
				border-radius: 10px;
				padding: 5px;
				min-width: 150px;
				margin-left: 1rem;
			}
		}
	}
}
</style>
