<template>
	<div id="task-mode" class="mode">
		<p>Formato de entrega: {{ mode }}</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mode: '',
		};
	},
	computed: {
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
	},
	mounted() {
		this.subtitle_mode = this.getTask.subtitle_mode;
		this.description = '';
		if (this.subtitle_mode == 'txt') {
			this.mode = 'Texto corrido';
		} else if (this.subtitle_mode == 'cc') {
			this.mode = 'Closed Caption';
		} else {
			this.mode = 'Legenda normal';
		}
	},
};
</script>

<style lang="scss" scoped>
.task-mode {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	width: auto;
	height: 100%;
	margin: 0px 10px;
}

p {
	font-size: 14px !important;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 0px !important;
	margin-left: 20px;
}
</style>
