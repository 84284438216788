<template>
	<div class="backups-modal-container">
		<b-modal
			id="backups-modal"
			title="Gerenciador de backups"
			cancel-title="Cancelar"
			ok-title="Aplicar"
			@ok="applyBackup"
		>
			<b-container>
				<b-tabs v-if="!loading" content-class="mt-3">
					<b-tab title="Backups automáticos" active class="backup-area">
						<b-row v-for="(backup, index) in backupsUpdated" :key="index">
							<div
								:id="`backup-${index}`"
								:class="{ 'backup-row selected': index == backupSelect, 'backup-row': index != backupSelect }"
								@click="selectBackup(backup, index)"
							>
								<b-row>Backup <span v-if="index == 0" class="latest-backup-tag">Mais recente</span> </b-row>
								<b-row>Data: {{ new Date(backup['time']).toLocaleString('pt-BR') }}</b-row>
								<b-row>Último segmento editado: {{ backup['currentSegment'] }}</b-row>
								<b-row class="d-flex align-items-center justify-content-left">
									<i
										v-if="backup.online"
										v-b-tooltip.hover
										class="fas fa-cloud-download-alt m-2"
										title="Este backup foi salvo online"
									></i>
									<i
										v-if="backup.offline"
										v-b-tooltip.hover
										class="fas fa-hdd m-2"
										title="Este backup foi salvo localmente"
									></i>
								</b-row>
							</div>
						</b-row>
					</b-tab>
					<b-tab title="Backups Manual" class="backup-area">
						<b-row v-for="(backup, index) in manualBackups" :key="index" class="m-0 p-0">
							<div
								:id="`backup-${index}`"
								:class="{ 'backup-row selected': index == backupSelect, 'backup-row': index != backupSelect }"
								@click="selectBackup(backup, index)"
							>
								<b-row>Backup <span v-if="index == 0" class="latest-backup-tag">Mais recente</span> </b-row>
								<b-row>Data: {{ new Date(backup['time']).toLocaleString('pt-BR') }}</b-row>
								<b-row>Último segmento editado: {{ backup['currentSegment'] }}</b-row>
							</div>
						</b-row>
					</b-tab>
				</b-tabs>
				<b-row v-else align-h="center">
					<b-spinner label="Spinning"></b-spinner>
				</b-row>
			</b-container>
		</b-modal>
	</div>
</template>

<script>
export default {
	data() {
		return {
			backupRestore: null,
			backupSelect: null,
			backups: [],
			manualBackups: [],
			db: null,
			loading: true,
		};
	},
	computed: {
		getBackupFlag() {
			return this.$store.getters['getBackupFlag'];
		},
		backupsUpdated() {
			return this.backups;
		},
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
	},
	watch: {
		getBackupFlag() {
			if (window.db_task) {
				this.loading = true;
				this.updateBackups();
				this.getManualBackups();
			}
		},
	},
	methods: {
		async updateBackups() {
			this.backups = [];
			this.backupRestore = null;
			this.backupSelect = null;

			let offlineBackups = await this.getAllOfflineBackups();
			let onlineBackups = await this.getAllOnlineBackups();

			// Crie a lista de resultado
			const listaResultado = [];

			// Percorra a lista online e marque os elementos presentes como online=true
			for (const objetoOnline of onlineBackups) {
				const objetoOffline = offlineBackups.find((item) => item.id === objetoOnline.id);

				if (objetoOffline) {
					listaResultado.push({
						...objetoOnline,
						online: true,
						offline: true,
					});
				} else {
					listaResultado.push({
						...objetoOnline,
						online: true,
						offline: false,
					});
				}
			}

			// Percorra a lista offline e adicione os elementos ausentes na lista de resultado
			for (const objetoOffline of offlineBackups) {
				if (!listaResultado.find((item) => item.id === objetoOffline.id)) {
					listaResultado.push({
						...objetoOffline,
						online: false,
						offline: true,
					});
				}
			}

			// ordena por tempo do bbackup
			this.backups = listaResultado.sort((first, second) => {
				return second.time - first.time;
			});
			this.loading = false;
		},
		async getAllOfflineBackups() {
			return new Promise((resolve, reject) => {
				let transaction = window.db_task.transaction('backups', 'readonly');
				let objectStore = transaction.objectStore('backups');
				let objects = [];

				let request = objectStore.openCursor();

				request.onsuccess = function (event) {
					let cursor = event.target.result;
					if (cursor) {
						objects.push(cursor.value);
						cursor.continue();
					} else {
						resolve(objects); // Resolva a Promise quando todos os objetos forem recuperados
					}
				};

				request.onerror = function (event) {
					reject('Erro ao abrir cursor: ' + event.target.error);
				};
			});
		},
		async getAllOnlineBackups() {
			try {
				const allOnlineBackups = await this.$store.dispatch('firebase/getAllOnlineBackups');
				return allOnlineBackups;
			} catch (err) {
				return null;
			}
		},
		selectBackup(backup, index) {
			this.backupSelect = index;
			this.backupRestore = backup;
		},
		applyBackup() {
			this.$store.dispatch('firebase/setBackup', this.backupRestore);
			this.$store.dispatch('controller/setCurrentIndex', null);
			this.$nextTick(() => {
				this.$store.dispatch('controller/setCurrentIndex', this.backupRestore.currentSegment);
				this.backupRestore = null;
				this.backupSelect = null;
			});
		},
		async getManualBackups() {
			this.manualBackups = await new Promise((resolve, reject) => {
				let transaction = window.db_task.transaction(`manualBackups`, 'readwrite');
				let objectStore = transaction.objectStore(`manualBackups`);

				let objectRequest = objectStore.getAll();
				objectRequest.onsuccess = (event) => {
					let result = event.target.result.sort((first, second) => {
						return second.time - first.time;
					});
					resolve(result);
				};
				objectRequest.onerror = function (error) {
					console.error('Erro on getting manual backups...', error);
					reject(error);
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.backup-area {
	max-height: 50vh;
	width: 100% !important;
	overflow-y: auto;
	overflow-x: hidden;
}

.backup-row {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 120px;
	background-color: rgba(0, 0, 0, 0.533);
	border: 1px solid rgba(0, 0, 0);
	color: #fff;
	font-size: 16px;
	margin-top: 20px;

	&:hover {
		cursor: pointer;
	}
}

.latest-backup-tag {
	background-color: rgb(208, 41, 41);
	color: #fff;
	border-radius: 5px;
	padding: 0px 5px;
	position: absolute;
	right: 5px;
	top: 5px;
}

.selected {
	border: 3px solid rgb(255, 0, 0) !important;
}
</style>
