function srt2mili(srt) {
	const tempos1 = srt.split(',');
	const milis = parseInt(tempos1[1]);
	const tempos2 = tempos1[0].split(':');
	const horas = parseInt(tempos2[0]);
	const minutos = parseInt(tempos2[1]);
	const segundos = parseInt(tempos2[2]);
	const horasInMili = horas * 60 * 60 * 1000;
	const minInMili = minutos * 60 * 1000;
	const secInMili = segundos * 1000;
	const resultMillis = horasInMili + minInMili + secInMili + milis;
	return resultMillis;
}

function mili2srt(mili) {
	if (mili === 0) return '00:00:00,000';
	if (mili === undefined) return '';
	const addZero = function (number) {
		if (number < 10) {
			number = '0' + number;
		}
		return number;
	};
	const addDoubleZero = function (number) {
		if (number < 100) {
			if (number < 10) {
				number = '00' + number;
			} else {
				number = '0' + number;
			}
		}
		return number;
	};
	const clock =
		addZero(Math.floor(mili / 3600000)) +
		':' +
		addZero(Math.floor((mili % 3600000) / 60000)) +
		':' +
		addZero(Math.floor((mili % 60000) / 1000)) +
		',' +
		addDoubleZero(Math.floor(mili % 1000));
	return clock;
}

export { srt2mili, mili2srt };
