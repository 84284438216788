<template>
	<div class="title-video">
		<span id="video-name">{{ getTaskName.split('.')[0] }}</span>
		<b-tooltip
			target="video-name"
			:title="getTaskName"
			triggers="hover"
			placement="bottom"
			:delay="{ show: 1000, hide: 100 }"
		/>
	</div>
</template>

<script>
export default {
	computed: {
		getTaskName() {
			return this.$store.getters['firebase/getTask'].name;
		},
	},
};
</script>

<style lang="scss" scoped>
.title-video {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 250px;
	max-height: 24px;
	margin-left: 5%;
	margin-right: 5%;
	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 18px;
		color: #ffffff;
	}
}
</style>
