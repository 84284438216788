<template>
	<div id="config-modal" class="container">
		<div no-gutters class="row mt-5 shortcutes-button-area">
			<button @click="page = 'Navegação'">Navegação</button>
			<button @click="page = 'Segmentos'">Segmentos</button>
			<button @click="page = 'Texto'">Texto</button>
			<button @click="page = 'Outros'">Outros</button>
		</div>

		<div class="row mt-3 m-0 shortcutes-area">
			<b-col class="m-0 p-0">
				<b-row class="align-items-center justify-content-center m-0">{{ page }}</b-row>
				<div v-if="error != ''" class="error mt-2 mb-2">{{ error }}</div>

				<div>
					<div v-if="page == 'Navegação'" class="row m-0" active>
						<b-col class="m-0 p-0">
							<b-row v-for="shortcut in config" :key="shortcut.indice" class="m-0">
								<div v-if="shortcut.page == 1" class="config-item">
									<span class="description">{{ shortcut.title }}</span>
									<span v-if="shortcut.mainAtl == 'ctrl'" class="trigger">
										<i class="tecla-trigger"> CTRL </i>
										<b> + </b>
									</span>
									<span v-if="shortcut.mainAtl == 'alt'" class="trigger">
										<i class="tecla-trigger">ALT</i>
										<b> +</b>
									</span>
									<input
										v-if="shortcut.block"
										v-model="shortcut.atl"
										class="inputItem"
										maxlength="1"
										type="text"
										disabled
									/>
									<input
										v-if="!isArrow(shortcut.code) && !shortcut.block"
										v-model="shortcut.atl"
										class="inputItem"
										maxlength="1"
										type="text"
										@click="filterOpen = true"
										@keydown="getKey($event, shortcut)"
									/>
									<div v-if="isArrow(shortcut.code) && !shortcut.block" class="inputItem-arrow">
										<i
											:class="'fas fa-arrow-alt-circle-' + getArrow(shortcut.code)"
											@click="inputSelect(shortcut.atl)"
										></i>
										<input
											:id="shortcut.atl"
											v-model="shortcut.atl"
											maxlength="1"
											type="text"
											@keydown="getKey($event, shortcut)"
										/>
									</div>
								</div>
							</b-row>
						</b-col>
					</div>

					<div v-if="page == 'Segmentos'" class="row m-0">
						<b-col class="m-0 p-0">
							<b-row v-for="shortcut in config" :key="shortcut.indice" class="m-0">
								<div v-if="shortcut.page == 2" class="config-item">
									<span class="description">{{ shortcut.title }}</span>
									<span v-if="shortcut.mainAtl == 'ctrl'" class="trigger">
										<i class="tecla-trigger"> CTRL </i>
										<b> + </b>
									</span>
									<span v-if="shortcut.mainAtl == 'alt'" class="trigger">
										<i class="tecla-trigger">ALT</i>
										<b> + </b>
									</span>
									<input
										v-if="shortcut.block == true"
										v-model="shortcut.atl"
										class="inputItem"
										maxlength="1"
										type="text"
										disabled
									/>
									<input
										v-else
										v-model="shortcut.atl"
										class="inputItem"
										maxlength="1"
										type="text"
										@click="filterOpen = true"
										@keydown="getKey($event, shortcut)"
									/>
								</div>
							</b-row>
						</b-col>
					</div>

					<div v-if="page == 'Texto'" class="row m-0">
						<b-col class="m-0 p-0">
							<b-row v-for="shortcut in config" :key="shortcut.indice" class="m-0">
								<div v-if="shortcut.page == 4" class="config-item">
									<span class="description">{{ shortcut.title }}</span>
									<span v-if="shortcut.secondAtl == 'shift'" class="trigger">
										<i class="tecla-trigger"> SHIFT </i>
										<b> + </b>
									</span>
									<span v-if="shortcut.mainAtl == 'ctrl'" class="trigger">
										<i class="tecla-trigger"> CTRL </i>
										<b> + </b>
									</span>
									<span v-if="shortcut.mainAtl == 'alt'" class="trigger">
										<i class="tecla-trigger">ALT</i>
										<b> + </b>
									</span>
									<input
										v-if="shortcut.block == true"
										v-model="shortcut.atl"
										class="inputItem"
										maxlength="1"
										type="text"
										disabled
									/>
									<input
										v-else
										v-model="shortcut.atl"
										class="inputItem"
										maxlength="1"
										type="text"
										@click="filterOpen = true"
										@keydown="getKey($event, shortcut)"
									/>
								</div>
							</b-row>
						</b-col>
					</div>

					<div v-if="page == 'Outros'" class="row m-0">
						<b-col class="m-0 p-0">
							<b-row v-for="shortcut in config" :key="shortcut.indice" class="m-0">
								<div v-if="shortcut.page == 3" class="config-item">
									<span class="description">{{ shortcut.title }}</span>
									<span v-if="shortcut.mainAtl == 'ctrl'" class="trigger">
										<i class="tecla-trigger"> CTRL </i>
										<b> + </b>
									</span>
									<span v-if="shortcut.mainAtl == 'alt'" class="trigger">
										<i class="tecla-trigger">ALT</i>
										<b> + </b>
									</span>
									<input
										v-if="shortcut.block == true"
										v-model="shortcut.atl"
										class="inputItem"
										maxlength="1"
										type="text"
										disabled
									/>
									<input
										v-else
										v-model="shortcut.atl"
										class="inputItem"
										maxlength="1"
										type="text"
										@click="filterOpen = true"
										@keydown="getKey($event, shortcut)"
									/>
								</div>
							</b-row>
						</b-col>
					</div>
				</div>
			</b-col>
		</div>

		<div id="save-area" class="row">
			<button class="btn" @click="resetDefault">Configuração padrão</button>
			<button class="btn" @click="save">Salvar</button>
		</div>

		<div v-if="filterOpen" class="filterGetKey" @click="filterOpen = false">
			<div class="filterArea">
				<span>Digite uma tecla para configurar o atalho</span>
			</div>
		</div>
	</div>
</template>

<script>
import { H } from 'highlight.run';
import shortcuts from '@/configs/shortcuts';
import _ from 'lodash';
import textTools from '@/utils/textTools';

export default {
	data() {
		return {
			config: { ...shortcuts },
			codesInUse: [],
			codesBlocked: [18],
			filterOpen: false,
			error: '',
			page: 'Navegação',
			shortcutesListener: null,
		};
	},
	computed: {
		userPreferences() {
			return this.$store.getters['firebase/getUserPreference'];
		},
		getInspectMode() {
			return this.$store.getters['firebase/getInspectMode'];
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getShortcuteEvent: {
			get() {
				return this.$store.getters['controller/getShortcuteEvent'];
			},
			deep: true,
		},
	},
	watch: {
		getShortcuteEvent(event) {
			if (event.eventKey == 'generateTaskTxt') {
				this.generateTaskTxt();
			}
		},
	},
	mounted() {
		this.removeShortcutesListeners();

		let configSaved = this.userPreferences !== null ? this.userPreferences.shortcuts.config : shortcuts;

		// KeyboardEvent.keyCode is deprecated, so we need to use KeyboardEvent.code instead.
		// But, we need to convert the old keyCode to the new code.
		Object.keys(configSaved).forEach((key) => {
			// if 'code' atribute is a number, it's a keyCode
			if (typeof configSaved[key].code == 'number') {
				// convert keyCode to code
				configSaved[key].code = textTools.convertKeyCodeToCode(configSaved[key].code);
			}
		});

		const configArray = Object.entries(configSaved);
		configArray.sort((frist, second) => frist[1].indice - second[1].indice);
		const sortedConfig = Object.fromEntries(configArray);

		this.config = sortedConfig;
		this.startShortcutesListeners();

		// PREVENT  KEYS
		document.addEventListener('keydown', function (event) {
			if (event.altKey) {
				event.preventDefault();
			}

			if (event.ctrlKey && event.code === 'KeyG') {
				event.preventDefault();
			}

			if (event.ctrlKey && event.code === 'KeyP') {
				event.preventDefault();
			}

			if (event.ctrlKey && event.code === 'KeyB') {
				event.preventDefault();
			}

			if (event.ctrlKey && event.code === 'KeyF') {
				event.preventDefault();
			}

			if (event.ctrlKey && event.code === 'KeyI') {
				event.preventDefault();
			}

			if (event.ctrlKey && event.code === 'Delete') {
				event.preventDefault();
			}

			if (event.altlKey && event.code === 'Delete') {
				event.preventDefault();
			}
		});
	},
	methods: {
		getKey(event, shortcut) {
			let key = event.key;
			let code = event.code;
			let input = event.srcElement;
			this.filterOpen = false;

			if (code == shortcut.code) return; // If new code not change, return
			if (this.codesBlocked.includes(code)) return; // If keyCode is blocked return

			if (this.keyCodeIsValid(code) == false) {
				this.error = '';

				// Remove de code used before on the codeInUse
				let indexToChange = this.codesInUse.indexOf(shortcut.code);
				this.codesInUse.splice(indexToChange, 1, code);

				Object.keys(this.config).forEach((stc) => {
					if (this.config[stc].indice == shortcut.indice) {
						this.config[stc].atl = key.toUpperCase();
						this.config[stc].code = code;
					}
				});

				this.isBlock = false;
			} else {
				this.error = 'Já existe um atalho definido com está tecla!';
				this.isBlock = true;
			}

			input.blur();
		},
		keyCodeIsValid(code) {
			this.codesInUse = [];
			Object.keys(this.config).forEach((shortcut) => {
				this.codesInUse.push(this.config[shortcut].code);
			});
			return this.codesInUse.includes(code);
		},
		isArrow(code) {
			return code == 'ArrowLeft' || code == 'ArrowUp' || code == 'ArrowRight' || code == 'ArrowDown';
		},
		getArrow(code) {
			if (code == 'ArrowLeft') return 'left';
			if (code == 'ArrowUp') return 'up';
			if (code == 'ArrowRight') return 'right';
			if (code == 'ArrowDown') return 'down';
		},
		inputSelect(atl) {
			this.filterOpen = true;
			let input = document.getElementById(atl);
			input.focus();
		},
		save() {
			let userPreferences = {
				...this.userPreferences,
				shortcuts: {
					config: this.config,
					version: this.userPreferences.shortcuts.version,
				},
			};
			let response = this.$store.dispatch('firebase/setUserPreferences', userPreferences);
			if (response == false) {
				alert('Erro ao salvar preferências!');
			} else {
				alert('Dados salvos com sucesso.');
			}
		},
		resetDefault() {
			this.config = { ...shortcuts };
			localStorage.setItem('config', JSON.stringify(shortcuts));
			alert('Configuração padrão redefinida com sucesso!');
		},
		startShortcutesListeners() {
			const element = window;
			this.shortcutesListener = _.debounce(
				/**
				 * @param {KeyboardEvent} e
				 */
				(e) => {
					let code = e.code;
					// prevent Shift default
					if (code == 'ShiftLeft' || code == 'ShiftRight') {
						e.preventDefault();
					}

					// prevent ALT default
					if (code == 'AltLeft' || code == 'AltRight') {
						e.preventDefault();
					}

					// prevent CTRL default
					if (code == 'ControlLeft' || code == 'ControlRight') {
						e.preventDefault();
					}

					// Navigate Up
					if (code == this.config.navigateUp.code && e.altKey) {
						e.preventDefault();
						this.$store.dispatch('controller/navigateUp');
					}

					// Navigate Down
					if (code == this.config.navigateDown.code && e.altKey) {
						e.preventDefault();
						this.$store.dispatch('controller/navigateDown');
					}

					// Navigate Between errors Up
					if (code == this.config.navigateBetweenErrosUp.code && e.ctrlKey) {
						e.preventDefault();
						this.$store.dispatch('controller/navigateBetweenErrors', 'UP');
					}

					//  Navigate Between errors Down
					if (code == this.config.navigateDown.code && e.ctrlKey) {
						e.preventDefault();
						this.$store.dispatch('controller/navigateBetweenErrors', 'DOWN');
					}

					// Add segment
					if (code == this.config.addSegment.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/addSegment', {
							text: '',
							translate_text: '',
						});
					}

					// Remove segment
					if (code == this.config.removeSegment.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						let id = this.$store.getters['controller/getCurrentIndex'];
						this.$store.dispatch('setRemoveSegmentModalActive', {
							status: true,
							payload: id,
						});
					}

					// Join segments
					if (code == this.config.joinSegments.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/joinSegments');
						this.$store.dispatch('controller/navigateUp');
					}

					// Play/Pause Video and Soundwave
					if (code == this.config.playPause.code && e.altKey) {
						e.preventDefault();
						this.$store.dispatch('controller/playPause', null);
					}

					// Replay Segment
					if (code == this.config.replaySegment.code && e.altKey) {
						e.preventDefault();
						this.$store.dispatch('controller/replaySegment');
					}

					// Foward Segment
					if (code == this.config.fowerdTime.code && e.altKey) {
						e.preventDefault();
						this.$store.dispatch('controller/fowerRewindTime', true);
					}

					// Rewind Segment
					if (code == this.config.rewindTime.code && e.altKey) {
						e.preventDefault();
						this.$store.dispatch('controller/fowerRewindTime', false);
					}

					// Set start time segment
					if (code == this.config.setStartTime.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/updateSegmentTime', 'start');
					}

					// Set end time segment
					if (code == this.config.setEndTime.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/updateSegmentTime', 'end');
					}

					// Set synchrony mode
					if (code == this.config.setSynchronyMode.code && e.altKey) {
						e.preventDefault();
						this.$store.dispatch('controller/setSynchronyMode');
					}

					// Confirm segment
					if (code == this.config.confirmSegment.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						let userPreferences = this.userPreferences;
						this.$store.dispatch('controller/saveSegment');
						if (userPreferences && userPreferences.skipToNextSegmentAfterSave) {
							this.$store.dispatch('controller/navigateDown');
						}
					}

					// Split Segment by cursor position (ALT+ENTER)
					if (code == this.config.splitSegment.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'splitSegment');
					}

					// Split Segment by cursor position and add in next segment (CTRL+ENTER)
					if (code == this.config.splitAndAddInNextSegment.code && e.ctrlKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'splitAndAddInNextSegment');
					}

					// Split Segment by cursor position and add in next segment with time correction(CTRL+SHIFT+ENTER)
					if (code == this.config.splitAndAddInNextSegmentWithTime.code && e.ctrlKey && e.shiftKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'splitAndAddInNextSegmentWithTime');
					}

					// cut first word in next segment and paste in the current segment
					if (code == this.config.cutFirstWorld.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'cutFirstWorld');
					}

					// cut first word in next segment and paste in the current segment with time correction
					if (code == this.config.cutFirstWorldWithTime.code && e.ctrlKey && e.shiftKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'cutFirstWorldWithTime');
					}

					// add invert exclamation in the cursor position
					if (code == this.config.invertExclamation.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'invertExclamation');
					}

					// add invert interrogation in the cursor position
					if (code == this.config.invertInterrogation.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'invertInterrogation');
					}

					// add music symbol in the cursor position
					if (code == this.config.addMusicSymbol.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'addMusicSymbol');
					}

					// add audio description
					if (code == this.config.addSoundDescription.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'addSoundDescription');
					}

					// Find And Replace
					if (code == this.config.openFindAndReplace.code && e.ctrlKey) {
						e.preventDefault();
						this.$store.dispatch('controller/sendShortcuteEvent', 'openFindAndReplace');
					}

					// Set bold
					if (code == this.config.addBold.code && e.ctrlKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'addBold');
					}

					// Set italic
					if (code == this.config.addItalic.code && e.ctrlKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'addItalic');
					}

					// Add segment bookmark
					if (code == this.config.addBookmark.code && e.altKey) {
						e.preventDefault();
						if (this.getInspectMode) return;
						this.$store.dispatch('controller/sendShortcuteEvent', 'addBookmark');
					}

					// Atalho para gerar texto corrido
					if (code == 'KeyQ' && e.ctrlKey) {
						e.preventDefault();
						this.$store.dispatch('controller/sendShortcuteEvent', 'generateTaskTxt');
					}

					// Undo
					if (code == 'KeyZ' && e.ctrlKey) {
						e.preventDefault();
						this.$store.dispatch('undoRedo/getCtrlZ');
					}

					// Redo
					if (code == 'KeyY' && e.ctrlKey) {
						e.preventDefault();
						this.$store.dispatch('undoRedo/getCtrlY');
					}

					// if ctrl or alt key is pressed with other key
					if ((e.ctrlKey || e.altKey) && e.key != 'Control' && e.key != 'Alt') {
						// track shortcut pressed
						H.track('shortcutPressed', {
							shortcut: `${e.ctrlKey ? 'Ctrl+' : ''}${e.altKey ? 'Alt+' : ''}${e.shiftKey ? 'Shift+' : ''}${e.key}`,
						});
					}
				},
				100
			);

			element.addEventListener('keydown', this.shortcutesListener);
		},
		removeShortcutesListeners() {
			const element = window;
			element.removeEventListener('keydown', this.shortcutesListener);
			this.shortcutesListener = null;
		},
		generateTaskTxt() {
			let segments = this.$store.getters['firebase/getAllSubtitles'];
			let text = '';
			segments.forEach((segment) => {
				if (this.getIsTranslate) {
					text += ' ' + segment.translate_text.replace('\n', ' ');
				} else {
					text += ' ' + segment.text.replace('\n', ' ');
				}
			});

			// change tag <br> to \n
			let textSegment = text;
			textSegment = textSegment.replace('<i>', '');
			textSegment = textSegment.replace('</i>', '');
			textSegment = textSegment.replace('<b>', '');
			textSegment = textSegment.replace('</b>', '');

			// remove textSegment doble	spaces
			textSegment = textSegment.replace(/\s\s+/g, ' ');

			// copy text to clipboard
			navigator.clipboard.writeText(textSegment);
			alert('Texto corrido copiado para área de transferência!');
		},
	},
};
</script>

<style lang="scss">
@import '../../app.scss';

#config-modal {
	color: #fff;

	.shortcutes-button-area {
		button {
			font-size: 1rem;
			background-color: $color-auxiliary;
			color: #ffffff;
			border-radius: 10px;
			padding: 0.5rem;
			min-width: 100px;
			margin-left: 1rem;
		}
	}

	.shortcutes-area {
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 400px;
	}

	.config-item {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		min-height: 50px;
		padding: 1rem;
		margin-bottom: 0px;
		border-bottom: 1px solid rgb(164, 163, 163);

		.description {
			text-align: left;
			width: 65%;
		}

		.inputItem {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgb(53, 52, 52);
			border: 1px solid rgb(164, 163, 163);
			border-radius: 5px;
			box-shadow: rgb(35, 35, 35) 0px 0px 0px 1px;
			height: auto;
			color: #fff;
			padding: 2px;
			width: 15%;
			margin-left: 10px;
			font-family: 'Times New Roman', Times, serif;
			font-style: initial;
			text-align: center;

			&:hover {
				cursor: pointer;
			}
		}

		.inputItem-arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgb(53, 52, 52);
			border: 1px solid rgb(164, 163, 163);
			border-radius: 5px;
			box-shadow: rgb(35, 35, 35) 0px 0px 0px 1px;
			height: auto;
			color: #fff;
			padding: 2px;
			width: 15%;
			margin-left: 10px;
			font-family: 'Times New Roman', Times, serif;
			font-style: initial;

			i {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 24px;
				z-index: 5;
				text-align: center;

				&:hover {
					cursor: pointer;
				}
			}

			input {
				position: absolute;
				width: 100%;
				height: 100%;
				background: none;
				border: none;
				color: #fff;
				text-align: center;
				z-index: -1;

				&:hover {
					cursor: pointer;
				}
			}
		}

		.trigger {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 20%;
		}

		.tecla-trigger {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgb(53, 52, 52);
			border: 1px solid rgb(164, 163, 163);
			border-radius: 5px;
			box-shadow: rgb(35, 35, 35) 0px 0px 0px 1px;
			height: auto;
			color: #fff;
			padding: 2px;
			width: 100%;
			margin-right: 10px;
			margin-left: 10px;
			font-family: 'Times New Roman', Times, serif;
			font-style: initial;
		}
	}

	.error {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		height: 24px;
		width: 100%;
		color: red;
		font-weight: bold;
		text-align: center;
		background-color: rgba(243, 104, 104, 0.164);
		margin-top: 3%;
	}
}

.filterGetKey {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.527);
	z-index: 10;

	.filterArea {
		width: 30%;
		height: 30%;
		background-color: #000000;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffff;
		font-size: 18px;
		border: 1px solid black;
	}
}
</style>
