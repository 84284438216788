<template>
	<div class="segment-controller">
		<div class="title-start">
			<span>Início</span>
		</div>

		<div class="describe-time"></div>

		<div id="time-init" class="timeChangeStart">
			<InputTimeEditor :value="startTime" @changeTime="changeStartTime" />
		</div>

		<div class="title-end">
			<span>Fim</span>
		</div>
		<div id="time-end" class="timeChangeEnd">
			<InputTimeEditor :value="endTime" @changeTime="changeEndTime" />
		</div>
	</div>
</template>

<script>
import InputTimeEditor from './InputTimeEditor';

export default {
	components: {
		InputTimeEditor,
	},
	props: {
		startTime: {
			type: Number,
			default: 0,
		},
		endTime: {
			type: Number,
			default: 0,
		},
	},

	methods: {
		changeStartTime(newValue) {
			this.$emit('changeTime', { startTime: newValue, endTime: this.endTime });
		},
		changeEndTime(newValue) {
			this.$emit('changeTime', {
				startTime: this.startTime,
				endTime: newValue,
			});
		},
	},
};
</script>

<style lang="scss">
@import '../../app.scss';

.segment-controller {
	display: grid;
	height: 100%;
	width: 100%;
	max-height: 100%;
	max-width: 100%;
	grid-template:
		'. .'
		'title-start  timeChangeStart'
		'title-end  timeChangeEnd';
	grid-template-rows: 0.5fr 1fr 1fr;
	grid-template-columns: 1fr 2fr;

	.title-start {
		grid-area: title-start;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: bold;
		padding-right: 10px;

		span {
			@include glass;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 50%;
			background-color: rgb(131, 130, 130);
			border-radius: 5px;
		}
	}

	.title-end {
		grid-area: title-end;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-weight: bold;
		padding-right: 10px;

		span {
			@include glass;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 50%;
			justify-content: center;
			background-color: rgb(37, 37, 37);
			border-radius: 5px;
		}
	}

	.timeChangeStart {
		grid-area: timeChangeStart;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.timeChangeEnd {
		grid-area: timeChangeEnd;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
</style>
