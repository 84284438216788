import Worker from 'worker-loader!./firebaseManager';
import * as Comlink from 'comlink';

const worker = Comlink.wrap(new Worker());

export const setBackup = async (result) => {
	try {
		const response = await worker.setBackup(result);
		return response;
	} catch (error) {
		throw new Error(`Failed to set backup: ${error}`);
	}
};

export const getAccess = worker.getAccess;
export const getInspect = worker.getInspect;
export const getUserPreferences = worker.getUserPreferences;
export const setUserPreferences = worker.setUserPreferences;
export const updateAccess = worker.updateAccess;
export const getSettings = worker.getSettings;
export const getTask = worker.getTask;
export const updateTask = worker.updateTask;
export const updateSavedTime = worker.updateSavedTime;
export const updateLastSegmentSelect = worker.updateLastSegmentSelect;
export const updateContabilizeTime = worker.updateContabilizeTime;
export const getSubtitles = worker.getSubtitles;
export const updateSegment = worker.updateSegment;
export const updateBatchSegmentList = worker.updateBatchSegmentList;
export const addSegment = worker.addSegment;
export const removeSegment = worker.removeSegment;
export const addFeedback = worker.addFeedback;
export const updateConfirmSegment = worker.updateConfirmSegments;
export const updateFeedbackSegment = worker.updateFeedbackSegment;
export const getSubtitlesQA = worker.getSubtitlesQA;
export const updateSegmentSpendTime = worker.updateSegmentSpendTime;
export const getAudioDescription = worker.getAudioDescription;
export const saveAutomaticBackup = worker.saveAutomaticBackup;
export const getAutomaticBackup = worker.getAutomaticBackup;
export const getAllOnlineBackups = worker.getAllOnlineBackups;
