<template>
	<div class="kpi-container"></div>
</template>

<script>
export default {
	data() {
		return {
			segments: [],
			actualSegment: null,
			timeSpend: 0,
			interval: null,
		};
	},
	computed: {
		getCurrentSegment() {
			return this.$store.getters['controller/getCurrentSegment'];
		},
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getIsReviewQA() {
			return this.$store.getters['firebase/getIsReviewQA'];
		},
	},
	watch: {
		getCurrentSegment() {
			if (this.getIsReviewQA) return;

			// save before change segment
			if (this.actualSegment) {
				this.$store.dispatch('firebase/updateSegmentSpendTime', {
					segment: this.actualSegment,
					spendTime: this.timeSpend,
				});
			}

			// clear varibales;
			clearInterval(this.interval);
			this.timeSpend = 0;
			this.actualSegment = this.getCurrentSegment;

			// set new timer
			this.interval = setInterval(() => {
				this.timeSpend += 3000;
			}, 3000);
		},
	},
	mounted() {
		// Contabilize time in editor
		if (this.getIsReviewQA) return;
		this.contabilizeTime();
	},
	methods: {
		contabilizeTime() {
			setInterval(() => {
				let task = this.getTask;
				if (!task.contabilize_time) {
					task.contabilize_time = 5000;
				}
				task.contabilize_time += 5000;

				this.$store.dispatch('firebase/updateContabilizeTime', task.contabilize_time);
			}, 5000);
		},
	},
};
</script>

<style lang="scss" scoped></style>
