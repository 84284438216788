import { render, staticRenderFns } from "./JobFeedback.vue?vue&type=template&id=66286030&scoped=true"
import script from "./JobFeedback.vue?vue&type=script&lang=js"
export * from "./JobFeedback.vue?vue&type=script&lang=js"
import style0 from "./JobFeedback.vue?vue&type=style&index=0&id=66286030&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66286030",
  null
  
)

export default component.exports