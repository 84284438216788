<template>
	<div id="app">
		<FirebaseManager v-if="startFirebase" />
		<LoadingScreen v-if="getLoadingScreen" />
		<LayoutManager v-if="permission" />
		<router-view></router-view>
		<div v-if="getInspectMode" class="inspect-mode">
			<p>Você está no modo de visualização. Edições e alterações não serão salvas nesse job!</p>
		</div>
	</div>
</template>
<script>
import LoadingScreen from './components/LoadingScreen.vue';
import FirebaseManager from './components/FirebaseManager.vue';
import LayoutManager from '@/components/LayoutManager.vue';

export default {
	name: 'App',
	components: {
		LoadingScreen,
		FirebaseManager,
		LayoutManager,
	},
	data() {
		return {
			startFirebase: false,
			accessId: false,
			permission: false,
			inspectMode: false,
		};
	},
	computed: {
		getLoadingScreen() {
			return this.$store.getters['getLoadingScreen'];
		},
		getLoadingStages() {
			return this.$store.getters['getLoadingStages'];
		},
		getInspectMode() {
			return this.$store.getters['firebase/getInspectMode'];
		},
	},
	watch: {
		getLoadingStages(stage) {
			if (stage == 1) {
				this.permission = true;
			}
		},
	},
	async mounted() {
		const urlParams = new URLSearchParams(window.location.search);
		const accessToken = urlParams.get('id');
		this.accessId = await this.$store.dispatch('firebase/getAccess', accessToken);
		await this.$store.dispatch('firebase/getInspectMode', accessToken);
		this.start();
	},
	methods: {
		start() {
			if (this.accessId != true) {
				this.$store.dispatch('setLoadingScreen', false);
				this.$router.push('/unauthorized');
			} else {
				this.startFirebase = true;
			}
		},
	},
};
</script>

<style lang="scss">
@import './app.scss';

#app {
	display: flex;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: gray;
}

* {
	.form-control {
		font-size: 1rem !important;
	}

	.cloud-saved-information {
		.link {
			color: #196aed !important;
			text-decoration: underline !important;
		}
	}

	.error-notification {
		input,
		span,
		p {
			// @include fontConfig;
			font-family: 'roboto', sans-serif !important;
			font-size: 1.2rem !important;
		}
	}

	.cloud-saved-information,
	.navbar {
		input,
		span,
		p {
			// @include fontConfig;
			font-family: 'roboto', sans-serif !important;
			font-size: 1rem !important;
		}
	}

	input,
	span,
	p {
		// @include fontConfig;
		font-family: 'roboto', sans-serif !important;
		font-size: 0.8rem !important;
	}
}

.inspect-mode {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 35px;
	background-color: #d93c3c;
	color: #fff;
	text-align: center;
	padding-top: 10px;
	z-index: 9999;
}
</style>
