<template>
	<div v-show="segmentTools.show" id="segment-tools" @mouseover="segmentTools.show = true">
		<b-button
			v-b-tooltip.hover.topright="'Adicionar segmento acima'"
			class="btn-segment-tools-1"
			variant="success"
			@click="addSegment(1)"
			>+</b-button
		>
		<b-button
			v-b-tooltip.hover.topright="'Adicionar segmento abaixo'"
			class="btn-segment-tools-2"
			variant="success"
			@click="addSegment(2)"
			>+</b-button
		>
	</div>
</template>

<script>
export default {
	data() {
		return {
			segmentTools: {
				show: false,
				indexSelect: 1,
			},
		};
	},
	computed: {
		getShowOptions() {
			return this.$store.getters['controller/getShowOptions'];
		},
		getInspectMode() {
			return this.$store.getters['firebase/getInspectMode'];
		},
	},
	watch: {
		getShowOptions() {
			this.segmentTools = this.getShowOptions;
		},
		segmentTools() {
			let rowSelect = document.querySelector('#rowSegment-' + this.segmentTools.indexSelect);
			let rect = rowSelect.getBoundingClientRect();
			let rectObject = {
				top: rect.top,
				left: rect.left,
				width: rect.width,
				height: rect.height,
			};

			let glass = document.querySelector('.clusterize-scroll');
			let div = document.getElementById('segment-tools');
			let button1 = document.getElementsByClassName('btn-segment-tools-1')[0];

			glass.addEventListener('mouseleave', () => {
				this.segmentTools.show = false;
			});

			if (div == null) return;
			let marginTop = rectObject.top - 10;
			div.style.top = marginTop + 'px';

			let marginLeft = rectObject.left;
			div.style.left = marginLeft + 'px';

			let marginHeight = rectObject.height - button1.offsetHeight;
			button1.style.marginBottom = marginHeight + 'px';

			if (marginHeight > glass.clientHeight + rectObject.height - 30) {
				this.segmentTools.show = false;
			}
		},
	},
	methods: {
		addSegment(buttonClicked) {
			if (this.getInspectMode) return;
			if (buttonClicked == 1) {
				this.$store.dispatch('controller/addSegment', {
					text: '',
					translate_text: '',
					indice: this.segmentTools.indexSelect - 1,
				});
			} else {
				this.$store.dispatch('controller/addSegment', {
					text: '',
					translate_text: '',
					indice: this.segmentTools.indexSelect,
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
#segment-tools {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0px;
	width: auto;
	height: auto;
	z-index: 1;

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		margin-left: 5px;
		font-size: 15px;
		font-weight: bold;
	}
}
</style>
