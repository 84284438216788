<template>
	<div>
		<div id="manual-icon" class="mx-4 manual-button" @click="openManual()"><i class="far fa-book"></i> Manual</div>
		<b-tooltip target="manual-icon" triggers="hover"> Manual de Revisão </b-tooltip>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	computed: {},
	mounted() {},
	methods: {
		openManual() {
			// window.open('https://bit.ly/manual-legendagem-skylar');
			window.open(
				'https://skylar-ai.notion.site/Manual-de-Legendagem-Skylar-2024-d7f034ae48e74f819ce1a13de4c30338?pvs=4'
			);
		},
	},
};
</script>

<style lang="scss" scoped>
#manual-icon:hover {
	cursor: pointer;
}

.manual-button {
	cursor: pointer;
	// border: 1px solid #ffff;
	padding: 4px 10px;
	border-radius: 5px;
	color: black;
	background-color: #ffffffd2;
}
</style>
