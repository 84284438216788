<template>
	<div>
		<CpsBox :text="textToVerify" :duration="segmentDuration" />

		<button id="bold-button" @click="setBold">
			<i class="fas fa-bold"></i>
		</button>
		<b-tooltip
			target="bold-button"
			title="Adicionar negrito (CTRL+B)"
			triggers="hover"
			placement="top"
			:delay="{ show: 300, hide: 100 }"
		/>
		<button id="italic-button" @click="setItalic">
			<i class="fa fa-italic"></i>
		</button>
		<b-tooltip
			target="italic-button"
			title="Adicionar itálico  (CTRL+I)"
			triggers="hover"
			placement="top"
			:delay="{ show: 300, hide: 100 }"
		/>
	</div>
</template>

<script>
import CpsBox from './CpsBox.vue';

export default {
	components: {
		CpsBox,
	},
	props: {
		segment: {
			type: Object,
			required: true,
		},
		textFildToVerify: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			selectionStartText: 0,
			selectionEndText: 0,
			selectionStartTextTranslate: 0,
			selectionEndTextTranslate: 0,
		};
	},
	computed: {
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		textToVerify() {
			if (this.textFildToVerify == 'text') {
				return this.segment.text;
			} else {
				return this.segment.translate_text;
			}
		},
		segmentDuration() {
			return (this.segment.end - this.segment.start) / 1000;
		},
		getShortcuteEvent: {
			get() {
				return this.$store.getters['controller/getShortcuteEvent'];
			},
			deep: true,
		},
	},
	watch: {
		getShortcuteEvent(event) {
			if (event.eventKey == 'addBold') {
				this.setBold();
			}

			if (event.eventKey == 'addItalic') {
				this.setItalic();
			}
		},
	},
	methods: {
		getSelectionPosition() {
			if (this.getIsTranslate) {
				let translateTextField = document.getElementById('textTranslate');
				this.selectionStartTextTranslate = translateTextField.selectionStart;
				this.selectionEndTextTranslate = translateTextField.selectionEnd;
			} else {
				let textfield = document.getElementById('text');
				this.selectionStartText = textfield.selectionStart;
				this.selectionEndText = textfield.selectionEnd;
			}
		},
		setItalic() {
			this.getSelectionPosition();
			if (this.getIsTranslate) {
				let translateText = this.segment.translate_text;
				// add italic beetween selection
				translateText =
					translateText.substring(0, this.selectionStartTextTranslate) +
					'<i>' +
					translateText.substring(this.selectionStartTextTranslate, this.selectionEndTextTranslate) +
					'</i>' +
					translateText.substring(this.selectionEndTextTranslate, translateText.length);
				const newCursorPosition =
					translateText.substring(0, this.selectionStartText).length +
					translateText.substring(this.selectionStartText, this.selectionEndText).length +
					3;
				this.$emit('updateText', translateText, newCursorPosition);
			} else {
				let text = this.segment.text;
				// add italic beetween selection
				text =
					text.substring(0, this.selectionStartText) +
					'<i>' +
					text.substring(this.selectionStartText, this.selectionEndText) +
					'</i>' +
					text.substring(this.selectionEndText, text.length);

				const newCursorPosition =
					text.substring(0, this.selectionStartText).length +
					text.substring(this.selectionStartText, this.selectionEndText).length +
					3;
				this.$emit('updateText', text, newCursorPosition);
			}
		},
		setBold() {
			this.getSelectionPosition();
			if (this.getIsTranslate) {
				let translateText = this.segment.translate_text;
				// add italic beetween selection
				translateText =
					translateText.substring(0, this.selectionStartTextTranslate) +
					'<b>' +
					translateText.substring(this.selectionStartTextTranslate, this.selectionEndTextTranslate) +
					'</b>' +
					translateText.substring(this.selectionEndTextTranslate, translateText.length);
				const newCursorPosition =
					translateText.substring(0, this.selectionStartText).length +
					translateText.substring(this.selectionStartText, this.selectionEndText).length +
					3;
				this.$emit('updateText', translateText, newCursorPosition);
			} else {
				let text = this.segment.text;
				// add italic beetween selection
				text =
					text.substring(0, this.selectionStartText) +
					'<b>' +
					text.substring(this.selectionStartText, this.selectionEndText) +
					'</b>' +
					text.substring(this.selectionEndText, text.length);

				const newCursorPosition =
					text.substring(0, this.selectionStartText).length +
					text.substring(this.selectionStartText, this.selectionEndText).length +
					3;
				this.$emit('updateText', text, newCursorPosition);
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
