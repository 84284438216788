<template>
	<div>
		<b-spinner v-if="loading" class="center-spinner" label="Spinning"></b-spinner>
		<div id="waveform"></div>
		<div id="timeline"></div>
		<div v-if="onlySoundwave" id="controller">
			<button @click="play">
				<i class="fas fa-play"></i>
			</button>
			<button @click="pause">
				<i class="fas fa-pause"></i>
			</button>
			<button @click="skipBackward">
				<i class="fas fa-backward"></i>
			</button>
			<button @click="skipForward">
				<i class="fas fa-forward"></i>
			</button>
		</div>
	</div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';

export default {
	props: {
		onlySoundwave: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: true,
			wavesurfer: null,
			playing: false,
			currentTime: 0,
		};
	},
	computed: {
		// Create Soundwave parameters
		getMediaPeaksUrl() {
			return this.$store.getters['firebase/getMediaPeaksUrl'];
		},
		getMediaUrl() {
			return this.$store.getters['firebase/getMediaUrl'];
		},
		getSubtitles() {
			return this.$store.getters['firebase/getSubtitles'];
		},

		// Region CRUD
		getRegionToAdd() {
			return this.$store.getters['soundwave/getRegionToAdd'];
		},
		getRegionToDelete() {
			return this.$store.getters['soundwave/getRegionToDelete'];
		},
		getUpdateRegion() {
			return this.$store.getters['firebase/getUpdateRegion'];
		},
		getUpdateFlag() {
			return this.$store.getters['firebase/getUpdateFlag'];
		},

		// Controllers Events
		getPlaying() {
			return this.$store.getters['controller/getPlaying'];
		},
		getReplay() {
			return this.$store.getters['soundwave/getReplay'];
		},
		getRewindForward() {
			return this.$store.getters['soundwave/getRewindForward'];
		},
		getSeeked() {
			return this.$store.getters['soundwave/getSeeked'];
		},

		// Events parameters
		getDuration() {
			return this.$store.getters['video/getDuration'];
		},
		getUpdateTime() {
			return this.$store.getters['video/getUpdateTime'];
		},
		getCurrentTime() {
			return this.$store.getters['video/getCurrentTime'];
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getCurrentIndex() {
			return this.$store.getters['controller/getCurrentIndex'];
		},
		getVideoDuration() {
			return this.$store.getters['video/getDuration'];
		},
		getPlaybackRate() {
			return this.$store.getters['controller/getPlaybackRate'];
		},

		getZoomRate() {
			return this.zoom / this.minZoom;
		},
	},
	watch: {
		// Update events
		getCurrentIndex() {
			this.addRegions(this.lastRegion);
		},
		// Update region
		getUpdateFlag() {
			this.updateRegion(this.getUpdateRegion); // Quando recebe a flag faz o update da região em questão
		},
		// Delete region
		getRegionToDelete(newValue) {
			this.removeRegion(newValue);
		},
		// Add new region
		getRegionToAdd(newValue) {
			this.addOneRegion(newValue);
		},

		// Controllers events
		getPlaying() {
			if (this.getPlaying) {
				window.wavesurfer.play(this.getUpdateTime / 1000);
			} else {
				window.wavesurfer.pause();
			}
		},
		getReplay() {
			this.seekAndCenter(this.getReplay);
		},
		getRewindForward() {
			this.seekAndCenter(this.getRewindForward);
		},
		getSeeked() {
			this.seekAndCenter(this.getSeeked);
		},
		timeScroll() {
			this.seekAndCenter(this.timeScroll);
		},
		getPlaybackRate(rateValue) {
			this.setPlaybackRate(rateValue);
		},
		zoom(value) {
			this.zoomSoundwave(value);
		},
	},
	mounted() {
		setTimeout(() => {
			this.createWaveSurfer();
		}, 1000);
	},
	methods: {
		async createWaveSurfer() {
			/**
			 * @type {WaveSurfer}
			 */
			window.wavesurfer = WaveSurfer.create({
				container: document.querySelector('.soundwave'),
				waveColor: '#686868',
				progressColor: 'rgb(148, 70, 233)',
				responsive: true,
				scrollParent: true,
				barHeight: 0.7,
				cursorColor: '#f500008f',
				cursorWidth: 3,
				plugins: [
					TimelinePlugin.create({
						container: '#timeline',
						fontSize: 12,
						notchPercentHeight: 50,
						primaryFontColor: '#fff',
						secondaryFontColor: '#fff',
						formatTimeCallback: this.secsToHMS,
					}),
				],
			});

			if (this.getMediaPeaksUrl != '') {
				await fetch(this.getMediaPeaksUrl)
					.then((response) => {
						if (!response.ok) {
							throw new Error('HTTP error ' + response.status);
						}
						return response.json();
					})
					.then(async (peaks) => {
						// Soundwave needs a reference to some url
						window.wavesurfer.load(process.env.VUE_APP_DUMMY_VIDEO_URL, peaks.data, false, this.getDuration);
					})
					.then(() => {
						window.wavesurfer.zoom(100);
						window.wavesurfer.setMute(true);

						// get div soundwave element
						const waveform = document.querySelector('.soundwave');
						// get height of div soundwave element
						const waveformHeight = waveform.offsetHeight;
						window.wavesurfer.setHeight(waveformHeight);
					})
					.catch((e) => {
						console.error('error', e);
					});
			}

			window.wavesurfer.drawer.on('click', () => {
				setTimeout(() => {
					const currentTime = window.wavesurfer.getCurrentTime();
					this.$store.commit('video/setVideoTimeChange', currentTime * 1000);
				}, 50);
			});

			window.wavesurfer.on('error', function (e) {
				console.warn(e);
			});

			// init wavesurfer in current time and pause
			window.wavesurfer.play({
				start: this.getCurrentTime,
				end: this.getCurrentTime,
			});

			this.$nextTick(() => {
				this.loading = false;
				this.$store.dispatch('setLoadingScreen', false);
				this.$store.dispatch('setLoadingStage', 5);
			});
		},
		secsToHMS(value) {
			let hours = ~~(value / 3600);
			let minutes = ~~(value / 60);
			let seconds = value % 60;

			const hh = hours < 10 ? '0' + hours : hours;
			const mm = minutes < 10 ? '0' + minutes : minutes;
			const ss = seconds < 10 ? '0' + seconds : seconds;

			return `${hh}:${mm}:${ss}`;
		},
		seekAndCenter(time) {
			const duration = this.getDuration;
			time = time / (duration * 1000);
			if (time < 0 || time > 1) time = 0;
			window.wavesurfer.seekAndCenter(time);
		},
		setPlaybackRate(rate) {
			window.wavesurfer.setPlaybackRate(rate);
		},
		zoomSoundwave(value) {
			window.wavesurfer.zoom(value);
		},
		setZoom(value) {
			if (this.zoom + value > this.maxZoom || this.zoom + value < this.minZoom) return;
			this.zoom += value;
		},
	},
};
</script>

<style lang="scss">
@mixin glass {
	background: rgba(12, 12, 12, 0.411);
	border-radius: 5px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(7.6px);
	-webkit-backdrop-filter: blur(7.6px);
	border: 1px solid rgba(0, 0, 0, 0.97);
}

// centralize spinner in middle of thee div
.center-spinner {
	position: absolute;
	left: 50%;
}

.srtText {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px;
	background-color: rgba(255, 255, 255, 0.6);
	font-weight: bold;
	margin: 5px 20px 5px 20px;
	width: auto;
	height: auto;
	min-height: 25%;
	max-height: 25%;
	border-radius: 5px;
	box-sizing: border-box;

	span {
		font-size: 11px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #000;
	}
}

.wavesurfer-region {
	border: 1px solid rgba($color: #fff, $alpha: 0.1);
	border-radius: 8px;
}

.wavesurfer-handle-start,
.wavesurfer-handle-end {
	width: 10px !important;
	margin: 0 !important;
	display: flex;
	justify-content: center;
	align-items: center;

	&::after {
		content: '||';
		font-size: 0.6rem;
		font-weight: lighter !important;
	}
}

.wavesurfer-handle-start {
	background-color: rgba(255, 255, 255, 0.6) !important;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;

	&::after {
		color: #0009;
	}
}

.wavesurfer-handle-end {
	background-color: rgba(0, 0, 0, 0.6) !important;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;

	&::after {
		color: #fff4;
	}
}

#controller {
	@include glass;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 10%;
	height: 50px;
	padding: 3px;
	margin: 3px;
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		height: 25px;
		border-radius: 5px;
		background-color: #424242 !important;
		color: #fff;
		i {
			font-size: 0.8rem;
		}
	}
}
</style>
