<template>
	<span v-b-tooltip.hover="connectionDetails" :class="['connection-icon', quality]">
		<i class="fa fa-wifi"></i>
		<span> {{ quality !== 'unknown' ? `${qualityText}` : '' }} </span>
	</span>
</template>

<script>
export default {
	name: 'NavigatorConnectionIcon',
	connectionExist: false,

	data() {
		return {
			effectiveType: 'unknown',
			connectionDetails: null,
		};
	},

	computed: {
		quality() {
			switch (this.effectiveType) {
				case 'slow-2g':
					return 'very-slow';
				case '2g':
					return 'slow';
				case '3g':
					return 'medium';
				case '4g':
					return 'fast';
				default:
					return this.effectiveType;
			}
		},
		qualityText() {
			switch (this.effectiveType) {
				case 'slow-2g':
					return 'Muito lenta';
				case '2g':
					return 'Lenta';
				case '3g':
					return 'Médio';
				case '4g':
					return 'Rápido';
				default:
					return this.effectiveType;
			}
		},
	},

	created() {
		this.connectionDetails = this.getConnectionDetails();
		if (navigator.connection) {
			this.connectionExist = true;
			this.effectiveType = navigator.connection.effectiveType;
			navigator.connection.addEventListener('change', (ev) => {
				this.effectiveType = ev.target.effectiveType;
				this.connectionDetails = this.getConnectionDetails(ev.target);
			});
		} else {
			this.connectionExist = false;
		}
	},

	methods: {
		getConnectionDetails(connection = null) {
			if (!navigator.connection) {
				return 'Não foi possível obter detalhes da conexão';
			}

			if (!connection) {
				connection = navigator.connection;
			}
			return (
				`Conexão efetiva: ${connection.effectiveType}\n` +
				`Velocidade de conexão: ${connection.downlink} Mbps\n` +
				`Latência: ${connection.rtt} ms`
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.connection-icon {
	&.unknown {
		// grey
		color: var(--gray);
	}

	&.very-slow {
		// red
		color: var(--red);
	}

	&.slow {
		// orange
		color: var(--orange);
	}

	&.medium {
		// gold
		color: var(--yellow);
	}

	&.fast {
		// green
		color: var(--green);
	}
}
</style>
