<template>
	<b-navbar class="navbar-editor">
		<div class="nav-bar-left">
			<MenuButton />
			<VideoName />
			<ProjectLanguage />
			<SubtitleMode />
		</div>

		<div class="nav-bar-right">
			<!-- <ChangePlataform /> -->
			<ManualButton />
			<navigator-connection-icon class="mr-4" />
			<LastUpdateTime />
			<TextModeChange v-if="isTxtMode" />
			<SegmentsResume v-if="!isTxtMode" />
			<FinalizeTask />
		</div>
	</b-navbar>
</template>

<script>
import VideoName from './VideoName.vue';
import SubtitleMode from './SubtitleMode.vue';
//import LastUpdateTime from './LastUpdateTime';
import SegmentsResume from './SegmentsResume';
import FinalizeTask from './FinalizeTask';
import TextModeChange from './TextModeChange.vue';
import ProjectLanguage from './ProjectLanguage.vue';
import MenuButton from './MenuButton.vue';
import NavigatorConnectionIcon from '@/components/Navbar/NavigatorConnectionIcon.vue';
import LastUpdateTime from '@/components/Navbar/LastUpdateTime.vue';
import ManualButton from './ManualButton.vue';

export default {
	components: {
		SegmentsResume,
		FinalizeTask,
		VideoName,
		SubtitleMode,
		TextModeChange,
		ProjectLanguage,
		MenuButton,
		LastUpdateTime,
		NavigatorConnectionIcon,
		ManualButton,
	},
	data() {
		return {
			showTutorial: false,
			showBookmarks: false,
		};
	},
	computed: {
		isTxtMode() {
			return this.$store.getters['firebase/getTask'].type == 'txt' ? true : false;
		},
	},
	methods: {
		back() {
			// back on history
			if (process.env.NODE_ENV === 'development') {
				// development environment
				window.location.href = process.env.VUE_APP_REVIEW_URL || 'https://review-dev-eae37.web.app/';
			} else {
				// production environment
				window.location.href = 'https://review.skylar.ai/';
			}
		},
	},
};
</script>

<style lang="scss">
@import '../../app.scss';

.navbar-editor {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 0px !important;
	width: 100vw;
	height: 10vh;

	.nav-bar-left {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		width: 50%;
		height: 100%;
	}

	.nav-bar-right {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-direction: row;
		width: 50%;
		height: 100%;
		padding: 5px;
	}

	.nav-bar-left {
		justify-content: flex-start;
	}

	// button {
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	width: 35px;
	// 	height: 35px;
	// 	margin-right: 0.5rem;
	// 	margin-left: 0.5rem;
	// 	border-radius: 5px;
	// 	background-color: #424242 !important;
	// 	color: #fff;
	// 	&:hover {
	// 		cursor: pointer;
	// 		background-color: $color-auxiliary !important;
	// 	}
	// 	&::before {
	// 		margin-right: 5%;
	// 		display: flex;
	// 		align-items: center;
	// 		justify-content: center;
	// 	}
	// }
}

.logo-text {
	@include fontConfig;
	font-size: 1.5rem;
	color: $color-auxiliary;
	margin-right: 10px;
}

.finalize-button {
	@include fontConfig;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 30px;
	width: auto;
	height: 80%;
	min-width: 100px;
	border-radius: 5px;
	padding: 5px;
	color: #fff !important;

	i {
		height: auto;
		margin-left: 10px;
	}

	&:hover {
		cursor: pointer;
		background-color: $color-auxiliary !important;
	}
}
</style>
