<template>
	<div v-if="getIsDivided" class="divided-job-interval">
		<div class="interval-title">
			<i id="info-job-interval" class="fa fa-question-circle" aria-hidden="true"></i>
			<b-tooltip class="tooltip-job-interval" :target="'info-job-interval'" triggers="hover" placement="bottom">
				<div>
					O intervalo de trabalho é o intervalo que você deve realizar a revisão. O Início e Fim são os tempos do vídeo
					que você deve se limitar a revisar. Qualquer revisão fora desse intervalo não será remunerada.
				</div>
			</b-tooltip>

			<span>Intervalo de trabalho:</span>
		</div>

		<div id="interval-error-start" class="start-interval">
			<span class="span-time-start">
				<span v-if="startError" class="fa fa-exclamation-circle error-job-interval" aria-hidden="true"></span>
				Início:
			</span>
			<span class="span-time">{{ converMiliToSrt(getTask.start_time) }}</span>
		</div>

		<div id="interval-error-end" class="end-interval">
			<span class="span-time-end">
				<span v-if="endError" class="fa fa-exclamation-circle error-job-interval" aria-hidden="true"></span>
				Fim:
			</span>
			<span class="span-time">{{ converMiliToSrt(getTask.end_time) }}</span>
		</div>

		<b-tooltip
			:show="startError"
			variant="danger"
			:target="'interval-error-start'"
			triggers="manual"
			placement="top"
			noninteractive
		>
			<div>
				<span class="fa fa-exclamation-circle" aria-hidden="true"></span>
				Você está trabalhando fora do intervalo previsto!<br />
				Tempo atual abaixo do tempo inicial.
			</div>
		</b-tooltip>

		<b-tooltip
			:show="endError"
			variant="danger"
			:target="'interval-error-end'"
			triggers="manual"
			placement="top"
			noninteractive
		>
			<div>
				<span class="fa fa-exclamation-circle" aria-hidden="true"></span>
				Você está trabalhando fora do intervalo previsto!<br />
				Tempo atual acima do tempo final.
			</div>
		</b-tooltip>
	</div>
</template>

<script>
import { mili2srt } from '../../utils/convertTime';

export default {
	data() {
		return {
			startError: false,
			endError: false,
		};
	},
	computed: {
		getIsDivided() {
			return this.$store.getters['firebase/getIsDivided'];
		},
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getUpdateTime() {
			return this.$store.getters['video/getUpdateTime'];
		},
		getVideoTimeChange() {
			return this.$store.getters['video/getCurrentTime'];
		},
	},
	watch: {
		getUpdateTime(time) {
			this.checkIfTimeIsValid(time);
		},
		getVideoTimeChange(time) {
			this.checkIfTimeIsValid(time);
		},
	},
	methods: {
		converMiliToSrt(mili) {
			return mili2srt(mili);
		},
		async checkIfTimeIsValid(time) {
			if (time < this.getTask.start_time) {
				this.startError = true;
				this.endError = false;
				return;
			} else if (time > this.getTask.end_time) {
				this.endError = true;
				this.startError = false;
				return;
			}

			this.startError = false;
			this.endError = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin time-interval {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	margin: 0px 5px 0px 5px;
	text-align: center;
	color: #fff;

	.span-time {
		padding: 0px 10px 0px 10px;
		border-radius: 0px 5px 5px 0px;
		background-color: rgb(109, 32, 109);
	}
}

.divided-job-interval {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	height: 10%;
	//border: #fff solid 1px;
	padding: 10px;
	font-size: 0.8rem;

	.interval-title {
		font-weight: bold;
		margin-right: 10px;
		color: #fff;
		i {
			margin-right: 10px;
			&:hover {
				cursor: pointer;
				transform: scale(1.3);
			}
		}
	}

	.start-interval {
		@include time-interval;
		.span-time-start {
			padding: 0px 5px 0px 5px;
			border-radius: 5px 0px 0px 5px;
			background-color: #838282;
		}
	}

	.end-interval {
		@include time-interval;
		.span-time-end {
			padding: 0px 5px 0px 5px;
			border-radius: 5px 0px 0px 5px;
			background-color: #252525;
		}
	}

	.error-job-interval {
		color: red;
	}
}
</style>
