<template>
	<div id="preferences-modal" class="row p-0 m-0">
		<div class="col-12">
			<div v-if="getTask.isSelfReviewing" class="row m-3 align-items-center">
				<b-form-checkbox v-model="acceptCriticalErrors" class="form-switch" switch>
					Permitir finalizar legendagem com erros críticos
				</b-form-checkbox>
				<i id="acceptCriticalErrors-info" class="fa fa-question-circle ml-2" aria-hidden="true"></i>
				<b-tooltip :target="'acceptCriticalErrors-info'" triggers="hover" placement="bottom">
					<div class="d-flex text-left">
						Ao permitir finalizar legendagem com erros críticos você poderá confirmar segmentos que não seguem os
						padrões de legendagem, como limite de CPS, duração do segmento e quebra de linhas.
					</div>
				</b-tooltip>
			</div>

			<div class="row m-3 align-items-center">
				<b-form-checkbox v-model="activeSegmentOverlap" class="form-switch" switch>
					Permitir sobreposição de segmentos
				</b-form-checkbox>
				<i id="activeSegmentOverlap-info" class="fa fa-question-circle ml-2" aria-hidden="true"></i>
				<b-tooltip :target="'activeSegmentOverlap-info'" triggers="hover" placement="bottom">
					<div class="d-flex text-left">
						Ao permitir sobreposições de segmentos você poderá definir qualquer tempo inicial e final para o segmento,
						mesmo que ultrapasse um tempo já definido por outro segmentos. Redobre a atenção ao utilizar essa
						configuração!
					</div>
				</b-tooltip>
			</div>

			<div class="row m-3 align-items-center">
				<b-form-checkbox v-model="skipToNextSegmentAfterSave" class="form-switch" switch>
					Pular para próximo segmentos após salvar
				</b-form-checkbox>
				<i id="skipToNextSegmentAfterSave-info" class="fa fa-question-circle ml-2" aria-hidden="true"></i>
				<b-tooltip :target="'skipToNextSegmentAfterSave-info'" triggers="hover" placement="bottom">
					<div class="d-flex text-left">
						Ao selecionar essa opção, quando você salvar algum segmento, automaticamente será enviado para o próximo.
						Atenção: Essa função só é executado utilizando a tecla de atalho!
					</div>
				</b-tooltip>
			</div>

			<div id="save-area" class="row">
				<button @click="save">Salvar</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			acceptCriticalErrors: false,
			activeSegmentOverlap: false,
			skipToNextSegmentAfterSave: true,
		};
	},
	computed: {
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		userPreferences() {
			return this.$store.getters['firebase/getUserPreference'];
		},
	},
	mounted() {
		this.acceptCriticalErrors = this.getTask.permissionToFinishWithError || false;
		// Segments overlap
		this.activeSegmentOverlap = this.userPreferences != null ? this.userPreferences.activeSegmentOverlap : false;
		// Skip after save
		this.skipToNextSegmentAfterSave =
			this.userPreferences != null ? this.userPreferences.skipToNextSegmentAfterSave : true;
	},
	methods: {
		save() {
			let task = this.getTask;
			task.permissionToFinishWithError = this.acceptCriticalErrors; // Finalize with critical erros
			this.$store.dispatch('firebase/updateTask', task); // update task

			let userPreferences = {
				...this.userPreferences,
				activeSegmentOverlap: this.activeSegmentOverlap,
				skipToNextSegmentAfterSave: this.skipToNextSegmentAfterSave,
			};

			let response = this.$store.dispatch('firebase/setUserPreferences', userPreferences);
			if (response == false) {
				alert('Erro ao salvar preferências!');
			} else {
				alert('Dados salvos com sucesso.');
			}
		},
	},
};
</script>

<style lang="scss">
@import '@/app.scss';

#preferences-modal {
	color: #fff;
	min-height: 100%;

	.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
		background-color: $color-auxiliary;
		border-color: $color-auxiliary;
	}
}
</style>
