import { render, staticRenderFns } from "./VideoName.vue?vue&type=template&id=0bd96a12&scoped=true"
import script from "./VideoName.vue?vue&type=script&lang=js"
export * from "./VideoName.vue?vue&type=script&lang=js"
import style0 from "./VideoName.vue?vue&type=style&index=0&id=0bd96a12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd96a12",
  null
  
)

export default component.exports