function merge(left, right) {
	let arr = [];
	// Break out of loop if any one of the array gets empty
	while (left.length && right.length) {
		// Pick the smaller among the smallest element of left and right sub arrays
		if (parseInt(left[0].id_reference) < parseInt(right[0].id_reference)) {
			arr.push(left.shift());
		} else {
			arr.push(right.shift());
		}
	}

	// Concatenating the leftover elements
	// (in case we didn't go through the entire left or right array)
	return [...arr, ...left, ...right];
}

function mergeSortByIdReference(array) {
	const half = array.length / 2;

	// Base case or terminating case
	if (array.length < 2) {
		return array;
	}

	const left = array.splice(0, half);
	return merge(mergeSortByIdReference(left), mergeSortByIdReference(array));
}

export default mergeSortByIdReference;
