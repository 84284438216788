<template>
	<b-modal :visible="openModal" title="Feedback e sugestões" ok-title="Finalizar revisão" @ok="finalizeTask">
		<div v-if="!isRevision" class="box-feedback">
			<div class="content">
				<div class="feed-title">Avalie o Editor</div>
				<span> Dê uma nota para sua experiência com o Editor:</span>
				<b-form-rating v-model="value" :no-border="true" variant="warning"></b-form-rating>
				<br />
				<span>Você possui alguma sugestão de melhoria? Digite abaixo:</span>
				<br />
				<b-form-textarea
					id="textarea-no-resize"
					v-model="textFeedback"
					placeholder="Digite sua sugestão aqui"
					rows="3"
					no-resize
					class="textarea-feedback"
				></b-form-textarea>
			</div>
		</div>

		<div v-else class="box-feedback">
			<div v-for="(feed, index) in feedback" :key="index">
				<div v-if="index === content" class="content">
					<div class="feed-title">
						Avaliação: <span class="tag" :style="getStyle(index)">Revisor {{ index + 1 }}</span>
					</div>
					<span> Dê uma nota para a revisão:</span>
					<b-form-rating v-model="feed.valueRevision" :no-border="true" variant="warning"></b-form-rating>
					<br />
					<span>Digite uma sugestão de melhoria para o revisor:</span>
					<br />
					<b-form-textarea
						id="textarea-no-resize"
						v-model="feed.textFeedbackRevision"
						placeholder="Digite sua sugestão aqui"
						rows="3"
						no-resize
						class="textarea-feedback"
					></b-form-textarea>
				</div>
			</div>

			<div class="feed-pagination">
				<button v-if="content > 0" class="back" @click="back">Voltar</button>
				<span class="pagination">{{ content + 1 }}/{{ pages + 1 }}</span>
				<button v-if="content < pages" class="next" @click="next">Avançar</button>
			</div>
		</div>

		<b-alert show variant="danger" class="observation">
			Ao clicar em <b>Finalizar revisão</b> não será mais possível realizar qualquer edição adicional. Certifique-se de
			que todos os segmentos foram revisados e sincronizados adequadamente.
		</b-alert>
	</b-modal>
</template>

<script>
export default {
	props: {
		openModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			value: 0,
			textFeedback: '',
			feedback: [],
			content: 0,
			pages: 1,
			isRevision: false,
		};
	},
	computed: {
		isOpen() {
			return this.openModal ? true : false;
		},
		getReviewdByQa() {
			return this.$store.getters['firebase/getReviewdByQa'];
		},
		getJoinPoints() {
			return this.$store.getters['firebase/getJoinPoints'];
		},
		getIsReviewQA() {
			return this.$store.getters['firebase/getIsReviewQA'];
		},
		getTagColors() {
			return this.$store.getters['firebase/getTagColors'];
		},
	},
	mounted() {
		this.pages = this.getJoinPoints.length - 1;
		if (this.getIsReviewQA && this.getReviewdByQa) {
			this.isRevision = true;
			if (this.pages <= 0) {
				this.pages = 0;
				this.feedback.push({
					valueRevision: 0,
					textFeedbackRevision: '',
				});
			} else {
				this.getJoinPoints.forEach((joinPoint) => {
					this.feedback.push({
						subtask_id: joinPoint.subtask_id,
						valueRevision: 0,
						textFeedbackRevision: '',
					});
				});
			}
		} else {
			this.pages = 0;
			this.isRevision = false;
		}
	},
	methods: {
		async finalizeTask() {
			// If the user is not QA then the feedback will be sent
			if (!this.getReviewdByQa) {
				await this.$store.dispatch('firebase/addFeedback', {
					value: this.value,
					textFeedback: this.textFeedback,
				});

				this.$emit('finalizeTask', {});
				return;
			} else {
				// verificar todos os pontos de junção
				this.$emit('finalizeTask', this.feedback);
			}
		},
		back() {
			if (this.content > 0) this.content--;
		},
		next() {
			if (this.content < this.pages) this.content++;
		},
		getStyle(index) {
			return {
				backgroundColor: this.getTagColors[index],
			};
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/app.scss';

@mixin fontConfig {
	font-family: 'roboto', sans-serif;
	font-weight: 300;
	font-size: 14px;
	color: #d6d2d2;
}

.finalize-button {
	@include fontConfig;
	background-color: #424242 !important;
}

.textarea-feedback {
	margin-top: 20px;
	margin-bottom: 20px;
}

.observation {
	margin-top: 3%;
}

.box-feedback {
	width: 100%;
	height: auto;
	padding: 10px;
	border-radius: 5px;
	//background-color: rgba(128, 128, 128, 0.326);

	.feed-title {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		width: 100%;
		height: auto;
		font-weight: bold;
		margin-bottom: 10px;
	}

	.feed-pagination {
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
		grid-auto-rows: 1fr;
		grid-template-areas: 'back pagination next';
		width: 100%;
		height: auto;
		button {
			@include fontConfig;
			background-color: #424242;
			border: none;
			border-radius: 5px;
			&:hover {
				background-color: $background;
			}
		}

		.back {
			grid-area: back;
		}

		.pagination {
			grid-area: pagination;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
		}

		.next {
			grid-area: next;
		}
	}

	.tag {
		border-radius: 5px;
		margin-left: 1%;
		padding: 0px 5px;
	}
}
</style>
