<template>
	<div class="input-wrapper">
		<div class="inputGrid">
			<input v-model="timeFormat" type="text" @change="updateManualTime" />
		</div>

		<i
			class="downButton fa fa-chevron-down"
			@mousedown="startIncrementTime('minus')"
			@mouseup="stopIncrementTime"
			@mouseout="stopIncrementTime"
		></i>
		<i
			class="upButton fa fa-chevron-up"
			@mousedown="startIncrementTime('plus')"
			@mouseup="stopIncrementTime"
			@mouseout="stopIncrementTime"
		></i>
	</div>
</template>

<script>
import { mili2srt, srt2mili } from '@/utils/convertTime';

export default {
	props: {
		value: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			incrementTime: null,
			time: 0,
		};
	},
	computed: {
		timeFormat() {
			return mili2srt(this.time);
		},
	},
	watch: {
		value(newValue) {
			this.time = parseInt(newValue);
		},
	},
	created() {
		this.time = this.value;
	},
	methods: {
		startIncrementTime(type) {
			this.incrementTime = setInterval(() => {
				if (type == 'minus') {
					this.time -= 100;
					this.$emit('changeTime', this.time);
				} else {
					this.time += 100;
					this.$emit('changeTime', this.time);
				}
			}, 1);
		},
		stopIncrementTime() {
			clearInterval(this.incrementTime);
		},
		updateManualTime(event) {
			// get the value from event
			let newValue = event.target.value;
			let srtToMili = srt2mili(newValue);
			this.$emit('changeTime', srtToMili);
		},
	},
};
</script>

<style lang="scss" scoped>
@mixin glass {
	background: rgba(99, 97, 97, 0.411);
	border-radius: 5px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(7.6px);
	-webkit-backdrop-filter: blur(7.6px);
	border: 1px solid rgba(0, 0, 0, 0.97);
}

// Font-Color
$font-color: rgb(214, 210, 210);
$color-auxiliary: rgb(148, 70, 233);

.input-wrapper {
	display: grid;
	grid-template-columns: 3fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		'inputGrid upButton'
		'inputGrid downButton';
	width: 100%;
	height: 100%;

	.inputGrid {
		grid-area: inputGrid;
		display: flex;
		justify-content: center;
		align-items: center;

		input {
			@include glass;
			color: $font-color;
			width: 100%;
			height: 50%;
			font-size: 12px;
			text-align: center;
		}
	}

	.downButton {
		display: flex;
		align-content: center;
		justify-content: center;
		grid-area: downButton;
		font-size: 12px;
		color: #fff;
		cursor: pointer;

		&:hover {
			transform: scale(1.2);
			color: $color-auxiliary;
		}

		&::before {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
		}
	}

	.upButton {
		display: flex;
		align-content: center;
		justify-content: center;
		grid-area: upButton;
		font-size: 12px;
		color: #fff;
		cursor: pointer;

		&:hover {
			transform: scale(1.2);
			color: $color-auxiliary;
		}

		&::before {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
		}
	}
}
</style>
