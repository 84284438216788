<template>
	<div class="text-mode-change">
		<button v-if="getAudioView" @click="setAudioView">Modo vídeo</button>
		<button v-else @click="setAudioView">Modo áudio</button>
	</div>
</template>

<script>
export default {
	computed: {
		getAudioView() {
			return this.$store.getters['getAudioView'];
		},
	},
	methods: {
		setAudioView() {
			this.$store.dispatch('setAudioView', !this.getAudioView);
		},
	},
};
</script>

<style lang="scss">
@import '../../app.scss';

.text-mode-change {
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
	height: 100%;

	button {
		width: auto;
		min-width: 100px;
	}
}
</style>
