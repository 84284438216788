import SegmentTools from '../../utils/segmentTools';

const state = {
	taskState: {
		currentIndex: -1,
		maxIndex: 0,
		synchronyMode: false,
		revisionMode: false,
		showOptions: null,
		errorNotification: null,
	},
	playing: false,
	playbackRate: 1,
	permissionToSaveSegmentWithError: null,
	annoucerSelect: null,
	audioDescriptionPopup: false,
	audioDescriptionText: '',
	shortcuteEvent: {
		eventKey: null,
		time: null,
	},
};

const getters = {
	getCurrentIndex: (state) => state.taskState.currentIndex,
	getCurrentSegment: (state, _, _2, rootGetters) => rootGetters['firebase/getSubtitle'](state.taskState.currentIndex),
	getBeforeSegment: (state, _, _2, rootGetters) =>
		rootGetters['firebase/getSubtitle'](state.taskState.currentIndex - 1),
	getNextSegment: (state, _, _2, rootGetters) => rootGetters['firebase/getSubtitle'](state.taskState.currentIndex + 1),
	getSubtitlesLength: (state, _, _2, rootGetters) => rootGetters['firebase/getSubtitlesLength'],
	getSynchronyMode: (state) => state.taskState.synchronyMode,
	getRevisionMode: (state) => state.taskState.revisionMode,
	getShowOptions: (state) => state.taskState.showOptions,
	getErrorNotification: (state) => state.taskState.errorNotification,
	getPlaying: (state) => state.playing,
	permissionToSaveSegmentWithErrorFLAG: (state) => state.permissionToSaveSegmentWithError,
	getAnnoucerPress: (state) => state.annoucerSelect,
	getAudioDescriptionPopup: (state) => state.audioDescriptionPopup,
	getShortcuteEvent: (state) => state.shortcuteEvent,
	getPlaybackRate: (state) => state.playbackRate,
};

const mutations = {
	setCurrentIndex: (state, index) => {
		if (index == state.taskState.currentIndex) return;

		if (index < 1) {
			state.taskState.currentIndex = 1;
			return;
		}

		state.taskState.currentIndex = index;
	},
	setSynchronyMode: (state, status = null) => {
		if (status != null) state.taskState.synchronyMode = status;
		else state.taskState.synchronyMode = !state.taskState.synchronyMode;
	},
	setRevisionMode: (state, status = null) => {
		if (status != null) state.taskState.revisionMode = status;
		else state.taskState.revisionMode = !state.taskState.revisionMode;
	},
	showOptions: (state, data) => {
		state.taskState.showOptions = data;
	},
	setErrorNotification: (state, data) => {
		state.taskState.errorNotification = data;
	},
	setPlaying: (state, status) => {
		state.playing = status;
	},
	setPermissionToSaveSegmentWithError: (state, value) => {
		state.permissionToSaveSegmentWithError = value;
	},
	setAnnoucer: (state, annoucerName) => {
		state.annoucerSelect = annoucerName;
	},
	setAudioDescriptionPopup: (state, status) => {
		state.audioDescriptionPopup = status;
	},
	setShortcuteEvent: (state, event) => {
		state.shortcuteEvent = event;
	},
	setPlaybackRate: (state, rate) => {
		state.playbackRate = rate;
	},
};

const actions = {
	async saveSegment({ dispatch, getters, rootGetters }) {
		let segment = getters.getCurrentSegment;
		let getPerssionToSaveWithCriticalError = rootGetters['firebase/getPerssionToSaveWithCriticalError'];
		let verifyIfHasCriticalError = await dispatch('checkIfSegmentHasCriticalError', { segment });

		if (verifyIfHasCriticalError && getPerssionToSaveWithCriticalError) {
			if (!segment.is_saved) {
				await dispatch('saveSegmentFlag', Date.now());
				return;
			} else {
				await dispatch('saveSegmentAction', segment);
				return;
			}
		}

		if (verifyIfHasCriticalError) {
			await dispatch('setErrorNotification', {
				title: 'Erro ao confirmar segmento',
				message: 'O segmento não pode ser confirmado pois possui algum erro crítico.',
				cancelAutoHide: false,
			});
			return;
		}

		await dispatch('saveSegmentAction', segment);
	},
	async saveSegmentAction({ dispatch }, segment) {
		segment.is_saved = !segment.is_saved;
		await dispatch('firebase/updateSegment', { segment: segment }, { root: true });
		await dispatch('firebase/updateConfirmSegments', {}, { root: true });
	},
	async saveSegmentPosition({ getters, dispatch }, position) {
		let segment = getters.getCurrentSegment;
		segment.position = position;
		await dispatch('firebase/updateSegment', { segment: segment }, { root: true });
	},
	async checkIfSegmentHasError({ rootGetters }, { segment }) {
		let getIsTranslate = rootGetters['firebase/getIsTranslate'];
		if (!segment.errors) return false;
		if (getIsTranslate) {
			if (segment.errors.translation.list.length > 0 && segment.errors.translation.isCritical) return true;
		} else {
			if (segment.errors.transcription.list.length > 0 && segment.errors.transcription.isCritical) return true;
		}
		return false;
	},
	async checkIfSegmentHasCriticalError({ rootGetters }, { segment }) {
		let getIsTranslate = rootGetters['firebase/getIsTranslate'];
		if (!segment.errors) return false;
		if (getIsTranslate) {
			if (segment.errors.translation.isCritical) return true;
		} else {
			if (segment.errors.transcription.isCritical) return true;
		}
		return false;
	},
	async addSegment(
		{ getters, rootGetters, dispatch },
		{
			text = null,
			translate_text: translateText = null,
			indice = null,
			isUndoRedo = false,
			startTimeReference = null,
			endTimeReference = null,
			errors = {
				transcription: {
					isCritical: false,
					list: [],
				},
				translation: {
					isCritical: false,
					list: [],
				},
			},
		}
	) {
		/** 
		 Para criar um novo segmento, pegamos o id_reference do segmento anterior e do segmento posterior e geramos um novo
		 id_reference a partir deles com um incremento (to_add_id) que será o id_reference do novo segmento.
		 Esse novo id_reference é garantido que está entre os dois valores de id_reference do segmento anterior e posterior.
		*/
		try {
			let currentSegment, nextSegment, id;

			// Se adicionar entre os segmentos
			if (indice === null && getters.getSubtitlesLength !== 0) {
				currentSegment = getters.getCurrentSegment;
				nextSegment = getters.getNextSegment;
				indice = currentSegment.id;
			} else {
				currentSegment = rootGetters['firebase/getSubtitle'](indice);
				nextSegment = rootGetters['firebase/getSubtitle'](indice + 1);
				if (nextSegment == undefined) nextSegment = { id_reference: null };
			}

			// Se for adicionar e não exister outros segmentos, o id_reference será o primeiro
			if (getters.getSubtitlesLength === 0) {
				let newSegmentInitial = {
					id_reference: `${new Date().getTime()}`,
					start: 0,
					end: 2000,
					text: text != null && text != undefined ? text : '',
					translate_text: translateText != null && translateText != undefined ? translateText : '',
					is_bookmark: false,
					is_saved: false,
					errors: errors,
				};
				dispatch('firebase/addSegment', { segment: newSegmentInitial, isUndoRedo }, { root: true }); // Adiciona o novo segmento no firebase
				return newSegmentInitial;
			}

			// Se for  adicionar na primeira posição
			if (indice == 0) {
				currentSegment = rootGetters['firebase/getSubtitle'](1);
				endTime += currentSegment.end + 5000;
				let newSegmentInitial = {
					id_reference: `${+currentSegment.id_reference - 1000000}`,
					start: 0,
					end: currentSegment.start,
					text: text != null && text != undefined ? text : '',
					translate_text: translateText != null && translateText != undefined ? translateText : '',
					is_bookmark: false,
					is_saved: false,
					errors: errors,
				};
				dispatch('firebase/addSegment', { segment: newSegmentInitial, isUndoRedo }, { root: true }); // Adiciona o novo segmento no firebase
				return newSegmentInitial;
			}

			// Se for para adicionar na última posição
			if (indice == getters.getSubtitlesLength) {
				currentSegment = rootGetters['firebase/getSubtitle'](getters.getSubtitlesLength);

				let newSegmentInitial = {
					id_reference: `${+currentSegment.id_reference + 1000000}`,
					start: currentSegment.end,
					end: currentSegment.end + 5000,
					text: text != null && text != undefined ? text : '',
					translate_text: translateText != null && translateText != undefined ? translateText : '',
					is_bookmark: false,
					is_saved: false,
					errors: errors,
				};
				dispatch('firebase/addSegment', { segment: newSegmentInitial, isUndoRedo }, { root: true }); // Adiciona o novo segmento no firebas
				return newSegmentInitial;
			}

			// Se for adicionar entre os segmentos
			// Define o intervalo de tempo do novo segmento
			let startTime, endTime;
			if (startTimeReference == null && endTimeReference == null) {
				startTime = currentSegment.end;
				endTime = nextSegment ? nextSegment.start : null;
			} else {
				startTime = startTimeReference;
				endTime = endTimeReference;
			}

			// Gera o novo id_reference do novo segmento entre o currentSegment e o nextSegment
			let to_add_id = (+nextSegment.id_reference - +currentSegment.id_reference) / 2;
			id = `${(+currentSegment.id_reference + +to_add_id).toFixed(0)}`;

			let newSegment = {
				id_reference: id,
				start: startTime,
				end: endTime,
				text: text != null && text != undefined ? text : '',
				translate_text: translateText != null && translateText != undefined ? translateText : '',
				is_bookmark: false,
				is_saved: false,
				errors: errors,
			};

			dispatch('firebase/addSegment', { segment: newSegment, isUndoRedo }, { root: true }); // Adiciona o novo segmento no firebase
			return newSegment;
		} catch (err) {
			console.error('Error [controller/addSegment]:', err);
		}
	},
	removeSegment({ getters, dispatch, rootGetters }, indice = null) {
		try {
			let currentSegment;
			indice === null
				? (currentSegment = getters.getCurrentSegment)
				: (currentSegment = rootGetters['firebase/getSubtitle'](indice));
			dispatch('firebase/removeSegment', { segment: currentSegment, isUndoRedo: false }, { root: true }); // Remove o segmento atual do firebase
			dispatch('navigateUp');
		} catch (err) {
			console.error('Error [controller/removeSegment]:', err);
		}
	},
	updateSegmentTime({ dispatch, getters, rootGetters }, param) {
		try {
			let currentSegment = getters.getCurrentSegment;
			let updateTime = rootGetters['video/getUpdateTime'];

			if (param == 'start') currentSegment.start = updateTime;
			if (param == 'end') currentSegment.end = updateTime;

			dispatch('firebase/updateSegment', { segment: currentSegment }, { root: true });
		} catch (err) {
			console.error('Error [controller/updateSegmentTime]:', err);
		}
	},

	joinSegments({ getters, dispatch }) {
		try {
			let currentSegment = getters.getCurrentSegment;
			let beforeSegment = getters.getBeforeSegment;
			if (beforeSegment == undefined) throw 'Error [controller/joinSegments]: Segmento anterior não encontrado.';

			// Verifica se o segmento não termina com quebra de linha
			if (!beforeSegment.text.includes('\n', 0) && !currentSegment.text.includes('\n', 0)) {
				// Adiciona a quebra de linha e realiza o join do texto
				beforeSegment.text = beforeSegment.text + '\n' + currentSegment.text;
				beforeSegment.translate_text = beforeSegment.translate_text + '\n' + currentSegment.translate_text;
			} else {
				// Realiza o join do texto
				beforeSegment.text = beforeSegment.text + ' ' + currentSegment.text;
				beforeSegment.translate_text = beforeSegment.translate_text + ' ' + currentSegment.translate_text;
			}
			// Realiza o join dos tempos
			beforeSegment.end = currentSegment.end;

			dispatch('firebase/removeSegment', { segment: currentSegment }, { root: true }); // Remove o segmento atual do firebase
			dispatch('firebase/updateSegment', { segment: beforeSegment }, { root: true });
		} catch (err) {
			console.error('Error [controller/joinSegments]:', err);
		}
	},

	setCurrentIndex: ({ commit }, index) => {
		commit('setCurrentIndex', index);
	},
	navigateUp({ commit, rootGetters, getters }) {
		let actualIndex;
		if (rootGetters['firebase/getSubtitlesLength'] == 0) return;
		actualIndex = getters.getCurrentSegment;
		actualIndex = rootGetters['firebase/getSubtitles'].indexOf(actualIndex);
		actualIndex = rootGetters['firebase/getSubtitles'][actualIndex - 1];
		if (actualIndex == undefined) return;
		if (actualIndex.id == 0) return;
		commit('setCurrentIndex', actualIndex.id);
	},
	navigateDown({ commit, rootGetters, getters }) {
		let actualIndex;
		if (rootGetters['firebase/getSubtitlesLength'] == 0) return;
		actualIndex = getters.getCurrentSegment;
		actualIndex = rootGetters['firebase/getSubtitles'].indexOf(actualIndex);
		actualIndex = rootGetters['firebase/getSubtitles'][actualIndex + 1];
		if (actualIndex == undefined) return;
		if (actualIndex.id > rootGetters['firebase/getSubtitlesLength']) return;
		commit('setCurrentIndex', actualIndex.id);
	},
	navigateBetweenErrors({ commit, rootGetters, getters }, direction) {
		if (rootGetters['firebase/getSubtitlesLength'] == 0) return;

		const actualIndex = getters.getCurrentIndex;
		const allSubtitles = rootGetters['firebase/getSubtitles'];
		const isTranslate = rootGetters['firebase/getIsTranslate'];

		if (direction == 'UP') {
			// Busca o próximo erro acima
			for (let i = actualIndex - 1; i >= 0; i--) {
				const segment = allSubtitles[i - 1];
				if (SegmentTools.showIfSegmentHasErrors(segment, isTranslate)) {
					commit('setCurrentIndex', segment.id);
					break;
				}
			}
		} else {
			// Busca o próximo erro abaixo
			for (let i = actualIndex + 1; i < allSubtitles.length; i++) {
				const segment = allSubtitles[i - 1];
				if (SegmentTools.showIfSegmentHasErrors(segment, isTranslate)) {
					commit('setCurrentIndex', segment.id);
					break;
				}
			}
		}
	},
	playPause({ commit, getters }, status = null) {
		try {
			let playing = getters.getPlaying;
			playing = status === null ? !playing : status;
			commit('setPlaying', playing);
		} catch (err) {
			console.error('Error [controller/playPause]:', err);
		}
	},
	replaySegment({ dispatch }) {
		try {
			dispatch('video/replaySegment', null, { root: true });
		} catch (err) {
			console.error('Error [controller/replaySegment]:', err);
		}
	},
	setPlaybackRate({ commit }, rate) {
		commit('setPlaybackRate', rate);
	},
	fowerRewindTime({ dispatch }, isForward) {
		try {
			if (isForward) {
				dispatch('video/forward', null, { root: true });
			} else {
				dispatch('video/rewind', null, { root: true });
			}
		} catch (err) {
			console.error('Error [controller/fowerdTime]:', err);
		}
	},

	// AUX FUNCTIONS
	saveSegmentFlag({ commit }, value) {
		commit('setPermissionToSaveSegmentWithError', value);
	},
	setSynchronyMode({ commit }) {
		commit('setSynchronyMode');
	},
	setRevisionMode({ commit }) {
		commit('setRevisionMode');
	},
	showOptions({ commit }, data) {
		commit('showOptions', data);
	},
	setErrorNotification({ commit }, data) {
		commit('setErrorNotification', data);
	},
	setAnnoucer({ commit }, annoucerName) {
		commit('setAnnoucer', annoucerName);
	},
	sendShortcuteEvent({ commit }, eventCode) {
		commit('setShortcuteEvent', {
			eventKey: eventCode,
			time: Date.now(),
		});
	},
};

const namespaced = true;

export default {
	namespaced,
	state,
	mutations,
	actions,
	getters,
};
