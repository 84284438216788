<template>
	<div ref="contentTable" class="clusterize">
		<div class="menu-table">
			<div class="text-content-0"><span>Id</span></div>
			<div class="text-content-1"><span>Início</span></div>
			<div class="text-content-1"><span>Final</span></div>
			<div :class="[getIsTranslate ? 'text-content-2' : 'text-content-2  only-transcription']">
				<span>Transcrição</span>
			</div>
			<div v-if="getIsTranslate" class="text-content-2"><span>Tradução</span></div>
			<div class="text-content-4">
				<FilterSegment @changeFilter="setFilter" />
			</div>
			<FindAndReplace v-if="findAndReplaceIsOpen" />
		</div>
		<virtual-list
			class="clusterize-scroll"
			:data-key="'id'"
			:data-sources="items"
			:data-component="itemComponent"
			:estimate-size="80"
		/>
	</div>
</template>

<script>
import FilterSegment from './FilterSegments/FilterSegment';
import FindAndReplace from './FindAndReplace/FindAndReplace.vue';
import VirtualList from 'vue-virtual-scroll-list';
import SegmentRow from './SegmentRow.vue';

export default {
	components: {
		FilterSegment,
		FindAndReplace,
		VirtualList,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectSegment: null,
			segmentHover: null,
			oldSelectSegment: null,
			errorDetect: '',
			clusterize: null,
			rows: [],
			items: [],
			itemComponent: SegmentRow,
		};
	},
	computed: {
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getCurrentIndex() {
			return this.$store.getters['controller/getCurrentIndex'];
		},
		getSubtitles() {
			return this.$store.getters['firebase/getSubtitles'];
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getJoinPoints() {
			return this.$store.getters['firebase/getJoinPoints'];
		},
		getReviewdByQa() {
			return this.$store.getters['firebase/getReviewdByQa'];
		},
		findAndReplaceIsOpen() {
			return this.$store.getters['getFindAndReplace'];
		},
		getShortcuteEvent: {
			get() {
				return this.$store.getters['controller/getShortcuteEvent'];
			},
			deep: true,
		},
	},
	watch: {
		async getCurrentIndex(newValue) {
			// This is necessary because the row maybe is not in the clusterize container. (not render)
			new Promise((resolve) => {
				let scrollArea = document.querySelector('.clusterize-scroll');
				scrollArea.scrollTo({
					top: newValue * 80 - 160,
				});
				resolve();
			})
				.then(() => {
					this.addMandatory();
				})
				.finally(() => {
					this.removeMandatory();
				});
		},
		getShortcuteEvent(event) {
			if (event.eventKey == 'openFindAndReplace') {
				this.$store.dispatch('setFindAndReplace');
			}
		},
		getSubtitles() {
			this.items = this.getSubtitles;
		},
	},
	mounted() {
		// find the last segmenet select
		this.selectSegment = this.getTask.last_segment_selected;
		this.$nextTick(() => {
			this.$store.commit('controller/setCurrentIndex', this.selectSegment);
		});
		this.items = this.getSubtitles;
		// Set next loading stage
		this.$store.dispatch('setLoadingStage', 3);
	},
	methods: {
		setFilter(data) {
			this.$store.dispatch('firebase/setFilter', data);
			this.$nextTick(() => {
				if (this.getSubtitles.length > 0 && !data.all) {
					this.$store.commit('controller/setCurrentIndex', this.getSubtitles[0].id);
				} else {
					let scrollArea = document.querySelector('.clusterize-scroll');
					scrollArea.scrollTo({
						top: this.getCurrentIndex * 80 - 160,
					});
				}
			});
		},
		removeMandatory() {
			let scrollArea = document.querySelector('.clusterize-scroll');
			scrollArea.style.scrollSnapType = 'y proximity';
		},
		addMandatory() {
			new Promise((resolve) => {
				let scrollArea = document.querySelector('.clusterize-scroll');
				scrollArea.style.scrollSnapType = 'y mandatory';
				resolve();
			});
		},
	},
};
</script>

<style lang="scss" scopped>
@import '../../app.scss';

.menu-table {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 10%;
	width: 100%;
	font-weight: bold;
	background-color: $background-scrollbar;
	border-bottom: 1px solid $secondary;
	color: $white;
	z-index: 1;
	position: relative;

	@mixin menu-content {
		border: none;
		height: auto;
	}

	div {
		@include menu-content;
		text-align: center;
	}
}

.clusterize-scroll {
	max-width: 100%;
	max-height: 90%;
	overflow-y: scroll;
	overflow-x: hidden;
	scroll-snap-type: y mandatory;
	scroll-behavior: smooth;
}

.tagColor {
	width: 10%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 0px;
}

.critical {
	color: $invalid !important;
}

.warning {
	color: $warning !important;
}

.filter-area {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 200px;
	height: 200px;

	.labels-filter {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		height: 80%;

		.item-filter {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding-left: 10%;
		}
	}

	.fa-times-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 10px;
		width: 10%;
		height: 10%;
		border: none;
		color: #fff;
		background-color: transparent;
	}

	.buttons-filter {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 100%;
		height: 20%;

		button {
			border-radius: 3px;
			color: #fff;
			margin-left: 10px;
		}

		.success {
			background-color: $confirm;
		}

		.danger {
			background-color: $invalid;
		}
	}
}

.text-content-0 {
	min-width: 5%;
}

.text-content-1 {
	min-width: 10%;
}

.text-content-2 {
	min-width: 35%;
}

.text-content-2.only-transcription {
	min-width: 70%;
}

.text-content-4 {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
	min-width: 5%;
	border: 1px solid red;
}
</style>
