<template>
	<Draggable class="box">
		<div class="topbar-item">
			<div class="topbar-item-title">
				<span id="info-job-interval" class="fa fa-question-circle help-join-points" aria-hidden="true"></span>
				<b-tooltip class="tooltip-job-interval" target="info-job-interval" triggers="hover" placement="bottom">
					<div>
						Os pontos de junção são pontos em que subjobs foram divididos durante a atribuição e interpolados
						posteriormente para realização do QA. Verifique se esses pontos não possuem errros como sobreposição,
						repetição e etc.
					</div>
				</b-tooltip>
				<span>Pontos de junção</span>
			</div>
			<div class="button-join fa fa-times-circle" @click="closeModal"></div>
		</div>

		<div class="item-itens">
			<div class="item-item menu">
				<span>Parte</span>
				<span>Ínico</span>
				<span>Fim</span>
				<span></span>
			</div>

			<div v-for="(item, index) in getItems" :key="item.id" class="item-item-saved">
				<div
					v-if="index == selectedItem"
					class="item-item selected"
					:item-item="index"
					@click="selectItem(item, index)"
				>
					<span>{{ index + 1 }}</span>
					<span>{{ converMiliToSrt(item.start) }}</span>
					<span>{{ converMiliToSrt(item.end) }}</span>
				</div>

				<div v-else class="item-item" :item-item="index" @click="selectItem(item, index)">
					<span>{{ index + 1 }}</span>
					<span>{{ converMiliToSrt(item.start) }}</span>
					<span>{{ converMiliToSrt(item.end) }}</span>
				</div>
			</div>
			<span v-if="getItems.length == 0">Nenhum item encontrado!</span>
		</div>

		<div class="item-navagation">
			<div class="button-join" @click="beforeItem">
				<i class="fa fa-arrow-circle-left" aria-hidden="true"></i>
				Anterior
			</div>
			<div class="button-join" @click="nextItem">
				Próximo
				<i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
			</div>
		</div>
	</Draggable>
</template>

<script>
import { mili2srt } from '../../utils/convertTime';

export default {
	data() {
		return {
			selectedItem: -1,
		};
	},
	computed: {
		getItems() {
			return this.$store.getters['firebase/getJoinPoints'];
		},
		getSelectItem() {
			return this.getItems[this.selectedItem];
		},
		reviewedByQA() {
			return this.$store.getters['firebase/getReviewdByQa'];
		},
	},
	mounted() {
		this.$store.dispatch('setBookmarksActive', true);
	},
	destroyed() {
		this.$store.dispatch('setBookmarksActive', false);
	},
	methods: {
		closeModal() {
			this.$emit('close');
		},
		beforeItem() {
			if (this.selectedItem > 0) {
				this.selectedItem--;
				this.setCurrentTime(this.getItems[this.selectedItem].end);
			}
		},
		nextItem() {
			if (this.selectedItem < this.getItems.length - 1) {
				this.selectedItem++;
				this.setCurrentTime(this.getItems[this.selectedItem].end);
			}
		},
		selectItem(item, index) {
			this.selectedItem = index;
			let rowSelect = document.querySelector(`[item-item="${index}"]`);
			if (rowSelect) rowSelect.scrollIntoView({ block: 'center' });
			this.setCurrentTime(item.end);
		},
		converMiliToSrt(mili) {
			return mili2srt(mili);
		},
		async setCurrentTime(time) {
			let mili = parseInt(time);
			this.$store.commit('video/setVideoTimeChange', mili);
			this.$store.dispatch('soundwave/seeked', mili);
		},
	},
};
</script>

<style lang="scss" scoped>
.box {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 10%;
	right: 5%;
	width: 20vw;
	height: 20vh;
	z-index: 5;
	border-radius: 8px;
	box-shadow: 3px 3px 3px #000;

	.button-join {
		border: none;
		background: none;
		color: #fff;
		font-weight: bold;
		&:hover {
			cursor: pointer;
			transform: scale(1.05);
		}
	}

	.topbar-item {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 20%;
		padding: 2%;
		border-radius: 5px 5px 0px 0px;
		background-color: #121212;

		.help-join-points {
			position: absolute;
			left: 2%;
			top: 5%;
		}

		.topbar-item-title {
			color: #fff;
			font-weight: bold;
			margin-right: 10px;
		}

		.button-join {
			position: fixed;
			right: 2%;
			top: 5%;
		}
	}

	.item-itens {
		width: 100%;
		height: 60%;
		overflow-y: scroll;
		padding: 2%;
		background-color: rgb(99, 97, 97);
		color: #fff;
		font-weight: bold;

		.item-item-saved {
			width: 100%;
			height: auto;
		}

		.item-item {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			height: auto;
			text-align: center;
			&:hover {
				background-color: #121212ee;
				cursor: pointer;
			}
		}

		.selected {
			background: rgb(76, 7, 78);
		}
	}

	.item-navagation {
		width: 100%;
		height: 20%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 2%;
		border-radius: 0px 0px 5px 5px;
		background-color: #121212;
		font-size: 14px;
	}
}
</style>
