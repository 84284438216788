<template>
	<b-modal
		:visible="openModal"
		title="Realizar avaliação"
		ok-title="Finalizar revisão"
		@ok="finalizeTask"
		@close="resetModal"
		@cancel="resetModal"
	>
		<b-alert v-if="error != ''" show variant="danger">{{ error }}</b-alert>
		<span> Escolha a nota de 1 a 5 para para essa avaliação:</span>
		<b-form-rating v-model="value" :no-border="true" variant="warning"></b-form-rating>

		<br />

		<span>Digite um feedback para o avaliado:</span>
		<br />
		<b-form-textarea
			id="textarea-no-resize"
			v-model="textFeedback"
			placeholder="Digite sua sugestão aqui"
			rows="3"
			no-resize
			class="textarea-feedback"
		></b-form-textarea>

		<span> Essa avaliação foi aprovada?</span>
		<div class="isAprove">
			<b-button v-if="!approved && approved != null" variant="danger" @click="setApproved(false)">Não</b-button>
			<b-button v-else @click="setApproved(false)">Não</b-button>

			<b-button v-if="approved" variant="success" @click="setApproved(true)">Sim</b-button>
			<b-button v-else @click="setApproved(true)">Sim</b-button>
		</div>

		<br />

		<b-alert show variant="danger" class="observation">
			Ao clicar em <b>Finalizar revisão</b> essa avaliação será concluida e não poderá ser mais alterada.
		</b-alert>
	</b-modal>
</template>

<script>
export default {
	props: {
		openModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			value: 0,
			textFeedback: '',
			approved: null,
			error: '',
		};
	},
	methods: {
		async finalizeTask(bvModalEvt) {
			if (this.value == 0) {
				this.error = 'Dê uma nota antes de finalizar!';
				bvModalEvt.preventDefault();
				return;
			}
			if (this.approved === null) {
				this.error = 'Não se esqueça de aprovar/reprovar a avaliação!';
				bvModalEvt.preventDefault();
				return;
			}

			this.$emit('finalizeTask', {
				value: this.value,
				text_feedback: this.textFeedback,
				approved: this.approved,
			});
		},
		setApproved(value) {
			this.approved = value;
		},
		resetModal() {
			this.value = 0;
			this.textFeedback = '';
			this.approved = null;
			this.error = '';
		},
	},
};
</script>

<style lang="scss" scoped>
.textarea-feedback {
	margin-top: 20px;
	margin-bottom: 20px;
}

.observation {
	margin-top: 3%;
}

.isAprove {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
	min-height: 50px;
	margin-top: 3%;
	button {
		min-height: 100%;
		width: 40%;
		border-radius: 5px;
	}
}
</style>
