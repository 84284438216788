<template>
	<button
		id="add-watermark"
		:class="watermark ? 'fa fa-tint-slash desactive' : 'fa fa-tint desactive'"
		aria-hidden="true"
		@click="toggleWatermark"
	>
		<b-tooltip
			id="add-watermark"
			target="add-watermark"
			:title="watermark ? 'Remover marca dagua' : 'Adicionar marca dagua'"
			triggers="hover"
			placement="top"
		/>
	</button>
</template>

<script>
export default {
	data() {
		return {
			watermark: 0,
		};
	},
	computed: {
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
	},
	created() {
		this.watermark = this.getTask.watermark ? this.getTask.watermark : 0;
	},
	methods: {
		toggleWatermark() {
			// O parâmetro watermark deve ser 0 quando não tiver watermark ou composta por uma string de dois caracteres
			// sendo que o primeiro representa a posição da marca d’agua e o segundo representa a cor.

			// Posição: 1 (superior-esquerdo), 2 (superior-direito), 3 (inferior-esquerdo), 4 (inferior-direito)
			// Cor: b (preto) ou w (branco)
			if (this.watermark == 0) this.watermark = '4w';
			else this.watermark = 0;

			let task = this.getTask;
			task.watermark = this.watermark;
			this.$store.dispatch('firebase/updateTask', task);
		},
	},
};
</script>
