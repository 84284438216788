const config = {
	navigateUp: {
		mainAtl: 'alt',
		indice: 1,
		atl: 'arrowup',
		//code: 38,
		code: 'ArrowUp',
		title: 'Retroceder segmento ',
		block: false,
		page: 1,
	},
	navigateDown: {
		mainAtl: 'alt',
		indice: 2,
		atl: 'arrowdown',
		// code: 40,
		code: 'ArrowDown',
		title: 'Avançar segmento ',
		block: false,
		page: 1,
	},
	addSegment: {
		mainAtl: 'alt',
		indice: 3,
		atl: 'B',
		// code: 66,
		code: 'KeyB',
		title: 'Adicionar segmento ',
		block: false,
		page: 2,
	},
	removeSegment: {
		mainAtl: 'alt',
		indice: 4,
		atl: 'D',
		// code: 68,
		code: 'KeyD',
		title: 'Remover segmento ',
		block: false,
		page: 2,
	},
	splitSegment: {
		mainAtl: 'alt',
		indice: 5,
		atl: 'ENTER',
		// code: 13,
		code: 'Enter',
		title: 'Criar segmento a partir do cursor ',
		block: false,
		page: 2,
	},
	joinSegments: {
		mainAtl: 'alt',
		indice: 6,
		atl: 'N',
		// code: 78,
		code: 'KeyN',
		title: 'Junta segmento atual com o anterior',
		block: false,
		page: 4,
	},
	splitAndAddInNextSegment: {
		mainAtl: 'ctrl',
		indice: 7,
		atl: 'ENTER',
		// code: 13,
		code: 'Enter',
		title: 'Recorta texto até o cursor e une com o segmento posterior',
		block: false,
		page: 4,
	},
	cutFirstWorld: {
		mainAtl: 'alt',
		indice: 8,
		atl: 'DEL',
		// code: 46,
		code: 'Delete',
		title: 'Traz a primeira palavra do segmento seguinte para o atual',
		block: false,
		page: 4,
	},
	splitAndAddInNextSegmentWithTime: {
		mainAtl: 'ctrl',
		secondAtl: 'shift',
		indice: 9,
		atl: 'ENTER',
		// code: 13,
		code: 'Enter',
		title: 'Recorta texto até o cursor e une com o segmento posterior recalculando tempo',
		block: true,
		page: 4,
	},
	cutFirstWorldWithTime: {
		mainAtl: 'ctrl',
		secondAtl: 'shift',
		indice: 10,
		atl: 'DEL',
		// code: 46,
		code: 'Delete',
		title: 'Traz a primeira palavra do segmento seguinte para o atual recalculando tempo',
		block: true,
		page: 4,
	},
	playPause: {
		mainAtl: 'alt',
		indice: 11,
		atl: 'P',
		// code: 80,
		code: 'KeyP',
		title: 'Play/Pause',
		block: false,
		page: 1,
	},
	replaySegment: {
		mainAtl: 'alt',
		indice: 12,
		atl: 'R',
		// code: 82,
		code: 'KeyR',
		title: 'Replay do segmento',
		block: false,
		page: 1,
	},
	fowerdTime: {
		mainAtl: 'alt',
		indice: 13,
		atl: '.',
		// code: 190,
		code: 'Period',
		title: 'Avançar 2 segundos',
		block: false,
		page: 1,
	},
	rewindTime: {
		mainAtl: 'alt',
		indice: 14,
		atl: ',',
		// code: 188,
		code: 'Comma',
		title: 'Retroceder 2 segundos',
		block: false,
		page: 1,
	},
	setStartTime: {
		mainAtl: 'alt',
		indice: 15,
		atl: 'J',
		// code: 74,
		code: 'KeyJ',
		title: 'Definir tempo inicial do segmento',
		block: false,
		page: 2,
	},
	setEndTime: {
		mainAtl: 'alt',
		indice: 16,
		atl: 'K',
		// code: 75,
		code: 'KeyK',
		title: 'Definir tempo final do segmento',
		block: false,
		page: 2,
	},
	setSynchronyMode: {
		mainAtl: 'alt',
		indice: 17,
		atl: 'I',
		// code: 73,
		code: 'KeyI',
		title: 'Ativar/Desativar modo de sincronia',
		block: false,
		page: 3,
	},
	confirmSegment: {
		mainAtl: 'alt',
		indice: 18,
		atl: 'S',
		// code: 83,
		code: 'KeyS',
		title: 'Confirmar segmento',
		block: false,
		page: 2,
	},
	invertExclamation: {
		mainAtl: 'alt',
		indice: 19,
		atl: 'Q',
		// code: 81,
		code: 'KeyQ',
		title: 'Atalho para adicionar "¡"',
		block: false,
		page: 3,
	},
	invertInterrogation: {
		mainAtl: 'alt',
		indice: 20,
		atl: 'W',
		// code: 87,
		code: 'KeyW',
		title: 'Atalho para adicionar "¿"',
		block: false,
		page: 3,
	},
	openFindAndReplace: {
		mainAtl: 'ctrl',
		indice: 21,
		atl: 'F',
		// code: 70,
		code: 'KeyF',
		title: 'Abrir caixa "Procurar e substituir"',
		block: true,
		page: 3,
	},
	addMusicSymbol: {
		mainAtl: 'alt',
		indice: 22,
		atl: 'M',
		// code: 77,
		code: 'KeyM',
		title: 'Atalho para adicionar "♪" (caso haja texto selecionado, adiciona "♪" antes e depois do texto)',
		block: false,
		page: 3,
	},
	addSoundDescription: {
		mainAtl: 'alt',
		indice: 23,
		atl: '[',
		// code: 221,
		code: 'BracketRight',
		title: 'Atalho para adicionar "[ ]"',
		block: false,
		page: 3,
	},
	addQuotes: {
		mainAtl: 'alt',
		indice: 24,
		atl: "'",
		// code: 221,
		code: 'Backquote',
		title: 'Atalho para adicionar " "',
		block: false,
		page: 3,
	},
	addBold: {
		mainAtl: 'ctrl',
		indice: 25,
		atl: 'B',
		// code: 221,
		code: 'KeyB',
		title: 'Adiciona negrito ao texto selectionado.',
		block: false,
		page: 4,
	},
	addItalic: {
		mainAtl: 'ctrl',
		indice: 26,
		atl: 'I',
		// code: 221,
		code: 'KeyI',
		title: 'Adiciona itálico ao texto selectionado.',
		block: false,
		page: 4,
	},
	addBookmark: {
		mainAtl: 'alt',
		indice: 27,
		atl: 'O',
		// code: 221,
		code: 'KeyO',
		title: 'Adiciona segmento aos bookmarks',
		block: false,
		page: 2,
	},
	navigateBetweenErrosUp: {
		mainAtl: 'ctrl',
		indice: 28,
		atl: 'arrowup',
		//code: 38,
		code: 'ArrowUp',
		title: 'Retroceder para segmento com erro ',
		block: false,
		page: 1,
	},
	navigateBetweenErrorsDown: {
		mainAtl: 'ctrl',
		indice: 29,
		atl: 'arrowdown',
		// code: 40,
		code: 'ArrowDown',
		title: 'Avançar para segmento com erro ',
		block: false,
		page: 1,
	},
	extractVideoFrame: {
		mainAtl: 'ctrl',
		indice: 30,
		atl: 'G',
		code: 'KeyG',
		title: 'Extrair texto do frame atual do vídeo',
		block: false,
		page: 3,
	},
};

export default config;
