<template>
	<!-- Two-way Data-Binding -->
	<div>
		<quill-editor
			ref="myQuillEditor"
			:content="content"
			:options="editorOption"
			@blur="onEditorBlur($event)"
			@focus="onEditorFocus($event)"
			@ready="onEditorReady($event)"
			@change="onEditorChange($event)"
		/>
	</div>

	<!-- Or manually control the data synchronization -->
</template>

<script>
export default {
	props: {
		layoutSelect: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			content: '',
			editorOption: {
				// Some Quill options...
			},
			toolbarShow: true,
			firstChange: true,
			quill: null,
			arrayFormatSave: null,
		};
	},
	computed: {
		editor() {
			return this.$refs.myQuillEditor.quill;
		},
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getAnnoucerPress() {
			return this.$store.getters['controller/getAnnoucerPress'];
		},
	},
	watch: {
		layoutSelect() {
			let textContainer = document.querySelector('.ql-editor');
			if (this.layoutSelect == 2) textContainer.style.padding = '1rem 20% 1rem 20%';
			else textContainer.style.padding = '1rem 10rem 1rem 10rem';
		},
		getAnnoucerPress(annoucerName) {
			if (annoucerName) {
				this.addAnnoucerToText(annoucerName);
				this.$store.dispatch('controller/setAnnoucer', null);
			}
		},
	},
	methods: {
		setToolbarPositon(lastSelection) {
			const toolbar = document.querySelector('.ql-toolbar-editor');
			let bounds = this.editor.getBounds(lastSelection);
			toolbar.style.left = bounds.left - 5 + 'px';
			toolbar.style.top = bounds.top - 34 + 'px';
		},
		createToolbar() {
			const toolbar =
				'<div class="ql-toolbar-editor">' + '<span>Última edição</span>' + '<div class="arrow"></div>' + '</div>';

			const editor = document.querySelector('.ql-container');
			editor.insertAdjacentHTML('beforeend', toolbar);
		},
		hideToolbar() {
			const toolbar = document.querySelector('.ql-toolbar-editor');
			toolbar.style.display = 'none';
			this.firstChange = false;
		},
		onEditorBlur() {},
		onEditorFocus() {},
		onEditorReady(quill) {
			if (!this.getIsTranslate) this.content = this.getTask.text;
			else this.content = this.getTask.translate_text;

			this.createToolbar();

			let editor = document.querySelector('.ql-editor');
			editor.addEventListener('scroll', () => {
				this.hideToolbar();
			});

			this.quill = quill;
		},
		onEditorChange({ quill, html }) {
			// update last update cursor position
			let lastSelection = quill.selection.lastRange == null ? this.getTask.last_selection : quill.getSelection().index;
			this.getTask.last_selection = lastSelection;

			// update text
			let task = this.getTask;
			if (!this.getIsTranslate) task.text = html;
			else task.translate_text = html;

			if (this.firstChange) {
				this.$nextTick(() => {
					this.editor.setSelection(lastSelection);
				});
				this.setToolbarPositon(lastSelection);
				this.firstChange = false;
			} else {
				this.hideToolbar();
			}

			// persist data
			this.$store.dispatch('firebase/updateSavedTime');
			this.$store.dispatch('firebase/updateTask', task);
		},
		addAnnoucerToText(annoucerName) {
			let range = this.quill.getSelection();
			let selection = range.index;
			let annoucer = `\n- ${annoucerName}: \n`;
			this.quill.insertText(selection, annoucer, 'bold', true);
		},
	},
};
</script>

<style lang="scss">
@import '../../app.scss';

$tooltip-color: rgb(236, 223, 36);

.quill-editor {
	position: relative;
	height: 100%;
	width: 100%;
}

.ql-toolbar {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	background-color: #ffff;
	height: 8%;
	max-height: 50px;
	width: 100%;
	margin: 0px;
	z-index: 3;
	position: absolute;
	.ql-formats {
		display: flex !important;
		align-items: flex-start !important;
		justify-content: center !important;
		flex-direction: row;
		margin: 2px !important;
	}
}

.ql-container {
	width: 100%;
	border: none !important;
	overflow-y: auto !important;
	padding-top: 50px !important;
}

.ql-editor {
	color: $font-color !important;
	padding: 3% 10% !important;
	box-sizing: border-box;
	background-color: $background;
	position: relative;
	padding-top: 9%;
	overflow-y: auto;
	p {
		font-size: 1rem !important;
	}
}

.ql-toolbar-editor {
	position: absolute;
	background-color: $tooltip-color;
	border: 1px solid rgb(0, 0, 0);
	top: 0;
	left: 0;
	height: 24px;
	width: 100px;
	z-index: 5;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	font-weight: bold;

	span {
		display: flex;
		justify-content: center;
		align-content: center;
		height: 100%;
		width: auto;
		padding-top: 8px;
	}

	.arrow {
		left: 0;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid $tooltip-color;
	}
}

@media (max-width: 1400px) {
	.ql-container {
		min-height: 90% !important;
		height: 90%;
		width: 100%;
		border: none !important;
	}
}
</style>
