import Vue from 'vue';
import Vuex from 'vuex';
import Controller from './modules/controller';
import Firebase from './modules/firabase';
import Video from './modules/video';
import Soundwave from './modules/soundwave';
import UndoRedo from './modules/undoRedo';
import Feedback from './modules/feedback';
import BatchSegment from './modules/batchSegments';

Vue.use(Vuex);

const state = {
	bookmarksActive: false,
	menuActive: false,
	removeSegmentModalActive: false,
	segmentToDelete: null,
	loadingScreen: true,
	audioView: false,
	findAndReplaceActive: false,
	loadingStages: 0,
	backupFlag: null,
	manualBackupFlag: null,
	lastAutomaticBackup: null,
	requestToSave: false,
	preferenceModalIsOpen: false,
	userPreferences: null,
};

const getters = {
	isBookmarksActive: (state) => state.bookmarksActive,
	isMenuActive: (state) => state.menuActive,
	isRemoveSegmentModalActive: (state) => state.removeSegmentModalActive,
	getSegmentToDelete: (state) => state.segmentToDelete,
	getLoadingScreen: (state) => state.loadingScreen,
	getAudioView: (state) => state.audioView,
	getFindAndReplace: (state) => state.findAndReplaceActive,
	getLoadingStages: (state) => state.loadingStages,
	getBackupFlag: (state) => state.backupFlag,
	getManualBackupFlag: (state) => state.manualBackupFlag,
	getLastAutomaticBackup: (state) => state.lastAutomaticBackup,
	getRequestToSave: (state) => state.requestToSave,
	getPreferenceModalIsOpen: (state) => state.preferenceModalIsOpen,
	getUserPreferences: (state) => state.userPreferences,
};

const mutations = {
	setBookmarksActive(state, payload) {
		state.bookmarksActive = payload;
	},
	setMenuActive(state, payload) {
		state.menuActive = payload;
	},
	setRemoveSegmentModalActive(state, payload) {
		state.removeSegmentModalActive = payload;
	},
	setSegmentToDelete(state, payload) {
		state.segmentToDelete = payload;
	},
	setLoadingScreen(state, payload) {
		state.loadingScreen = payload;
	},
	setAudioView(state, payload) {
		state.audioView = payload;
	},
	setFindAndReplace(state) {
		state.findAndReplaceActive = !state.findAndReplaceActive;
	},
	setLoadingStages(state, payload) {
		state.loadingStages = payload;
	},
	updateBackups(state) {
		state.backupFlag = Date.now();
	},
	setLastAutomaticBackup(state, time) {
		state.lastAutomaticBackup = time;
	},
	addManualBackup(state) {
		state.manualBackupFlag = Date.now();
	},
	setRequestToSave(state, status) {
		state.requestToSave = status;
	},
	setPreferenceModalIsOpen(state) {
		state.preferenceModalIsOpen = !state.preferenceModalIsOpen;
	},
	setUserPreferences(state, preferences) {
		state.userPreferences = preferences;
	},
};

const actions = {
	setBookmarksActive({ commit }, payload) {
		commit('setBookmarksActive', payload);
	},
	setMenuActive({ commit }, payload) {
		commit('setMenuActive', payload);
	},
	setRemoveSegmentModalActive({ commit }, { status, payload }) {
		commit('setRemoveSegmentModalActive', status);
		commit('setSegmentToDelete', payload);
	},
	setLoadingScreen({ commit }, payload) {
		commit('setLoadingScreen', payload);
	},
	setAudioView({ commit }, payload) {
		commit('setAudioView', payload);
	},
	setFindAndReplace({ commit }, payload) {
		commit('setFindAndReplace', payload);
	},
	setLoadingStage({ commit }, payload) {
		commit('setLoadingStages', payload);
	},
	updateBackups({ commit }) {
		commit('updateBackups');
	},
	setLastAutomaticBackup({ commit }, time) {
		commit('setLastAutomaticBackup', time);
	},
	addManualBackup({ commit }) {
		commit('addManualBackup');
	},
	setRequestToSave({ commit }, state) {
		commit('setRequestToSave', state);
	},
	setPreferenceModalIsOpen({ commit }) {
		commit('setPreferenceModalIsOpen');
	},
};

export default new Vuex.Store({
	state,
	mutations,
	actions,
	getters,
	modules: {
		controller: Controller,
		firebase: Firebase,
		video: Video,
		soundwave: Soundwave,
		undoRedo: UndoRedo,
		feedback: Feedback,
		batchSegment: BatchSegment,
	},
});
