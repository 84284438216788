let steps = [
	{
		stepId: 0,
		title: 'Botão Tutorial',
		text: 'Bem-vindo ao tutorial do Editor!\nAqui você encontrará informações sobre como utilizar a plataforma.\n\nPara começar, clique em "Próximo"',
		divId: '[id="btn-tutorial"]',
		tooltip: 'bottom',
	},
	{
		stepId: 1,
		title: 'Gerenciador de segmentos',
		text: 'Este é o gerenciador de segmentos.\n Aqui você pode adicionar, excluir e visualizar informações sobre os segmentos.',
		divId: '[id="segment-manager"]',
		tooltip: 'right',
		function: (store) => {
			let scrollArea = document.getElementById('scrollArea');
			scrollArea.scrollTop = 0;
			store.dispatch('controller/setCurrentIndex', 1);
			store.dispatch('video/replaySegment', null, { root: true });
		},
	},
	{
		stepId: 2,
		title: 'Segmento',
		text: 'Este é um segmento. Segmentos contém todas as informações de uma parte de legenda.\nDentro desta área você encontrará o tempo de início e fim do segmento, bem como o texto da legenda e opções de edição.\n Para selecionar um segmento para edição basta clicar sobre ele!',
		divId: '[data-pk="1"]',
		tooltip: 'right',
	},
	{
		stepId: 3,
		title: 'Segmento',
		text: 'Quando um segmento possuir erros, será exibida uma marcação embaixo do botão de remover segmento, essa marcação pode ser vermelha, indicando um erro crítico, ou amarela, indicando um aviso.',
		divId: '[class="selectedRow"]',
		tooltip: 'right',
	},
	{
		stepId: 4,
		title: 'Filtro de segmentos',
		text: 'Clicando sobre este ícone você terá acesso a diversas opções de filtros.\n Você pode filtrar por segmentos já salvos, não salvos, confirmados, não confirmados e bookmarks.',
		divId: '[id="filterButton"]',
		tooltip: 'bottom',
	},
	{
		stepId: 5,
		title: 'Editor de segmentos',
		text: 'Ao clicar sobre um segmento no Gerenciador de Segmentos, você poderá visualizar todas as informações dele, assim como realizar edições sobre o texto',
		divId: '[id="segmentEditor"]',
		tooltip: 'auto',
	},
	{
		stepId: 6,
		title: 'Id do segmento',
		text: 'Este é o identificador do segmento selecionado.\nEste id é único e será utilizado para identificar o segmento.',
		divId: '[id="segmento-id"]',
		tooltip: 'auto',
	},
	{
		stepId: 7,
		title: 'Bookmark',
		text: 'Ao clicar sobre este ícone o segmento atual terá um "bookmark".\n Você pode encontrar todos os Bookmarks ativo ao selecionar a opção "Bookmarks" no filtro do Gerenciador de segmentos.',
		divId: '[id="bookmark"]',
		tooltip: 'auto',
	},
	{
		stepId: 8,
		title: 'Confirmar segmento',
		text: 'Ao clicar sobre este ícone, o segmento atual será marcado como confirmado.\n Você pode encontrar todos os segmentos confirmados selecionando a opção "Confirmados" no filtro do Gerenciador de segmentos.\nSegmentos só podem ser confirmados se não possuirem erros!',
		divId: '[id="confirm-button"]',
		tooltip: 'auto',
	},
	{
		stepId: 9,
		title: 'Tempo inicial do segmento',
		text: 'Aqui você pode visualizar/editar o tempo inicial do segmento.\nEste tempo é o tempo inicial que o segmento será exibido no vídeo.',
		divId: '[id="time-init"]',
		tooltip: 'auto',
	},
	{
		stepId: 10,
		title: 'Tempo final do segmento',
		text: 'Aqui você pode visualizar/editar o tempo final do segmento.\nEste tempo é o tempo final que o segmento será exibido no vídeo.',
		divId: '[id="time-end"]',
		tooltip: 'auto',
	},
	{
		stepId: 11,
		title: 'Edição do segmento',
		text: 'Nesta área você pode editar o conteúdo do segmento.\nO conteúdo do segmento é o texto que será exibido na legenda.\n Cada conteúdo do segmento pode ter somente duas linhas e existem para transcrição e/ou tradução.',
		divId: '[class="text-area"]',
		tooltip: 'auto',
	},
	{
		stepId: 12,
		title: 'CPS do segmento',
		text: 'O CPS indica a quantidade de caracteres por segundo do segmento.\nO CPS recomendado é 17, mas é tolerado até 24. Esse valor varia quando você altera a duração do segmento e/ou o texto do segmento.\nQuando o CPS for acima do tolerado, esse campo será exibido em vermelho e uma mensagem de erro será exibida.',
		divId: '[id="cps-box"]',
		tooltip: 'auto',
	},
	{
		stepId: 13,
		title: 'Caracteres por linha',
		text: 'Aqui você pode ver a quantidade de caracteres por linha do segmento. Tolera-se até 38 caracteres por linha. Quando esse valor for ultrapassado, esse campo será exibido em vermelho e uma mensagem de erro será exibida.',
		divId: '[id="caracters-per-line"]',
		tooltip: 'auto',
	},
	{
		stepId: 14,
		title: 'Soundwave',
		text: 'Essa área é utilizada para visualização da soundwave. Na soundwave você pode visualizar todos os segmentos e os tempos em que se encontram, além das ondas sonoras para sincronização dos tempos inicias e finais.',
		divId: '[id="soundwave"]',
		tooltip: 'top',
	},
	{
		stepId: 15,
		title: 'Timeline',
		text: 'Aqui você pode visualizar o tempo referente ao vídeo em que os segmentos estão alocados.',
		divId: '[id="timeline"]',
		tooltip: 'top',
	},
	{
		stepId: 16,
		title: 'Segmento (soudwave)',
		text: 'Este é um segmento da soundwave. Segmentos da soundwave correspondem aos segmentos do "Gerenciador de Segmentos", porém aqui você pode editar de maneira facilitada os tempos inicias e finais. O segmento atual selecionado será exibido na cor roxa.',
		divId: '[class="wavesurfer-region selectedSoundwaveSegment"]',
		tooltip: 'top',
	},
	{
		stepId: 17,
		title: 'Segmento (soudwave)',
		text: 'Mantendo pressionado o botão esquerdo do mouse sobre o segmento e arrastando-o para as laterais, você pode alterar o tempo inicial e final simultaneamente, sincronizando da maneira que desejar de acordo com as ondas sonoras exibidas.',
		divId: '[class="wavesurfer-region selectedSoundwaveSegment"]',
		tooltip: 'top',
	},
	{
		stepId: 18,
		title: 'Barra de tempo inicial',
		text: 'Esta é a barra de edição do tempo inicial do segmento. Clique e arraste para definir um novo tempo inicial do segmento.',
		divId: '.selectedSoundwaveSegment > .wavesurfer-handle-start',
		tooltip: 'top',
	},
	{
		stepId: 19,
		title: 'Barra de tempo final',
		text: 'Esta é a barra de edição do tempo final do segmento. Clique e arraste para definir um novo tempo final do segmento.',
		divId: '.selectedSoundwaveSegment > .wavesurfer-handle-end',
		tooltip: 'top',
	},
	{
		stepId: 20,
		title: 'Modo sincronia',
		text: 'Clicando sobre esse botão você pode ativar o modo sincronia. Com o modo sincronia ativado você será direcionado sempre para o segmento referente ao tempo do vídeo que está sendo executado.\n Para saber se o modo sincronia está ativo é só verificar se o círculo está vermelho nesta área. (Tecla de atalho padrão: ALT+I)',
		divId: '[id="synchronyMode-button"]',
		tooltip: 'top',
	},
	{
		stepId: 21,
		title: 'Salvamento automático',
		text: 'Aqui você pode verificar o horário que seu trabalho foi salvo automaticamente pela última vez.',
		divId: '[id="lastUpdateTime"]',
		tooltip: 'bottom',
		function: (store) => {
			store.dispatch('setMenuActive', true, { root: true });
		},
	},
	{
		stepId: 22,
		title: 'Menu',
		text: 'Clicando sobre o botão menu você poderá encontrar diversas opções adicionais.',
		divId: '[id="menuSuspend-button"]',
		tooltip: 'top',
	},
	{
		stepId: 23,
		title: 'Layout',
		text: 'Clique sobre este ícone para selecionar um layout diferente.',
		divId: '[id="layout-button"]',
		tooltip: 'left',
	},
	{
		stepId: 24,
		title: 'Configurações',
		text: 'Clique sobre este ícone para abrir o menu de configurações do Editor.',
		divId: '[id="config-button"]',
		tooltip: 'left',
		function: (store) => {
			store.dispatch('setMenuActive', true, { root: true });
		},
	},
	{
		stepId: 25,
		title: 'Finalizar revisão',
		text: 'Ao terminar de realizar todas as revisões dos segmentos e confirmá-los, clique neste botão para finalizar a revisão. Você só poderá finalizar se todos os segmentos estiverem sem erros!',
		divId: '[id="finalizeRevisionButton"]',
		tooltip: 'bottom',
		function: (store) => {
			store.dispatch('setMenuActive', false, { root: true });
		},
	},
];

export default steps;
