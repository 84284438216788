<template>
	<div class="filter-button" @click="showFilter = !showFilter">
		<i id="filterButton" class="fa fa-filter"></i>
		<span>Filtros</span>
		<b-tooltip target="filterButton" :show="showFilter" triggers="" placement="bottom">
			<div class="filter-area p-0">
				<span>
					<h5>Filtros</h5>
				</span>
				<button class="fa fa-times-circle" @click="showFilter = !showFilter"></button>
				<div class="labels-filter">
					<b-form-checkbox v-model="filter.all" @change="setAllSelectFilter">Todos segmentos</b-form-checkbox>
					<div class="item-filter">
						<b-form-checkbox v-model="filter.confirmed" @change="removeAllSelectedFilter"
							>Segmentos confirmados</b-form-checkbox
						>
						<b-form-checkbox v-model="filter.notConfirmed" @change="removeAllSelectedFilter"
							>Segmentos não confirmados</b-form-checkbox
						>
						<b-form-checkbox v-model="filter.withErrors" @change="removeAllSelectedFilter"
							>Segmentos com erros</b-form-checkbox
						>
						<b-form-checkbox v-model="filter.bookmarks" @change="removeAllSelectedFilter">Bookmarks</b-form-checkbox>
					</div>
				</div>

				<div class="buttons-filter">
					<button class="danger" @click="resetFilter">Limpar</button>
					<button class="success" @click="showFilter = false">Aplicar</button>
				</div>
			</div>
		</b-tooltip>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showFilter: false,
			filter: {
				autoApply: true,
				all: true,
				confirmed: true,
				notConfirmed: true,
				withErrors: true,
				bookmarks: true,
			},
		};
	},
	computed: {
		isBookmarksActive() {
			return this.$store.getters['isBookmarksActive'];
		},
	},
	watch: {
		isBookmarksActive() {
			if (this.isBookmarksActive) {
				this.resetFilter();
			}
		},
	},
	methods: {
		setAllSelectFilter() {
			if (!this.filter.all) {
				this.filter.all = false;
				this.filter.confirmed = false;
				this.filter.notConfirmed = false;
				this.filter.withErrors = false;
				this.filter.bookmarks = false;
			} else {
				this.filter.all = true;
				this.filter.confirmed = true;
				this.filter.notConfirmed = true;
				this.filter.withErrors = true;
				this.filter.bookmarks = true;
			}
			this.$emit('changeFilter', this.filter);
		},
		resetFilter() {
			this.filter.all = true;
			this.filter.confirmed = true;
			this.filter.notConfirmed = true;
			this.filter.withErrors = true;
			this.filter.bookmarks = true;
			this.$emit('changeFilter', this.filter);
		},
		removeAllSelectedFilter() {
			this.filter.all = false;
			this.$emit('changeFilter', this.filter);
		},
	},
};
</script>

<style lang="scss" scoped>
$background-secondary: #2e2e2e;
$color-auxiliary: rgb(148, 70, 233);

.filter-button {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	position: relative;
	background-color: $background-secondary;
	border-radius: 5px;
	padding: 0px 10px;

	i {
		color: $color-auxiliary;
		margin-top: 3px;
	}

	&:hover {
		transform: scale(1.1);
	}
}

.filter-area,
.tooltip-inner {
	width: auto;
	height: auto;
}
</style>
