const state = {
	isOpen: false,
	replay: 0,
	rewindForward: 0,
	seeked: 0,
	regionToDelete: null,
	regionToAdd: null,
};

const getters = {
	getIsOpen(state) {
		return state.isOpen;
	},
	getReplay(state) {
		return state.replay;
	},
	getRewindForward(state) {
		return state.rewindForward;
	},
	getSeeked(state) {
		return state.seeked;
	},
	getRegionToAdd(state) {
		return state.regionToAdd;
	},
	getRegionToDelete(state) {
		return state.regionToDelete;
	},
};

const mutations = {
	open: (state) => (state.isOpen = !state.isOpen),
	setReplay: (state, time) => (state.replay = time),
	setRewindForward: (state, time) => (state.rewindForward = time),
	setSeeked: (state, time) => (state.seeked = time),
	setRegionToAdd: (state, segment) => (state.regionToAdd = segment),
	setRegionToDelete: (state, segment) => (state.regionToDelete = segment),
};

const actions = {
	open({ commit }) {
		commit('open');
	},
	replay({ commit }, time) {
		commit('setReplay', time);
	},
	rewindForward({ commit }, time) {
		commit('setRewindForward', time);
	},
	seeked({ commit }, time) {
		commit('setSeeked', time);
	},
	addRegion({ commit }, segment) {
		commit('setRegionToAdd', segment);
	},
	removeRegion({ commit }, segment) {
		commit('setRegionToDelete', segment);
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
	namespaced: true,
};
