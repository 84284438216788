<template>
	<div class="segment-batch-list">
		<!-- <div style="display: flex"> -->
		<div class="flex-center my-2">
			<div style="flex-grow: 1; text-align: center">
				<button class="format-button bold" @click="setBatchBold">Negrito</button>
				<button class="format-button italic" @click="setBatchItalic">Itálico</button>
				<button class="format-button underline" @click="setBatchUnderline">Sublinhado</button>
			</div>
			<i class="fas fa-times-circle" style="font-size: large; cursor: pointer" @click="clearSegmentBatchList"></i>
		</div>

		<!-- </div> -->
		<b-table class="segment-table" striped :items="getSegmentBatchList" :fields="fields" small>
			<template #cell(start)="data">
				{{ timeFormatter(data.item.start) }}
			</template>
			<template #cell(end)="data">
				{{ timeFormatter(data.item.end) }}
			</template>
			<template #cell(text)="data">
				<span v-if="getIsTranslate">
					{{ data.item.translate_text }}
				</span>
				<span v-else>
					{{ data.item.text }}
				</span>
			</template>
		</b-table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
			fields: [
				{
					key: 'id',
					label: 'Índice',
				},
				{
					key: 'start',
					label: 'Tempo Inicial',
				},
				{
					key: 'end',
					label: 'Tempo Final',
				},
				{
					key: 'text',
					label: 'Texto',
				},
			],
		};
	},
	computed: {
		...mapGetters({
			getSegmentBatchList: 'batchSegment/getBatchList',
			getIsTranslate: 'firebase/getIsTranslate',
		}),
	},
	watch: {},
	methods: {
		timeFormatter(ms) {
			// 1- Convert to seconds:
			let seconds = (ms / 1000).toFixed();
			// 2- Extract hours:
			let hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
			if (hours < 9) hours = '0' + hours;

			seconds = seconds % 3600; // seconds remaining after extracting hours
			// 3- Extract minutes:
			let minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
			if (minutes < 9) minutes = '0' + minutes;

			// 4- Keep only seconds not extracted to minutes:
			seconds = seconds % 60;
			if (seconds < 9) seconds = '0' + seconds;

			return hours + ':' + minutes + ':' + seconds;
		},

		setBatchItalic() {
			this.$store.dispatch('batchSegment/setBatchItalic');
		},
		setBatchBold() {
			this.$store.dispatch('batchSegment/setBatchBold');
		},
		setBatchUnderline() {
			this.$store.dispatch('batchSegment/setBatchUnderline');
		},
		clearSegmentBatchList() {
			this.$store.commit('batchSegment/clearBatchList');
		},
	},
};
</script>

<style lang="scss" scoped>
.segment-batch-list {
	display: flex;
	height: auto;
	position: absolute;
	flex-direction: column;
	width: 70%;
	min-height: 66%;
	max-height: 66%;
	left: 30%;
	bottom: 0;
	z-index: 4;
	background-color: rgb(66, 66, 66);
	border: 3px solid #1e1e1e;
	border-radius: 5px;
	font-size: small;
	.segment-table {
		color: white;
	}
	overflow: scroll;
}
.flex-center {
	display: flex;
	flex-wrap: nowrap;
	align-items: center; /* vertical align */
}
.format-button {
	padding: 5px 10px;
	margin-right: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.format-button:hover {
	background-color: #f0f0f0;
}

.text {
	font-size: 16px;
}

.bold {
	font-weight: bold;
}

.italic {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}
</style>
