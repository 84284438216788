<template>
	<div>
		<div id="segmentEditor" :class="getIsTranslate ? 'grid-segment-editor' : 'grid-segment-editor-2'">
			<!-- Start Info area -->
			<InfoArea class="info-area" :segment="segment" />
			<!-- End Info area -->

			<!-- Start Segments Controllers -->
			<div class="time-area">
				<SegmentTimeEditor
					:start-time="parseInt(segment.start)"
					:end-time="parseInt(segment.end)"
					@changeTime="updateTime"
				/>
			</div>
			<!-- End Segments Controllers-->

			<!-- Start Transcription TextArea -->
			<TopbarTools
				v-if="!getIsTranslate"
				class="top-bar-text-area"
				:segment="segment"
				text-fild-to-verify="text"
				@updateText="updateTextSegment"
			/>
			<div class="text-area">
				<audioDescriptonPopup
					v-if="getAudioDescriptionPopup && !getIsTranslate"
					:current-segment="getCurrentSegment"
					:full-text="segment.text"
					@textUpdate="updateTextSegment"
				/>
				<b-form-textarea
					id="text"
					ref="text"
					v-model="text"
					:class="getIsTranslate ? 'disable-textarea' : ''"
					placeholder=""
					rows="2"
					max-rows="2"
					:disabled="getIsTranslate || getInspectMode"
					spellcheck="true"
					@update="updateTextSegment"
					@keydown.enter="enterPrevent"
					@keydown="handleKeyDown"
				></b-form-textarea>

				<CaracteresPerLine v-if="!getIsTranslate" :text="segment.text" class-name="cps-per-line" />
			</div>
			<!-- End Transcription TextArea -->

			<!-- Start Translate TextArea -->
			<TopbarTools
				v-if="getIsTranslate"
				class="top-bar-translate-area"
				:segment="segment"
				text-fild-to-verify="translate-text"
				@updateText="updateTextSegment"
			/>
			<div v-if="getIsTranslate" class="translate-area">
				<audioDescriptonPopup
					v-if="getAudioDescriptionPopup && getIsTranslate"
					:current-segment="getCurrentSegment"
					:full-text="segment.translate_text"
					@textUpdate="updateTextSegment"
				/>
				<b-form-textarea
					id="textTranslate"
					ref="textTranslate"
					v-model="translate_text"
					:class="!getIsTranslate ? 'disable-textarea' : ''"
					placeholder=""
					rows="2"
					max-rows="2"
					:disabled="!getIsTranslate || getInspectMode"
					spellcheck="true"
					@input="updateTextSegment"
					@keydown.enter="enterPrevent"
					@keydown="handleKeyDown"
				></b-form-textarea>

				<CaracteresPerLine v-if="getIsTranslate" :text="segment.translate_text" class-name="cps-per-line" />
			</div>
			<!-- end Translate TextArea -->
		</div>
	</div>
</template>

<script>
import InfoArea from './InfoArea.vue';
import TopbarTools from './TopbarTools.vue';
import SegmentTimeEditor from './SegmentTimeEditor';
import TextTools from '../../utils/textTools';
import SegmentTools from '../../utils/segmentTools';
import CaracteresPerLine from './CaractersPerLineBox.vue';
import audioDescriptonPopup from './AudioDescriptionPopup.vue';
import _ from 'lodash';

export default {
	components: {
		SegmentTimeEditor,
		TopbarTools,
		CaracteresPerLine,
		InfoArea,
		audioDescriptonPopup,
	},
	data() {
		return {
			text: '',
			translate_text: '',
			segment: {
				text: '',
				translate_text: '',
			},
			segmentActualState: null,
			selectionStartText: 0,
			selectionEndText: 0,
			selectionStartTextTranslate: 0,
			selectionEndTextTranslate: 0,
			cursorPosition: null,
			audioDescriptionText: null,
		};
	},
	computed: {
		getCurrentSegment() {
			return this.$store.getters['controller/getCurrentSegment'];
		},
		getBeforeSegment() {
			return this.$store.getters['controller/getBeforeSegment'];
		},
		getNextSegment() {
			return this.$store.getters['controller/getNextSegment'];
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getIsCC() {
			return this.$store.getters['firebase/getIsCC'];
		},
		hasCriticalError() {
			if (!this.segment.errors) return false;
			if (this.getIsTranslate) {
				return !this.segment.errors.translation.isCritical; // return false if isCritical because component need to be false to show error
			} else {
				return !this.segment.errors.transcription.isCritical;
			}
		},
		getUpdateFlag() {
			return this.$store.getters['firebase/getUpdateFlag'];
		},
		getAudioDescriptionPopup() {
			return this.$store.getters['controller/getAudioDescriptionPopup'];
		},
		getAnnoucerPress() {
			return this.$store.getters['controller/getAnnoucerPress'];
		},
		findAndReplaceIsOpen() {
			return this.$store.getters['getFindAndReplace'];
		},
		getShortcuteEvent: {
			get() {
				return this.$store.getters['controller/getShortcuteEvent'];
			},
			deep: true,
		},
		getInspectMode() {
			return this.$store.getters['firebase/getInspectMode'];
		},
	},
	watch: {
		getCurrentSegment(newValue) {
			// Set segment in SegmentEditor
			if (newValue === null || newValue === undefined) {
				this.segment = {
					text: '',
					translate_text: '',
					startTime: 0,
					endTime: 0,
				};
			} else {
				this.segment = newValue;
				this.text = newValue.text;
				this.translate_text = newValue.translate_text;
			}

			// Save segment to update reference
			this.segmentActualState = { ...newValue };
		},
		getUpdateFlag() {
			this.text = this.segment.text;
			this.translate_text = this.segment.translate_text;
		},
		getAnnoucerPress(annoucerName) {
			if (annoucerName) {
				this.addAnnoucerToSegment(annoucerName);
				this.$store.dispatch('controller/setAnnoucer', null);
			}
		},
		getShortcuteEvent(event) {
			if (this.getInspectMode) return;
			if (event.eventKey == 'splitSegment') {
				if (this.getIsTranslate) this.splitTextTranslateInNewSegment();
				else this.splitTextInNewSegment();
			}
			if (event.eventKey == 'splitAndAddInNextSegment') {
				if (this.getIsTranslate) this.splitTextTranslateAndAddInNextSegment();
				else this.splitTextAndAddInNextSegment();
			}
			if (event.eventKey == 'splitAndAddInNextSegmentWithTime') {
				if (this.getIsTranslate) this.splitTextTranslateAndAddInNextSegmentWithTime();
				else this.splitTextAndAddInNextSegmentWithTime();
			}
			if (event.eventKey == 'cutFirstWorld') {
				this.cutFirstWordTextSegment();
			}
			if (event.eventKey == 'cutFirstWorldWithTime') {
				this.cutFirstWordTextSegmentWithTime();
			}
			if (event.eventKey == 'invertExclamation') {
				if (!this.findAndReplaceIsOpen) this.addInvertExclamation();
			}
			if (event.eventKey == 'invertInterrogation') {
				if (!this.findAndReplaceIsOpen) this.addInvertInterrogation();
			}
			if (event.eventKey == 'addMusicSymbol') {
				this.addMusicSymbol();
			}
			if (event.eventKey == 'addSoundDescription') {
				this.addAudioDescription();
			}
		},
	},
	mounted() {
		this.$store.dispatch('setLoadingStage', 4);
	},
	methods: {
		updateSegment: _.debounce(function (segment = null) {
			if (segment == null) {
				this.$store.dispatch('firebase/updateSegment', {
					segment: this.segment,
				});
				this.$store.dispatch('firebase/setSavedFalse', { segment: this.segment });
			} else {
				this.$store.dispatch('firebase/updateSegment', { segment });
				this.$store.dispatch('firebase/setSavedFalse', { segment });
			}
		}, 200),
		updateTextSegment(text, cursorPosition = null) {
			// IMPORNTANT
			let textSeg = SegmentTools.clearSegmentText({ text: text, translate_text: text });
			if (this.getIsTranslate) {
				this.saveCursorPosition(cursorPosition || this.$refs['textTranslate'].selectionStart);
				this.segment.translate_text = textSeg.translate_text;
				this.translate_text = textSeg.translate_text;
			} else {
				this.saveCursorPosition(cursorPosition || this.$refs['text'].selectionStart);
				this.segment.text = textSeg.text;
				this.text = textSeg.text;
			}
			this.updateSegment();
			this.$nextTick(() => {
				this.setCursorPosition();
			});
		},
		async setCursorPosition() {
			let position;
			let textArea;
			if (this.getIsTranslate) {
				position = this.cursorPosition != null ? this.cursorPosition : this.segment.translate_text.length;
				this.$refs.textTranslate.focus();
				textArea = this.$refs['textTranslate'];
				await textArea.setSelectionRange(position, position);
			} else {
				this.$refs.text.focus();
				position = this.cursorPosition != null ? this.cursorPosition : this.segment.text.length;

				textArea = this.$refs['text'];
				await textArea.setSelectionRange(position, position);
			}
		},
		saveCursorPosition(position) {
			this.cursorPosition = position;
		},
		updateTime(time) {
			this.segment.start = time.startTime;
			this.segment.end = time.endTime;
			this.updateSegment();
		},
		enterPrevent(event) {
			let text = event.target.value;

			// Verify if ctrl or alt key is pressed
			let isCtrl = event.ctrlKey;
			let isAlt = event.altKey;
			if (isCtrl || isAlt || this.getAudioDescriptionPopup) {
				return;
			}

			if (text.split('\n').length > 1) {
				event.preventDefault();
				text = text.replace('\n', ' ');
				const selectionCut = event.target.selectionStart;
				const { firstText, secondText } = TextTools.splitTextBySelectionStart(text, selectionCut);
				text = `${firstText || ''}\n${secondText || ''}`;

				const formatText = TextTools.enforceSigleLineBreak(text);
				this.updateTextSegment(formatText);
				return;
			}
		},
		splitTextInNewSegment() {
			try {
				const text = this.$refs['text'].value;
				const selectionCut = this.$refs['text'].selectionStart;
				const { firstText, secondText } = TextTools.splitTextBySelectionStart(text, selectionCut, true);

				// Break segment in two and calculate the time
				const duration = this.segment.end - this.segment.start;
				const newEndTimeSegment = (duration / text.length) * selectionCut;

				const newEndTimeNewSegment = this.segment.end;
				const newStarTimeNewSegment = this.segment.start + newEndTimeSegment;

				this.segment.text = firstText;
				this.text = firstText;
				this.segment.end = newStarTimeNewSegment;

				// When addSegment, add undoRedo automatic
				this.$store.dispatch('controller/addSegment', {
					text: secondText,
					isUndoRedo: true, // Precisa ser true para evitar erro no undoRedo
					startTimeReference: newStarTimeNewSegment,
					endTimeReference: newEndTimeNewSegment,
				});

				this.updateSegment();
			} catch (err) {
				console.error('Error [SegmentEditor/splitText]', err);
			}
		},
		splitTextTranslateInNewSegment() {
			try {
				const translateText = this.$refs['textTranslate'].value;
				const selectionCut = this.$refs['textTranslate'].selectionStart;
				const { firstText, secondText } = TextTools.splitTextBySelectionStart(translateText, selectionCut, true);

				// Break segment in two and calculate the time
				const duration = this.segment.end - this.segment.start;
				const newEndTimeSegment = (duration / translateText.length) * selectionCut;

				const newEndTimeNewSegment = this.segment.end;
				const newStarTimeNewSegment = this.segment.start + newEndTimeSegment;

				this.segment.translate_text = firstText;
				this.translate_text = firstText;
				this.segment.end = newStarTimeNewSegment;

				this.$store.dispatch('controller/addSegment', {
					translate_text: secondText,
					isUndoRedo: true, // Isso evita de adicionar o undoRedo na criação.
					startTimeReference: newStarTimeNewSegment,
					endTimeReference: newEndTimeNewSegment,
				});

				this.updateSegment();
			} catch (err) {
				console.error('Error [SegmentEditor/splitTextTranslate]', err);
			}
		},
		splitTextAndAddInNextSegment() {
			try {
				const text = this.$refs['text'].value;
				const selectionCut = this.$refs['text'].selectionStart;
				const { firstText, secondText } = TextTools.splitTextBySelectionStart(text, selectionCut, true);

				let currentSegment = this.getCurrentSegment;
				let nextSegment = this.getNextSegment;
				if (nextSegment == undefined)
					throw 'Error [controller/splitTextAndAddInNextSegment]: Próximo segmento não encontrado.';

				currentSegment.text = firstText;
				nextSegment.text = secondText + ' ' + nextSegment.text;

				this.updateSegment(currentSegment);
				this.updateSegment(nextSegment);
			} catch (err) {
				console.error('Error [SegmentEditor/splitTextAndAddInNextSegment]', err);
			}
		},
		splitTextAndAddInNextSegmentWithTime() {
			try {
				const text = this.$refs['text'].value;
				const selectionCut = this.$refs['text'].selectionStart;
				const { firstText, secondText } = TextTools.splitTextBySelectionStart(text, selectionCut, true);

				let currentSegment = this.getCurrentSegment;
				let nextSegment = this.getNextSegment;
				if (nextSegment == undefined)
					throw 'Error [controller/splitTextAndAddInNextSegment]: Próximo segmento não encontrado.';

				// Recalcula os tempos de acordo com o novo texto
				const durationCurrentSegment = this.segment.end - this.segment.start;
				const newEndTimeCurrentSegment = (durationCurrentSegment * selectionCut) / text.length;

				currentSegment.text = firstText;
				this.text = firstText;
				currentSegment.end = currentSegment.start + newEndTimeCurrentSegment;

				nextSegment.text = secondText + ' ' + nextSegment.text;
				nextSegment.start = currentSegment.end;

				this.updateSegment(currentSegment);
				this.updateSegment(nextSegment);
			} catch (err) {
				console.error('Error [SegmentEditor/splitTextAndAddInNextSegment]', err);
			}
		},
		splitTextTranslateAndAddInNextSegment() {
			try {
				const translateText = this.$refs['textTranslate'].value;
				const selectionCut = this.$refs['textTranslate'].selectionStart;
				const { firstText, secondText } = TextTools.splitTextBySelectionStart(translateText, selectionCut, true);

				let currentSegment = this.getCurrentSegment;
				let nextSegment = this.getNextSegment;
				if (nextSegment == undefined)
					throw 'Error [controller/splitTextTranslateAndAddInNextSegment]: Próximo segmento não encontrado.';

				currentSegment.translate_text = firstText;
				nextSegment.translate_text = secondText + ' ' + nextSegment.translate_text;

				this.updateSegment(currentSegment);
				this.updateSegment(nextSegment);
			} catch (err) {
				console.error('Error [SegmentEditor/splitTextTranslateAndAddInNextSegment]', err);
			}
		},
		splitTextTranslateAndAddInNextSegmentWithTime() {
			try {
				const translateText = this.$refs['textTranslate'].value;
				const selectionCut = this.$refs['textTranslate'].selectionStart;
				const { firstText, secondText } = TextTools.splitTextBySelectionStart(translateText, selectionCut, true);

				let currentSegment = this.getCurrentSegment;
				let nextSegment = this.getNextSegment;
				if (nextSegment == undefined)
					throw 'Error [controller/splitTextTranslateAndAddInNextSegment]: Próximo segmento não encontrado.';

				// Recalcula os tempos de acordo com o novo texto
				const durationCurrentSegment = this.segment.end - this.segment.start;
				const newEndTimeCurrentSegment = (durationCurrentSegment * selectionCut) / translateText.length;

				currentSegment.translate_text = firstText;
				this.translate_text = firstText;
				currentSegment.end = currentSegment.start + newEndTimeCurrentSegment;

				nextSegment.translate_text = secondText + ' ' + nextSegment.translate_text;
				nextSegment.start = currentSegment.end;

				this.updateSegment(currentSegment);
				this.updateSegment(nextSegment);
			} catch (err) {
				console.error('Error [SegmentEditor/splitTextTranslateAndAddInNextSegment]', err);
			}
		},
		cutFirstWordTextSegmentWithTime() {
			try {
				let currentSegment = this.getCurrentSegment;
				let nextSegment = this.getNextSegment;
				if (nextSegment == undefined) throw 'Error [controller/cutFirstWorld]: Próximo segmento não encontrado.';

				// segmentTools function
				let { currentSegmentUpdated, nextSegmentUpdated } = SegmentTools.cutFirstWorldAndPasteOnActualSegmentWithTime(
					currentSegment,
					nextSegment,
					this.getIsTranslate
				);

				this.updateSegment(currentSegmentUpdated);
				this.updateSegment(nextSegmentUpdated);
			} catch (err) {
				console.error('Error [SegmentEditor/cutFirstWordTextSegmentWithTime]', err);
			}
		},
		cutFirstWordTextSegment() {
			try {
				let currentSegment = this.getCurrentSegment;
				let nextSegment = this.getNextSegment;
				if (nextSegment == undefined) throw 'Error [controller/cutFirstWorld]: Próximo segmento não encontrado.';

				let { currentSegmentUpdated, nextSegmentUpdated } = SegmentTools.cutFirstWorldAndPasteOnActualSegment(
					currentSegment,
					nextSegment,
					this.getIsTranslate
				);

				this.updateSegment(currentSegmentUpdated);
				this.updateSegment(nextSegmentUpdated);
			} catch (err) {
				console.error('Error [SegmentEditor/cutFirstWordTextSegment]', err);
			}
		},
		addInvertExclamation() {
			try {
				let text;
				let selectionCut;
				if (this.getIsTranslate) {
					text = this.$refs['textTranslate'].value;
					selectionCut = this.$refs['textTranslate'].selectionStart;
				} else {
					text = this.$refs['text'].value;
					selectionCut = this.$refs['text'].selectionStart;
				}

				// add invert exclamation in selectionCut position
				text = text.substring(0, selectionCut) + '¡' + text.substring(selectionCut);

				this.updateTextSegment(text, selectionCut + 1);
			} catch (err) {
				console.error('Error [SegmentEditor/addInvertExclamation]', err);
			}
		},
		addInvertInterrogation() {
			try {
				let text;
				let selectionCut;
				if (this.getIsTranslate) {
					text = this.$refs['textTranslate'].value;
					selectionCut = this.$refs['textTranslate'].selectionStart;
				} else {
					text = this.$refs['text'].value;
					selectionCut = this.$refs['text'].selectionStart;
				}
				text = text.substring(0, selectionCut) + '¿' + text.substring(selectionCut);
				this.updateTextSegment(text, selectionCut + 1);
			} catch (err) {
				console.error('Error [SegmentEditor/addInvertInterrogation]', err);
			}
		},
		addMusicSymbol() {
			let positionCursor = null;
			try {
				let text, selectionStart, selectionEnd;
				if (this.getIsTranslate) {
					text = this.$refs['textTranslate'].value;
					selectionStart = this.$refs['textTranslate'].selectionStart;
					selectionEnd = this.$refs['textTranslate'].selectionEnd;
				} else {
					text = this.$refs['text'].value;
					selectionStart = this.$refs['text'].selectionStart;
					selectionEnd = this.$refs['text'].selectionEnd;
				}
				if (selectionStart == selectionEnd) {
					text = [text.slice(0, selectionStart), '♪', text.slice(selectionStart)].join('');
					positionCursor =
						text.substring(0, selectionStart).length + text.substring(selectionStart, selectionEnd).length + 1;
				} else {
					text =
						text.substring(0, selectionStart) +
						'♪<i>' +
						text.substring(selectionStart, selectionEnd) +
						'</i>♪' +
						text.substring(selectionEnd, text.length);

					const MUSIC_AND_ITALIC_LENGTH = 9;
					positionCursor = selectionEnd + MUSIC_AND_ITALIC_LENGTH;
				}
				this.updateTextSegment(text, positionCursor);
			} catch (err) {
				console.error('Error [SegmentEditor/addMusicSymbol]', err);
			}
		},
		addAudioDescription() {
			try {
				let text;
				let selectionStart;
				let selectionEnd;
				if (this.getIsTranslate) {
					text = this.$refs['textTranslate'].value;
					selectionStart = this.$refs['textTranslate'].selectionStart;
					selectionEnd = this.$refs['textTranslate'].selectionEnd;
				} else {
					text = this.$refs['text'].value;
					selectionStart = this.$refs['text'].selectionStart;
					selectionEnd = this.$refs['text'].selectionEnd;
				}

				let text1 = text.slice(0, selectionStart);
				let audioDescripton = text.slice(selectionStart, selectionEnd);
				let text2 = text.slice(selectionEnd, text.length);
				text = text1 + '[#' + audioDescripton + '#]' + text2;

				// If selectionStart != selectionEnd then exist a word selected.
				if (selectionStart != selectionEnd) {
					this.cursorPosition = text1.length + audioDescripton.length;
					text = text.replace(`[#`, `[`);
					text = text.replace(`#]`, `]`);
				} else {
					this.cursorPosition = selectionStart + 2;
					this.$store.commit('controller/setAudioDescriptionPopup', true);
				}
				this.updateTextSegment(text, this.cursorPosition);
			} catch (err) {
				console.error('Error [SegmentEditor/addInvertExclamation]', err);
			}
		},
		addAnnoucerToSegment(annoucerName) {
			try {
				let textAreaRef = this.getIsTranslate ? this.$refs['textTranslate'] : this.$refs['text'];
				let text = textAreaRef.value;
				const selectionCut = textAreaRef.selectionStart;
				text = text.substring(0, selectionCut) + ` ${annoucerName} ` + text.substring(selectionCut);
				textAreaRef.selectionStart = textAreaRef.selectionStart + annoucerName.length + 1;
				textAreaRef.selectionEnd = textAreaRef.selectionStart;
				this.updateTextSegment(text);
			} catch (err) {
				console.error('Error [SegmentEditor/addAnnoucerToSegment]', err);
			}
		},
		handleKeyDown(event) {
			if (event.key === 'Delete' || event.key === 'Backspace') {
				const textarea = this.getIsTranslate ? this.$refs.textTranslate : this.$refs.text;
				const text = textarea.value;
				const selectionStart = textarea.selectionStart;

				// Trata a remoção do ponto no final do segmento.
				const positionToVerify = event.key === 'Delete' ? selectionStart : selectionStart - 1;
				if (selectionStart === text.length || (event.key === 'Delete' && selectionStart === text.length - 1)) {
					if (['.', '?', '!', '¿', '¡'].includes(text[positionToVerify])) {
						let nextSegment = this.getNextSegment;
						if (this.getIsTranslate) {
							nextSegment.translate_text =
								nextSegment.translate_text.charAt(0).toLowerCase() + nextSegment.translate_text.slice(1);
						} else {
							nextSegment.text = nextSegment.text.charAt(0).toLowerCase() + nextSegment.text.slice(1);
						}
						this.updateSegment(nextSegment);
					}
					return;
				}

				if (['.', '?', '!', '¿', '¡'].includes(text[positionToVerify])) {
					// Encontre o índice do próximo caractere não vazio
					let nextCharIndex = event.key === 'Delete' ? selectionStart + 1 : selectionStart;
					while (nextCharIndex < text.length && /\s/.test(text[nextCharIndex])) {
						nextCharIndex++;
					}

					// Verifique se o próximo caractere é uma letra maiúscula
					if (/[A-Z]/.test(text[nextCharIndex])) {
						const index = text.indexOf('\n');
						// Verifica se há quebra de linha no texto após o ponto
						if (index == selectionStart) {
							// Será necessário concatenar todo o texto com a letra seguinte do ponto minúscula
							let newText =
								text.slice(0, positionToVerify) + text[nextCharIndex].toLowerCase() + text.slice(nextCharIndex + 1);
							// Com o texto concatenado, adicionar a quebra de linha na posição onde estava
							newText = [newText.slice(0, index - 1), '\n', newText.slice(index - 1)].join('');
							this.updateTextSegment(newText, nextCharIndex - 2);
							event.preventDefault();
							return;
						} else {
							// Transforme a letra em minúscula
							const newText =
								text.slice(0, positionToVerify) +
								' ' +
								text[nextCharIndex].toLowerCase() +
								text.slice(nextCharIndex + 1);

							this.updateTextSegment(newText, nextCharIndex - 2);
							event.preventDefault();
							return;
						}
					}
				}
			}
		},
	},
};
</script>

<style lang="scss" scopped>
@import '../../app.scss';

@mixin text-input {
	display: flex;
	flex-direction: row;
	height: 100%;
	width: 100%;
	padding: 0px 5px 0px 5px;
	position: relative;

	textarea {
		@include glass;
		height: 100%;
		color: $font-color;
		text-align: center;
		display: flex;
		padding-top: 1.5%;
		width: 95%;
	}

	.cps-per-line {
		margin-left: 3px;
		width: 15%;
		height: 100%;
	}
}

// END text-input

@mixin top-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: auto;
	box-sizing: border-box;

	> div {
		width: 25%;
		min-width: 25%;
	}

	button {
		@include glass;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 5%;
		height: 70%;
		padding: 2px;
		color: $font-color;
		margin-right: 2%;
	}
}

.grid-segment-editor {
	font-size: 12px;
	padding: 2px;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 0.5fr 1fr 1fr 1fr;
	grid-template-columns: 0.5fr 1fr 2.5fr 2.5fr;
	grid-template-areas:
		'info-area  time-area top-bar-text-area  top-bar-translate-area loader-signal'
		'info-area  time-area text-area     translate-area  .'
		'info-area  time-area text-area     translate-area  .'
		'info-area  time-area text-area     translate-area  .';

	.info-area {
		display: flex;
		flex-direction: column;
		grid-area: info-area;
		width: 100%;
		height: 100%;
		align-items: flex-start;
		justify-content: center;
	}

	.time-area {
		grid-area: time-area;
		width: 100%;
		min-height: 100%;
	}

	.top-bar-text-area {
		@include top-bar;
		grid-area: top-bar-text-area;
	}

	.top-bar-translate-area {
		@include top-bar;
		grid-area: top-bar-translate-area;
	}

	.text-area {
		grid-area: text-area;
		@include text-input;
	}

	.translate-area {
		grid-area: translate-area;
		@include text-input;
	}

	.loader-signal {
		grid-area: loader-signal;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		z-index: 5;

		span {
			width: 20px;
			height: 20px;
		}
	}
}

.grid-segment-editor-2 {
	font-size: 12px;
	padding: 10px;
	width: 100%;
	height: 98%;
	display: grid;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	grid-template-columns: 0.5fr 1fr 2.5fr 0.5fr;
	grid-template-areas:
		'info-area  time-area top-bar-text-area  loader-signal'
		'info-area  time-area text-area     text-area '
		'info-area  time-area text-area     text-area '
		'info-area  time-area text-area     text-area ';

	.info-area {
		display: flex;
		flex-direction: column;
		grid-area: info-area;
		width: 100%;
		height: 100%;
		margin-left: -10px;
	}

	.time-area {
		grid-area: time-area;
	}

	.top-bar-text-area {
		@include top-bar;
		grid-area: top-bar-text-area;
	}

	.top-bar-translate-area {
		@include top-bar;
		grid-area: top-bar-translate-area;
	}

	.text-area {
		grid-area: text-area;
		@include text-input;
	}

	.translate-area {
		grid-area: translate-area;
		@include text-input;
	}

	.loader-signal {
		grid-area: loader-signal;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		z-index: 5;

		span {
			width: 20px;
			height: 20px;
		}
	}
}
</style>
