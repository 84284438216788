<template>
	<div class="error-notification">
		<b-alert
			:show="dismissCountDown"
			variant="danger"
			class="error-notification-alert"
			dismissible
			fade
			@dismissed="dismissCountDown = 0"
			@dismiss-count-down="countDownChanged"
		>
			<span class="text-error title">{{ title }}</span>
			<br />
			<span class="text-error">{{ message }}</span>
		</b-alert>
	</div>
</template>

<script>
export default {
	data() {
		return {
			title: 'Firebase Error',
			message: 'Erro ao atualizar o banco de dados',
			dismissSecs: 6,
			dismissCountDown: 0,
		};
	},
	computed: {
		getErrorNotification() {
			return this.$store.getters['controller/getErrorNotification'];
		},
	},
	watch: {
		getErrorNotification() {
			this.title = this.getErrorNotification.title || '';
			this.message = this.getErrorNotification.message || '';
			this.dismissSecs = this.getErrorNotification.dismissSecs || 6;
			this.showAlert();
		},
	},
	methods: {
		countDownChanged(dismissCountDown) {
			this.dismissCountDown = dismissCountDown;
		},
		showAlert() {
			if (this.cancelAutoHide) this.dismissCountDown = true;
			else this.dismissCountDown = this.dismissSecs;
		},
	},
};
</script>

<style lang="scss" scoped>
$invalid: #c24e4e;
.error-notification {
	position: fixed;
	bottom: 2%;
	right: 10px;
	max-width: 30%;
	z-index: 4;

	.error-notification-alert {
		background-color: $invalid;
	}

	.title {
		font-weight: bold !important;
	}

	.text-error {
		color: #ffff !important;
	}
}
</style>
