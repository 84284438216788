<template>
	<div>
		<b-spinner v-if="loading" class="center-spinner" label="Spinning"></b-spinner>
		<div class="container-fluid soundwave-utils">
			<div class="row">
				<div class="soundwave-zoom">
					<span class="actual-zoom">{{ getZoomRate }} x</span>
					<i class="fa fa-search-minus" aria-hidden="true" @click="setZoom(-zoomStep)"></i>
					<b-form-input
						id="range-1"
						v-model.number="zoom"
						type="range"
						:min="minZoom"
						:max="maxZoom"
						class="ms-2"
						:step="zoomStep"
					></b-form-input>
					<i class="fa fa-search-plus" aria-hidden="true" @click="setZoom(zoomStep)"></i>
				</div>
			</div>
		</div>
		<div id="waveform"></div>
		<div id="timeline"></div>
	</div>
</template>

<script>
import _ from 'lodash';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';

export default {
	data() {
		return {
			loading: true,
			wavesurfer: null,
			playing: false,
			currentTime: 0,
			selectColor: 'rgb(148, 70, 233, 0.15)',
			unselectColor: 'rgb(52, 58, 64, 0.4)',
			isScrolling: null,
			timeScroll: null,
			zoomStep: 30,
			minZoom: 60,
			maxZoom: 360,
			zoom: 60,
			lastSegmentRendered: null,
		};
	},
	computed: {
		// Create Soundwave parameters
		getMediaPeaksUrl() {
			return this.$store.getters['firebase/getMediaPeaksUrl'];
		},
		getMediaUrl() {
			return this.$store.getters['firebase/getMediaUrl'];
		},
		getSubtitles() {
			return this.$store.getters['firebase/getSubtitles'];
		},

		// Region CRUD
		getRegionToAdd() {
			return this.$store.getters['soundwave/getRegionToAdd'];
		},
		getRegionToDelete() {
			return this.$store.getters['soundwave/getRegionToDelete'];
		},
		getUpdateRegion() {
			return this.$store.getters['firebase/getUpdateRegion'];
		},
		getUpdateFlag() {
			return this.$store.getters['firebase/getUpdateFlag'];
		},

		// Controllers Events
		getPlaying() {
			return this.$store.getters['controller/getPlaying'];
		},
		getReplay() {
			return this.$store.getters['soundwave/getReplay'];
		},
		getRewindForward() {
			return this.$store.getters['soundwave/getRewindForward'];
		},
		getSeeked() {
			return this.$store.getters['soundwave/getSeeked'];
		},

		// Events parameters
		getDuration() {
			return this.$store.getters['video/getDuration'];
		},
		getUpdateTime() {
			return this.$store.getters['video/getUpdateTime'];
		},
		getCurrentTime() {
			return this.$store.getters['video/getCurrentTime'];
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getCurrentIndex() {
			return this.$store.getters['controller/getCurrentIndex'];
		},
		getVideoDuration() {
			return this.$store.getters['video/getDuration'];
		},
		getPlaybackRate() {
			return this.$store.getters['controller/getPlaybackRate'];
		},

		getZoomRate() {
			return this.zoom / this.minZoom;
		},
	},
	watch: {
		// Update events
		getCurrentIndex(newValue) {
			this.addRegions(newValue);
		},
		// Update region
		getUpdateFlag() {
			this.updateRegion(this.getUpdateRegion); // Quando recebe a flag faz o update da região em questão
		},
		// Delete region
		getRegionToDelete(newValue) {
			this.removeRegion(newValue);
		},
		// Add new region
		getRegionToAdd(newValue) {
			this.addOneRegion(newValue);
		},

		// Controllers events
		getPlaying() {
			this.renderSegmentByTime(this.getUpdateTime);
			if (this.getPlaying) {
				window.wavesurfer.play(this.getUpdateTime / 1000);
			} else {
				window.wavesurfer.pause();
			}
		},
		getReplay() {
			this.seekAndCenter(this.getReplay);
		},
		getRewindForward() {
			this.seekAndCenter(this.getRewindForward);
		},
		getSeeked() {
			this.renderSegmentByTime(this.getSeeked);
			this.seekAndCenter(this.getSeeked);
		},
		timeScroll() {
			this.renderSegmentByTime(this.timeScroll);
			this.seekAndCenter(this.timeScroll);
		},
		getPlaybackRate(rateValue) {
			this.setPlaybackRate(rateValue);
		},
		zoom(value) {
			this.zoomSoundwave(value);
		},
		getUpdateTime() {
			// Execute the renderSegmentByTime function every 1 second to avoid performance issues
			return _.debounce(() => {
				this.renderSegmentByTime(this.getUpdateTime);
			}, 1000);
		},
	},
	mounted() {
		setTimeout(() => {
			this.createWaveSurfer();
		}, 1000);
	},
	methods: {
		async createWaveSurfer() {
			/**
			 * @type {WaveSurfer}
			 */
			window.wavesurfer = WaveSurfer.create({
				container: document.querySelector('.soundwave'),
				waveColor: '#686868',
				progressColor: 'rgb(148, 70, 233)',
				responsive: true,
				scrollParent: true,
				barHeight: 0.8,
				cursorColor: '#f500008f',
				cursorWidth: 3,
				maxCanvasWidth: 32000,
				plugins: [
					RegionPlugin.create({
						regions: [],
					}),
					TimelinePlugin.create({
						container: '#timeline',
						fontSize: 12,
						notchPercentHeight: 50,
						primaryFontColor: '#fff',
						secondaryFontColor: '#fff',
						formatTimeCallback: this.secsToHMS,
						timeInterval: 1,
					}),
					CursorPlugin.create({
						showTime: true,
						opacity: 1,
						customShowTimeStyle: {
							'background-color': '#000',
							color: '#fff',
							padding: '2px',
							'font-size': '10px',
						},
					}),
				],
			});

			if (this.getMediaPeaksUrl && this.getMediaPeaksUrl != '') {
				await fetch(this.getMediaPeaksUrl)
					.then((response) => {
						if (!response.ok) {
							throw new Error('HTTP error ' + response.status);
						}
						return response.json();
					})
					.then((peaks) => {
						// Soundwave needs a reference to some url
						window.wavesurfer.load(process.env.VUE_APP_DUMMY_VIDEO_URL, peaks.data, false, this.getDuration);
					})
					.catch((e) => {
						console.error('error', e);
					});
			} else {
				window.wavesurfer.load(this.getMediaUrl);
				window.wavesurfer.setHeight(100);
			}

			window.wavesurfer.zoom(this.zoom);
			window.wavesurfer.setMute(true);

			// get div soundwave element
			const waveform = document.querySelector('.soundwave');
			// get height of div soundwave element
			const waveformHeight = waveform.offsetHeight;
			window.wavesurfer.setHeight(waveformHeight);

			window.wavesurfer.drawer.on('click', () => {
				setTimeout(() => {
					const currentTime = window.wavesurfer.getCurrentTime();
					this.$store.commit('video/setVideoTimeChange', currentTime * 1000);
				}, 50);
			});

			window.wavesurfer.on('error', function (e) {
				console.warn(e);
			});

			const scrollContainer = document.querySelector('wave');
			scrollContainer.addEventListener('wheel', (evt) => {
				evt.preventDefault();
				scrollContainer.scrollLeft += evt.deltaY;
			});

			scrollContainer.addEventListener(
				'scroll',
				() => {
					// Clear our timeout throughout the scroll
					window.clearTimeout(this.isScrolling);

					// Set a timeout to run after scrolling ends
					this.isScrolling = setTimeout(() => {
						// Run the callback
						let duration = this.getVideoDuration;
						let totalScroll = scrollContainer.scrollWidth;
						let actualScroll = scrollContainer.scrollLeft;
						let actualTime = (duration * actualScroll) / totalScroll;
						this.renderSegmentByTime(actualTime * 1000);
					}, 66);
				},
				{ passive: true }
			);

			this.addRegions(this.getCurrentIndex);
			let subtitle = this.findSubtitleById(this.getCurrentIndex);
			if (subtitle) this.seekAndCenter(subtitle.start);

			window.wavesurfer.play({
				start: this.getCurrentTime,
				end: this.getCurrentTime,
			});

			this.$nextTick(async () => {
				this.loading = false;
				this.$store.dispatch('setLoadingScreen', false);
				this.$store.dispatch('setLoadingStage', 5);
			});
		},
		addRegions: _.debounce(function (index) {
			this.drawRegions(index);
		}, 10),
		drawRegions(subtitleIndex) {
			if (!window.wavesurfer) return;
			let index = this.getSubtitles.findIndex((subtitle) => subtitle.id == subtitleIndex);

			// Extract 20 segments before and after the current segment
			const initialSegment = index - 20 <= 0 ? 0 : index - 20;
			const finalSegment = index + 20 > this.getSubtitles.length ? this.getSubtitles.length : index + 20;
			const segments = this.getSubtitles.slice(initialSegment, finalSegment);

			// Clear all regions and render the new ones
			window.wavesurfer.clearRegions();
			segments.every((segment) => {
				const isSelectedRegion = segment.id == this.getCurrentIndex ? true : false;
				let newRegion = window.wavesurfer.addRegion({
					id: parseInt(segment.id_reference),
					start: segment.start / 1000,
					end: segment.end / 1000,
					color: isSelectedRegion ? this.selectColor : this.unselectColor,
					drag: true,
					resize: true,
					loop: false,
					minLength: 0,
					maxLength: this.getDuration,
				});

				newRegion.on('update-end', () => {
					segment.start = newRegion.start * 1000;
					segment.end = newRegion.end * 1000;
					this.$store.dispatch('firebase/updateSegment', { segment: segment });
					this.$store.dispatch('firebase/setSavedFalse', { segment: segment });
				});

				return true;
			});
			// Add srt to new segment
			this.addSrtInRegions(segments);

			this.lastSegmentRendered = subtitleIndex;
		},
		addSrtInRegions(segments) {
			const regions = window.wavesurfer.regions.list;
			segments.forEach((segment) => {
				const segmentId = Number(segment.id_reference);
				for (const region in regions) {
					const regionId = Number(region);
					const regionRef = regions[regionId];
					if (regionId == segmentId) {
						if (this.getIsTranslate) {
							regionRef.element.innerHTML += `<div class="srtText"><span srt-id='${parseInt(segmentId)}'>${
								segment.translate_text
							}</span></div>`;
						} else {
							regionRef.element.innerHTML += `<div class="srtText"><span srt-id='${parseInt(segmentId)}'>${
								segment.text
							}</span></div>`;
						}
					}
				}
			});
		},
		updateRegion(segments) {
			//console.time('updateRegion Soundwave');
			for (let i = 0; i < segments.length; i++) {
				let segment = segments[i];
				let region = window.wavesurfer.regions.list[parseInt(segment.id_reference)];
				if (region) {
					region.update({
						start: segment.start / 1000,
						end: segment.end / 1000,
					});

					// search div with property id
					let divWithPropertyId = document.querySelector(`[srt-id='${parseInt(segment.id_reference)}']`);
					// Busca o elemento 'srtTExt' adicionado na criação da soundwave e faz update
					if (this.getIsTranslate) {
						divWithPropertyId.innerHTML = `${segment.translate_text}`; // Busca o elemente 'srtTExt' adicionado na criação da soundwave e faz update
					} else {
						divWithPropertyId.innerHTML = `${segment.text}`;
					}
				}
			}
			//console.timeEnd('updateRegion Soundwave');
		},
		removeRegion(segment) {
			let region = window.wavesurfer.regions.list[parseInt(segment.id_reference)];
			if (region) region.remove();
		},
		addOneRegion(segment) {
			//console.time('addOneRegion');
			const isSelectedRegion = segment.id == this.getCurrentIndex ? true : false;
			let newRegion = window.wavesurfer.addRegion({
				id: parseInt(segment.id_reference),
				start: segment.start / 1000,
				end: segment.end / 1000,
				color: isSelectedRegion ? this.selectColor : this.unselectColor,
				drag: true,
				resize: true,
				loop: false,
				minLength: 0,
				maxLength: this.getDuration,
			});

			newRegion.on('update-end', () => {
				segment.start = newRegion.start * 1000;
				segment.end = newRegion.end * 1000;
				this.$store.dispatch('firebase/updateSegment', { segment: segment });
				this.$store.dispatch('firebase/setSavedFalse', { segment: segment });
			});

			// add srt to new segment
			let region = document.querySelector(`[data-id='${parseInt(segment.id_reference)}']`);
			if (this.getIsTranslate) {
				region.innerHTML += `<div class="srtText"><span srt-id='${parseInt(segment.id_reference)}'>${
					segment.translate_text
				}</span></div>`;
			} else {
				region.innerHTML += `<div class="srtText"><span srt-id='${parseInt(segment.id_reference)}'>${
					segment.text
				}</span></div>`;
			}
			//console.timeEnd('addOneRegion');
		},
		secsToHMS(value) {
			const horas = Math.floor(value / 3600);
			const minutos = Math.floor((value % 3600) / 60);
			const ss = value % 60;

			const hh = String(horas).padStart(2, '0');
			const mm = String(minutos).padStart(2, '0');
			const ssFormatted = String(ss).padStart(2, '0');

			return `${hh}:${mm}:${ssFormatted}`;
		},
		seekAndCenter(time) {
			const duration = this.getDuration;
			time = time / (duration * 1000);
			if (time < 0 || time > 1) time = 0;
			window.wavesurfer.seekAndCenter(time);
		},
		renderSegmentByTime(time) {
			let sub = this.getSubtitles.find((subtitle) => {
				let subtitleFind = subtitle.start < time && subtitle.end > time;
				if (subtitleFind) {
					return subtitleFind;
				}
			});
			if (sub) this.addRegions(sub.id);
			else return;
		},
		findSubtitleById(index) {
			return this.getSubtitles.find((subtitle) => {
				return subtitle.id == index;
			});
		},
		setPlaybackRate(rate) {
			window.wavesurfer.setPlaybackRate(rate);
		},
		zoomSoundwave(value) {
			window.wavesurfer.zoom(value);
		},
		setZoom(value) {
			if (this.zoom + value > this.maxZoom || this.zoom + value < this.minZoom) return;
			this.zoom += value;
		},
	},
};
</script>

<style lang="scss">
@import '../app.scss';

// centralize spinner in middle of thee div
.center-spinner {
	position: relative;
	left: 50%;
}

.srtText {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 3px;
	background-color: rgba(255, 255, 255, 0.6);
	font-weight: bold;
	margin: 5px 20px 5px 20px;
	width: auto;
	height: auto;
	min-height: 25%;
	max-height: 25%;
	border-radius: 5px;
	box-sizing: border-box;

	span {
		font-size: 11px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #000;
	}
}

.wavesurfer-region {
	border: 1px solid rgba($color: #fff, $alpha: 0.1);
	border-radius: 8px;
}

.wavesurfer-handle-start,
.wavesurfer-handle-end {
	width: 10px !important;
	margin: 0 !important;
	display: flex;
	justify-content: center;
	align-items: center;

	&::after {
		content: '||';
		font-size: 0.6rem;
		font-weight: lighter !important;
	}
}

.wavesurfer-handle-start {
	background-color: rgba(255, 255, 255, 0.6) !important;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;

	&::after {
		color: #0009;
	}
}

.wavesurfer-handle-end {
	background-color: rgba(0, 0, 0, 0.6) !important;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;

	&::after {
		color: #fff4;
	}
}

.soundwave-zoom {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 180px;
	height: 100%;

	i {
		font-size: 15px;
		padding: 5px;
		color: #ffffff !important;
		cursor: pointer;
	}

	.custom-range::-webkit-slider-thumb {
		background-color: #333435 !important;
		border: none;
	}

	.custom-range::-webkit-slider-runnable-track {
		height: 6px;
		border-radius: 5px;
		background-color: #ffffff;
	}
}

.soundwave-utils {
	position: relative;
	max-height: 30px;
	font-size: 15px;

	.actual-zoom {
		min-width: 40px;
		padding: 0px 5px;
	}
}
</style>
