/* eslint-disable */

import * as FirebaseManager from '../../db/firebase-api';
import mergeSortByIdReference from '../../utils/merge_sort';
import SegmentTools from '../../utils/segmentTools';

import _ from 'lodash';
import axios from 'axios';

let TASK_URL, REVIEW_URL;
let FINALIZE_TASK_URL, FINALIZE_SUBTASK_URL, FINALIZE_ASSESSMENT_URL;
if (process.env.NODE_ENV === 'development') {
	// development environment
	TASK_URL = process.env.VUE_APP_TASK_FUNCTIONS_EMULATOR_URL || 'https://us-central1-task-dev-env.cloudfunctions.net';
	REVIEW_URL =
		process.env.VUE_APP_REVIEW_FUNCTIONS_EMULATOR_URL || 'https://us-central1-review-dev-env.cloudfunctions.net';
} else {
	// production environment
	TASK_URL = 'https://us-central1-task-v4.cloudfunctions.net';
	REVIEW_URL = 'https://us-central1-skylar-review.cloudfunctions.net';
}

FINALIZE_TASK_URL = `${TASK_URL}/finalizeTask`;
FINALIZE_SUBTASK_URL = `${TASK_URL}/subtask-finalizeSubtask`;
FINALIZE_ASSESSMENT_URL = `${TASK_URL}/finalizeAssessmentTask`;
const TASK_TOKEN = 'w4A7wZMF5t2SKl5kfOfk';

const state = {
	settings: null,
	inspectMode: false,
	task: null,
	userPreferences: null,
	audioDescriptionDictionary: null,
	lastTimeSaved: null,
	subtitles: null,
	subtitlesQA: null,
	filter: {
		autoApply: true,
		all: true,
		confirmed: true,
		notConfirmed: true,
		withErrors: true,
	},
	updateRegion: [],
	updateFlag: null,
	access: false,
	tagColor: ['#ff9800', '#4caf50', '#2196f3', '#9c27b0', '#ffeb3b', '#673ab7', '#3f51b5', '#f44336'],
};

const getters = {
	getAccess: (state) => {
		return state.access;
	},
	getInspectMode: (state) => {
		return state.inspectMode;
	},
	getTask: (state) => {
		return state.task;
	},
	getUserPreference: (state) => {
		return state.userPreferences;
	},
	// START TASK DEPENDECES
	getPerssionToSaveWithCriticalError: (state) => {
		if (!state.task.permissionToFinishWithError) {
			return false;
		} else {
			return true;
		}
	},
	getIsDivided: (state) => {
		return state.task.parent_id == null ? false : true;
	},
	getIsCC: (state) => {
		return state.task.subtitle_mode == 'cc' ? true : false;
	},
	getIsTranslate: (state) => {
		return !!state.task.translate_language;
	},
	getIsReviewQA: (state) => {
		if (
			(state.task.status === 'quality_transcription' || state.task.status === 'quality_translation') &&
			state.task.reviewed_by_qa == true
		) {
			return true;
		} else {
			return false;
		}
	},
	getMediaUrl: (state) => {
		return state.task ? state.task.media_url : '';
	},
	getMediaType: (state) => {
		return state.task.media_type;
	},
	getMediaPeaksUrl: (state) => {
		return state.task ? state.task.mediapeaks_url : '';
	},
	getCurrentLanguage: (state) => {
		return state.task.translate_language ? state.task.translate_language : state.task.source_language;
	},
	getVimeoData: (state) => {
		return {
			vimeo_uri: state.task.vimeo_uri,
			vimeo_embed_link: state.task.vimeo_embed_link,
		};
	},
	getReviewdByQa: (state) => {
		return state.task.reviewed_by_qa;
	},
	getisEmbedSubtitles: (state) => {
		return state.task.embeddedSubtitlesVideoFile;
	},
	getJoinPoints: (state) => {
		if (state.task.join_points) return state.task.join_points;
		return [];
	},
	// END TASK DEPENDENCES

	// SETTINGS DEPENDENCES
	getSettings: (state) => {
		return state.settings;
	},
	// END SETTINGS DEPENDENCES

	// SUBTITLES DEPENDENCES
	getAllSubtitles: (state) => {
		return state.subtitles;
	},
	getSubtitlesLength: (state) => {
		return state.subtitles.length;
	},
	getSubtitle: (state) => (id) => {
		return state.subtitles.find((subtitles) => subtitles.id === id);
	},
	getSubtitles: (state) => {
		if (state.filter.autoApply && !state.filter.all) {
			return state.subtitles.filter((subtitle) => {
				if (state.filter.confirmed && subtitle.is_saved) return true;
				if (state.filter.notConfirmed && !subtitle.is_saved) return true;
				if (state.filter.withErrors && subtitle.errors.type != '') return true;
				if (state.filter.bookmarks && subtitle.is_bookmark === true) return true;
				return false;
			});
		}
		return state.subtitles;
	},
	getSubtitleQAByIdReference: (state) => (id) => {
		return state.subtitlesQA.find((subtitles) => subtitles.id === id);
	},
	subtitleByTime: (getters) => (timeMs) => {
		return _.find(getters.subtitles, function (subtitle) {
			const startLessEqual = subtitle.start <= timeMs;
			const endMore = subtitle.end > timeMs;
			return startLessEqual && endMore;
		});
	},
	// END SUBTITLES DEPENDENCES

	// START AUDIO DESCRIPTION DICTIONARY DEPENDENCES
	getAudioDescriptionDictionary: (state) => {
		return state.audioDescriptionDictionary;
	},
	// END AUDIO DESCRIPTION DICTIONARY DEPENDENCES

	getUpdateRegion: (state) => {
		return state.updateRegion;
	},
	getUpdateFlag: (state) => {
		return state.updateFlag;
	},

	getTagColors: (state) => {
		return state.tagColor;
	},
	getLastTimeSaved(state) {
		return state.lastTimeSaved;
	},
};

const mutations = {
	setSettings(state, data) {
		state.settings = data;
	},
	setInspectMode(state, data) {
		state.inspectMode = data;
	},
	setTask(state, data) {
		state.task = data;
	},
	setUserPreference(state, data) {
		state.userPreferences = data;
	},
	setAudioDescriptionDictionary(state, data) {
		state.audioDescriptionDictionary = data;
	},
	setSubtitles(state, data) {
		state.subtitles = data;
	},
	setSubtitlesQA(state, data) {
		state.subtitlesQA = data;
	},
	updateSegment(state, segment) {
		let index = segment.id - 1; // Real index = segment.id - 1
		state.subtitles[index] = segment;
	},
	addSegment(state, segment) {
		state.subtitles.push(segment);
	},
	removeSegment(state, segment) {
		let index = state.subtitles.findIndex((subtitle) => subtitle.id_reference === segment.id_reference);
		state.subtitles.splice(index, 1);
	},
	mergeSortSubtitles(state) {
		let subtitles = mergeSortByIdReference(state.subtitles);

		let i = 1;
		subtitles.forEach((subtitle) => {
			subtitle.id = i;
			i++;
		});
		state.subtitles = subtitles;
	},
	clearUpdateRegion(state) {
		state.updateRegion = [];
	},
	updateRegion(state, segment) {
		state.updateRegion.push(segment);
	},
	setUpdateFlag(state) {
		state.updateFlag = Date.now();
	},
	setAcess(state) {
		state.access = true;
	},
	setFilter(state, filter) {
		state.filter = filter;
	},
	lastTimeSaved(state, time) {
		state.lastTimeSaved = time;
	},
};

const actions = {
	// START INITIALIZE EDITOR
	async getAccess({ commit }, accessId) {
		try {
			let access = await FirebaseManager.getAccess(accessId);
			if (access == undefined || access == null) throw `Valor inesperado para settings: ${access}`;
			commit('setAcess');
			return true;
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao solicitar "access". ', err);
			return false;
		}
	},
	async getInspectMode({ commit }, accessId) {
		try {
			const isSpectMode = await FirebaseManager.getInspect(accessId);
			commit('setInspectMode', isSpectMode);
			return isSpectMode;
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao solicitar "inspectMode". ', err);
		}
	},
	async getTask({ commit }) {
		try {
			let task = await FirebaseManager.getTask();
			if (task == undefined || task == null) throw `Valor inesperado para task: ${task}`;
			commit('setTask', task);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao solicitar "task". ', err);
		}
	},
	async getSettings({ commit }) {
		try {
			let settings = await FirebaseManager.getSettings();
			if (settings == undefined || settings == null) throw `Valor inesperado para settings: ${settings}`;
			commit('setSettings', settings);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao solicitar "settings". ', err);
		}
	},
	async getUserPreference({ commit }) {
		try {
			let userPreference = await FirebaseManager.getUserPreferences();
			commit('setUserPreference', userPreference);
			return userPreference;
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao solicitar "getUserPreferences". ', err);
			return false;
		}
	},
	async setUserPreferences({ commit, getters }, userPreference) {
		try {
			await FirebaseManager.setUserPreferences(userPreference);
			commit('setUserPreference', userPreference);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao solicitar "setUserPreferences". ', err);
			return false;
		}
	},
	async getSubtitles({ commit, getters }) {
		try {
			//console.time('getSubtitles')
			let subtitles = await FirebaseManager.getSubtitles();
			let getJoinPoints = getters.getJoinPoints;
			let tagColors = getters.getTagColors;

			// Check segment rules
			subtitles.forEach((segment) => {
				let newSegment = SegmentTools.checkErrors(segment, getters.getIsTranslate, {
					cpsRecomendationWarning: getters.getSettings.cps_recomendation_warning || false,
				});
				segment = newSegment;
				segment.start = Number(segment.start);
				segment.end = Number(segment.end);
				if (getJoinPoints.length > 0) {
					for (let i = 0; i < getJoinPoints.length; i++) {
						if (
							parseInt(segment.start) >= parseInt(getJoinPoints[i].start) &&
							parseInt(segment.end) <= parseInt(getJoinPoints[i].end)
						) {
							segment.tagColor = tagColors[i];
							break;
						}
					}
				}
			});

			if (subtitles == undefined || subtitles == null) throw `Valor inesperado para subtitles: ${subtitles}`;
			commit('setSubtitles', subtitles);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao solicitar "subtitles". ', err);
		}
	},
	async getSubtitlesQA({ commit }) {
		try {
			let subtitles = await FirebaseManager.getSubtitlesQA();
			if (subtitles == undefined || subtitles == null) throw `Valor inesperado para subtitles: ${subtitles}`;
			commit('setSubtitlesQA', subtitles);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao solicitar "subtitles". ', err);
		}
	},
	async getAudioDescription({ commit }) {
		try {
			let audioDescriptionDictionary = await FirebaseManager.getAudioDescription();
			if (audioDescriptionDictionary == undefined || audioDescriptionDictionary == null)
				throw `Valor inesperado para getAudioDescription: ${audioDescriptionDictionary}`;
			commit('setAudioDescriptionDictionary', audioDescriptionDictionary);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao solicitar "getAudioDescription". ', err);
		}
	},
	// END INITIALIZE EDITOR
	updateTask({ commit }, task) {
		try {
			commit('setTask', task);
			let result = FirebaseManager.updateTask(task);
			if (result == undefined || result == null) throw `Valor inesperado para task: ${result}`;
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar "task". ', err);
		}
	},
	updateContabilizeTime(_, time) {
		try {
			let result = FirebaseManager.updateContabilizeTime(time);
			if (result == undefined || result == null) throw `Valor inesperado para task: ${result}`;
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar "ContabilizeTime". ', err);
		}
	},

	setFilter({ commit }, filter) {
		commit('setFilter', filter);
	},
	updateSegment({ commit, getters, dispatch, rootGetters }, { segment }) {
		try {
			dispatch('undoRedo/setSnapshot', null, { root: true });
			dispatch('setRequestToSave', true, { root: true }); // LastUpdateSave

			let userPreferences = rootGetters['getUserPreferences'];
			// This function can be better implemented, it runs in about 1 second at most
			let nextSegment = getters.getSubtitle(segment.id + 1);
			let beforeSegment = getters.getSubtitle(segment.id - 1);

			// Check if time overlap in beforeSegment and nextSegment need to be updated
			let maxDuration = window.wavesurfer.getDuration() * 1000;
			let { segmentUpdated, beforeSegmentUpdated, nextSegmentUpdated } = SegmentTools.checkIfHasTimeOverlap(
				segment,
				beforeSegment,
				nextSegment,
				maxDuration,
				userPreferences
			);
			segment = segmentUpdated;
			beforeSegment = beforeSegmentUpdated;
			nextSegment = nextSegmentUpdated;

			// Verify if first latter in nextSegment need to be upper case
			if (nextSegment != null) nextSegment = SegmentTools.firstLatterToUpperCaseIfNeed(segment, nextSegment);

			// Check segment rules
			segment = SegmentTools.checkErrors(segment, getters.getIsTranslate, {
				cpsRecomendationWarning: getters.getSettings.cps_recomendation_warning || false,
			});

			// =====> CRITICAL AREA, DON'T CHANGE THE ORDER OF clearUpdateRegion AND setUpdateFlag <=====
			commit('clearUpdateRegion'); // Clear update region list
			commit('updateSegment', segment); // Update segments in memory
			commit('updateRegion', segment); // update soundwave region

			if (beforeSegment != null && beforeSegment != undefined) {
				commit('updateRegion', beforeSegment); // Stay this first for reflect the update in the soundwave more fast
				beforeSegment = SegmentTools.checkErrors(beforeSegment, getters.getIsTranslate, {
					cpsRecomendationWarning: getters.getSettings.cps_recomendation_warning || false,
				});
				commit('updateSegment', beforeSegment);
			}

			if (nextSegment != null && nextSegment != undefined) {
				commit('updateRegion', nextSegment);
				nextSegment = SegmentTools.checkErrors(nextSegment, getters.getIsTranslate, {
					cpsRecomendationWarning: getters.getSettings.cps_recomendation_warning || false,
				});
				commit('updateSegment', nextSegment);
			}
			// =====> END CRITICAL AREA <=====

			// Persist segments in firebase
			const promise = new Promise((resolve) => {
				resolve(FirebaseManager.updateSegment(segment));
			});

			promise.then(() => {
				dispatch('setRequestToSave', false, { root: true });
			});
			if (beforeSegment != null) FirebaseManager.updateSegment(beforeSegment);
			if (nextSegment != null) FirebaseManager.updateSegment(nextSegment);

			// Save latest updates
			dispatch('updateSavedTime');
			dispatch('updateLastSegmentSelect', segment.id);
			commit('setUpdateFlag'); // SET THE FLAG TO UPDATE IN SOUNDWAVE BEFORE ALL CHANGES
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar segmento.', err);
		}
	},
	updateSegmentBatchList({ commit, getters, dispatch, rootGetters }, { segmentBatchList }) {
		try {
			dispatch('undoRedo/setSnapshot', null, { root: true });
			dispatch('setRequestToSave', true, { root: true }); // LastUpdateSave
			const promise = new Promise((resolve) => {
				resolve(FirebaseManager.updateBatchSegmentList(segmentBatchList));
			});
			promise.then(() => {
				dispatch('setRequestToSave', false, { root: true });
			});

			// =====> CRITICAL AREA, DON'T CHANGE THE ORDER OF clearUpdateRegion AND setUpdateFlag <=====
			commit('clearUpdateRegion'); // Clear update region list
			for (let segment in segmentBatchList) {
				commit('updateSegment', segment); // Update segments in memory
				commit('updateRegion', segment); // update soundwave region
			}

			// Save latest updates
			dispatch('updateSavedTime');
			commit('setUpdateFlag'); // SET THE FLAG TO UPDATE IN SOUNDWAVE BEFORE ALL CHANGES
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar segmentBatchList.', err);
		}
	},
	setBookmarkSegment({ commit }, { segment, bookmark }) {
		try {
			segment.is_bookmark = bookmark;
			commit('updateSegment', segment); // Update segments in memory
			FirebaseManager.updateSegment(segment);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar segmento.', err);
		}
	},
	setSavedFalse({ commit }, { segment }) {
		try {
			segment.is_saved = false;
			commit('updateSegment', segment); // Update segments in memory
			FirebaseManager.updateSegment(segment);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar segmento.', err);
		}
	},
	addSegment({ dispatch, commit }, { segment }) {
		commit('addSegment', segment); // add segment in memory
		dispatch('soundwave/addRegion', segment, { root: true }); // Save action to undo
		commit('mergeSortSubtitles'); // sort segments in memory

		try {
			let result = FirebaseManager.addSegment(segment); // persist add segment in firebase
			if (result == undefined || result == null) throw `Valor inesperado para result: ${result}`;
			dispatch('updateSavedTime');
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao adicionar segmento.', err);
		}
	},
	removeSegment({ commit, dispatch }, { segment, isUndoRedo = false }) {
		dispatch('soundwave/removeRegion', segment, { root: true }); // Save action to undo
		commit('removeSegment', segment); // remove segment in memory
		commit('mergeSortSubtitles'); // sort segments in memory
		try {
			let result = FirebaseManager.removeSegment(segment); // persist remove segment in firebase
			if (result == undefined || result == null) throw `Valor inesperado para result: ${result}`;
			dispatch('updateSavedTime');
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao remover segmento.', err);
		}
	},
	addFeedback({ getters }, feedback) {
		try {
			feedback.taskId = getters.getTask.id;
			feedback.parentId = getters.getTask.parent_id;
			let result = FirebaseManager.addFeedback(feedback); // persist feedback in firebase
			if (result == undefined || result == null) throw `Valor inesperado para result: ${result}`;
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao adicionar feedback.', err);
		}
	},
	async finalizeTask({ getters, dispatch }, { feedback = [] }) {
		try {
			// parentId is used only in subtasks
			let task_id = getters.getIsDivided ? getters.getTask.parent_id : getters.getTask.id;
			let dispute = getters.getTask.dispute !== undefined ? getters.getTask.dispute : false;
			let subtask_id = getters.getTask.id;
			let system_used = 'editor';

			if (getters.getIsDivided) {
				await axios.post(FINALIZE_SUBTASK_URL, {
					task_id,
					subtask_id,
					system_used,
				});
			} else {
				await axios.post(FINALIZE_TASK_URL, {
					task_id,
					feedback,
					system_used,
					dispute,
				});
			}

			//await dispatch('removeBackupsFromTaskFinalized');
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao finalizar task.', err);
		}
	},
	async finalizeAssessment({ getters }, { feedback = null }) {
		try {
			await axios.post(`${FINALIZE_ASSESSMENT_URL}`, {
				task_id: getters.getTask.id,
				feedback,
			});
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao finalizar assessment.', err);
		}
	},
	updateSegmentSpendTime(_, { segment, spendTime }) {
		try {
			FirebaseManager.updateSegmentSpendTime(segment, spendTime);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar updateSavedTime.', err);
		}
	},
	updateSavedTime({ commit, getters }) {
		try {
			let time = Date.now();
			let subtitleLength = getters.getSubtitlesLength;
			commit('lastTimeSaved', time);
			FirebaseManager.updateSavedTime(time, subtitleLength);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar updateSavedTime.', err);
		}
	},
	updateLastSegmentSelect(_, segmentId) {
		try {
			FirebaseManager.updateLastSegmentSelect(segmentId);
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar updateLastSegmentSelect.', err);
		}
	},
	updateAcess() {
		try {
			let result = FirebaseManager.updateAccess(); // persist feedback in firebase
			if (result == false) throw `Valor inesperado para result: ${result}`;
			else return result; // return true
		} catch (err) {
			console.error('[FirabaseManager]: Erro ao atualizar acesso.', err);
		}
	},
	async saveAutomaticBackup(_, backup) {
		await FirebaseManager.saveAutomaticBackup(backup);
	},
	async getAutomaticBackup(_, backupIndex) {
		return await FirebaseManager.getAutomaticBackup(backupIndex);
	},
	async getAllOnlineBackups() {
		return await FirebaseManager.getAllOnlineBackups();
	},
	async setBackup({ dispatch }, backup) {
		dispatch('setLoadingScreen', true, { root: true });
		let firebaseList = await FirebaseManager.getSubtitles();
		let backupList = backup.allSegments;
		// Move as atualização para o firebase
		let result = await dispatch('compareArrays', { backupList, firebaseList });
		await FirebaseManager.setBackup(result)
			.then(() => {
				location.reload();
			})
			.catch((err) => {
				console.error(err);
			});
	},
	updateConfirmSegments({ getters }) {
		FirebaseManager.updateConfirmSegment(getters.getAllSubtitles);
	},
	updateFeedbackSegment(_, { segment, feedback }) {
		FirebaseManager.updateFeedbackSegment(segment, feedback);
	},
	compareArrays(_, { backupList, firebaseList }) {
		let elementsChanged = [];
		let elementsOnlyInbackupList = [];
		let elementsOnlyInfirebaseList = [];

		// Cria um dicionário com todos os elementos do backupList para facilitar as comparações
		let dictA = {};
		backupList.forEach((element) => {
			if (element.errors) delete element.errors; // Atribute used only in task front-end
			if (element._rowVariant) delete element._rowVariant; // Atribute used only in task front-end
			if (element.tagColor == undefined) delete element.tagColor; // Atribute used only in task front-end
			dictA[element.id_reference] = element;
		});

		firebaseList.forEach((element) => {
			if (dictA[element.id_reference]) {
				// O elemento está presente nos dois arrays
				if (
					dictA[element.id_reference].text != element.text ||
					dictA[element.id_reference].translate_text != element.translate_text ||
					dictA[element.id_reference].start != element.start ||
					dictA[element.id_reference].end != element.end ||
					dictA[element.id_reference].is_bookmark != element.is_bookmark ||
					dictA[element.id_reference].is_saved != element.is_saved
				) {
					// O elemento foi alterado
					elementsChanged.push(dictA[element.id_reference]);
				}
				// Remove o elemento do dicionário
				delete dictA[element.id_reference];
			} else {
				// O elemento está apenas no firebaseList
				elementsOnlyInfirebaseList.push(element);
			}
		});

		// Todos os elementos que sobraram no dicionário estão apenas no backupList
		for (let id_reference in dictA) {
			elementsOnlyInbackupList.push(dictA[id_reference]);
		}

		return {
			elementsChanged,
			elementsOnlyInbackupList,
			elementsOnlyInfirebaseList,
		};
	},
	removeBackupsFromTaskFinalized({ getters }) {
		let taskId = getters.getTask.id;
		let request = window.indexedDB.deleteDatabase(taskId);
		// Manipula os eventos de sucesso e erro
		request.onsuccess = function () {
			console.info('Banco de dados deletado com sucesso');
		};
		request.onerror = function (event) {
			console.error('Erro ao deletar banco de dados:', event.target.error);
		};
	},
	// Review comunnication
	async getAllQasJobsInTheSameProject({ getters }) {
		return await axios.post(`${REVIEW_URL}/getAllQasJobsInTheSameProject`, {
			project_id: getters.getTask.review_project_id,
		});
	},
	async updateTaskInfo(_, data) {
		const response = await axios.post(`${TASK_URL}/updateTaskReviewer`, data);
		return response;
	},
	async requestAccess({ rootState }, job) {
		if (job.is_divided) {
			// get access token for subtask
			const { data } = await axios.post(`${TASK_URL}/subtask-createSubtaskAccess`, {
				task_id: job.task_id,
				subtask_id: job.subtask_id,
				is_divided: job.is_divided,
				user_id: job.qa_reviewer,
				system: 'review',
			});
			return data;
		} else {
			// get access token for task
			const { data } = await axios.post(`${TASK_URL}/addAccessRegistry`, {
				task_id: job.task_id,
				user_id: job.qa_reviewer,
				system: 'review',
				token: TASK_TOKEN,
			});
			return data;
		}
	},
};

const namespaced = true;

export default {
	namespaced,
	state,
	mutations,
	actions,
	getters,
};
