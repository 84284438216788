const state = {
	replay: null,
	play_from_mili: null,
	updatedTime: 0,
	current_time: 0,
	last_time_watched: 0,
	forward_backward_interval: 2000,
	changeRewindForward: null,
	duration: 0,
	video_time_change: 0,
	loaded: false,
};

const mutations = {
	setUpdateTime(state, time) {
		state.updatedTime = time;
	},
	setCurrentTime(state, milliseconds) {
		// if (state.current_time === milliseconds) state.current_time = milliseconds + 1;
		// else state.current_time = milliseconds
		state.current_time = parseInt(milliseconds);
		state.last_time_watched = milliseconds;
	},
	setVideoTimeChange(state, milliseconds) {
		state.video_time_change = milliseconds;
	},
	setForwardBackwardInterval(state, interval) {
		state.forward_backward_interval = interval;
	},
	replay(state) {
		state.replay = Date.now();
	},
	setRewindForward(state, time) {
		state.changeRewindForward = time;
	},
	setDuration(state, duration) {
		state.duration = duration;
	},
	setLoaded(state) {
		state.loaded = true;
	},
};

const getters = {
	/**
	 * Gets the current time of any video
	 * according to its media_type
	 */
	getCurrentTime(state, rootState) {
		const mediaType = rootState.media_type;
		return mediaType ? state.current_time : 0;
	},
	getUpdateTime(state) {
		return state.updatedTime;
	},
	getLastTimeWatched(state) {
		return state.last_time_watched;
	},
	getReplay(state) {
		return state.replay;
	},
	getRewindForward(state) {
		return state.changeRewindForward;
	},
	getDuration(state) {
		return state.duration;
	},
	getVideoTimeChange(state) {
		return state.video_time_change;
	},
	getLoaded(state) {
		return state.loaded;
	},
};

const actions = {
	/**
	 * Call playFromMili passing active segment time_init
	 */
	replaySegment({ commit }) {
		commit('replay');
	},

	/**
	 * Call playFromMili passing -2 seconds
	 */
	rewind({ state, commit }) {
		const REWIND_TIME = state.forward_backward_interval;

		let updatedTime = state.updatedTime - REWIND_TIME;
		if (updatedTime < 0) updatedTime = 0;

		commit('setRewindForward', Number(updatedTime));
	},

	/**
	 * Call playFromMili passing +2 seconds
	 */
	forward({ state, commit }) {
		const FORWARD_TIME = state.forward_backward_interval;

		let updatedTime = state.updatedTime + FORWARD_TIME;

		commit('setRewindForward', Number(updatedTime));
	},
	setCurrentTimeManual({ commit }, milliseconds) {
		commit('setVideoTimeChange', milliseconds);
	},
	setLoaded({ commit }, loaded) {
		commit('setLoaded', loaded);
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
	namespaced: true,
};
