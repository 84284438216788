<template>
	<div
		:id="'rowSegment-' + source.id"
		:data-pk="source.id"
		:class="[getCurrentIndex === source.id ? 'rowSegment active' : 'rowSegment']"
		@click.exact="selectItem"
		@click.ctrl="addItemToBatchList"
		@mouseover="showOptions"
	>
		<div class="id-area">{{ source.id }}</div>
		<div class="time-area-row">{{ mili2srt(source.start) }}</div>
		<div class="time-area-row">{{ mili2srt(source.end) }}</div>
		<div :class="[getIsTranslate ? 'text-content-2' : 'text-content-2  only-transcription']">
			<div class="text-segment">
				<p v-html="changeTextView(source.text)"></p>
			</div>
		</div>
		<div v-if="getIsTranslate" class="text-content-2">
			<div class="text-segment">
				<p v-html="changeTextView(source.translate_text)"></p>
			</div>
		</div>
		<div class="buttons-area">
			<div class="rowButton">
				<button class="remove-button fa fa-trash" @click="removeSegment"></button>
				<button v-if="source.is_saved" class="saved-button fa fa-check-circle"></button>
			</div>
			<button
				v-if="showIfHasErrors"
				:id="'report-error-' + source.id"
				:class="
					getErrors.isCritical
						? 'error-button fa fa-exclamation-circle critical'
						: 'error-button fa fa-exclamation-circle '
				"
			>
				<b-popover :target="'report-error-' + source.id" triggers="hover" placement="bottom" custom-class="error-popup">
					<template #title>
						<div>
							<span>Erros no segmento</span>
						</div>
					</template>
					<div v-for="(error, indice) in getErrors.list" :key="'errors-' + source.id + indice">
						<span>
							<i v-if="error.isCritical" class="fa fa-exclamation-circle critical"></i>
							<i v-else class="fa fa-exclamation-circle"></i>
							{{ error.msg }}
						</span>
					</div>
				</b-popover>
			</button>
		</div>
	</div>
</template>

<script>
import { mili2srt } from '../../utils/convertTime';
import SegmentTools from '../../utils/segmentTools';

export default {
	name: 'ItemComponent',
	props: {
		index: {
			default: 0,
			type: Number,
		},
		source: {
			// here is: {uid: 'unique_1', text: 'abc'}
			type: Object,
			default() {
				return {};
			},
		},
	},
	computed: {
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getCurrentIndex() {
			return this.$store.getters['controller/getCurrentIndex'];
		},
		showIfHasErrors() {
			return SegmentTools.showIfSegmentHasErrors(this.source, this.getIsTranslate);
		},
		getErrors() {
			if (this.source == null) return '';
			let errors = {};
			if (this.getIsTranslate) {
				if (this.source.errors.translation.list.length > 0) errors = this.source.errors.translation;
				return errors;
			} else {
				if (this.source.errors.transcription.list.length > 0) errors = this.source.errors.transcription;
				return errors;
			}
		},
		getInspectMode() {
			return this.$store.getters['firebase/getInspectMode'];
		},
	},
	methods: {
		selectItem() {
			this.$store.commit('controller/setCurrentIndex', this.source.id);
		},
		addItemToBatchList() {
			// Verifica se o segmento já existe na lista e caso não, o adiciona
			if (this.$store.getters['batchSegment/getBatchList'].indexOf(this.source) == -1)
				this.$store.commit('batchSegment/addToBatchList', this.source);
			// Caso o segmento já exista na lista, ele é removido
			else this.$store.commit('batchSegment/removeFromBatchList', this.source.id);
		},
		removeSegment() {
			if (this.getInspectMode) return;
			this.$store.dispatch('setRemoveSegmentModalActive', {
				status: true,
				payload: parseInt(this.source.id),
			});
		},
		mili2srt(value) {
			return mili2srt(value);
		},
		showOptions() {
			if (!this.source.id) return;
			this.$store.dispatch('controller/showOptions', {
				show: true,
				indexSelect: this.source.id,
			});
		},
		changeTextView(value) {
			// change /n to <br>
			let text = value.replace(/\n/g, '<br>');
			// textSegment = textSegment.replace(/</g, '&lt;').replace(/&/g, '&amp;');

			return text;
		},
	},
};
</script>

<style scopped lang="scss">
@import '../../app.scss';

.rowSegment {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	box-sizing: border-box;
	border: 1px solid $secondary;
	background-color: $background-secondary;
	color: $font-color;
	width: 100%;
	height: 80px;
	max-height: 80px;
	min-height: 80px;
	font-size: 0.8rem;

	&:hover {
		cursor: pointer;
		background-color: $background;
	}
}

.rowSegment.active {
	border: 2px solid rgb(148, 70, 233);
	scroll-snap-align: center;
	scroll-snap-stop: always;
	position: relative;
}

// TEXTS IN TABLE
@mixin table-row {
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	text-align: center;
	border-right: 1px solid $secondary;
	max-height: 80px;
	height: 80px;
}

.id-area {
	@include table-row;
	position: relative;
	width: 5%;
	min-width: 5%;
}

.time-area-row {
	@include table-row;
	width: 10%;
	min-width: 10%;
}

.text-content-2 {
	@include table-row;
	width: 35%;
	min-width: 35%;
	padding: 0px;
	text-align: left;
}

.only-transcription {
	width: 70% !important;
}

.text-content-3 {
	@include table-row;
	position: relative;
	width: 70%;
	min-width: 70%;
}

.buttons-area {
	@include table-row;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 7%;
	min-width: 5%;
	max-width: 5%;
	border: none;

	.rowButton {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: auto;
		width: 100%;
	}
}

.text-segment {
	display: flex;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: auto;
	height: auto;
	min-height: 70px;
	max-height: 70px;

	p {
		text-align: center;
		padding: 5px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@mixin button-layout {
	width: 30px;
	height: 30px;
	border: none;
	background-color: transparent;
	transform: scale(1.2);
}

.remove-button {
	@include button-layout();
	color: $font-color;

	&:hover {
		cursor: pointer;
		transform: scale(1.5);
		color: $invalid !important;
	}
}

.error-button {
	@include button-layout();
	color: $warning;

	&:hover {
		cursor: pointer;
		color: $warning;
	}
}

.saved-button {
	@include button-layout();
	color: $confirm;
}

.error-popup {
	min-width: 450px !important;
	border-radius: 5px;
	text-align: left !important;

	.title-saved {
		width: auto;
		height: 100%;
		border-radius: 3px;
		padding: 5px 5px;
		font-weight: bold;
	}
}
</style>
