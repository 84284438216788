<template>
	<div>
		<div id="audio-description-popup" class="popup">
			<div v-if="suggestionFinded.length > 0" ref="suggestionList" class="suggestion-list">
				<div
					v-for="(suggestion, index) in suggestionFinded"
					:key="index"
					:class="{ suggestion: true, selected: suggestionSelected == index }"
				>
					<span @click="addWordBetweenBrackets(suggestion)">{{ suggestion }}</span>
				</div>
			</div>
			<div v-else class="suggestion-list">
				<div class="suggestion">
					<span>Nenhuma sugestão encontrada</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		fullText: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			listener: null,
			segmentText: '',
			textToFind: '',
			suggestionFinded: [],
			suggestionSelected: 0,
		};
	},
	computed: {
		getCurrentSegment() {
			return this.$store.getters['controller/getCurrentSegment'];
		},
		getAudioDescriptionDictionary() {
			return this.$store.getters['firebase/getAudioDescriptionDictionary'];
		},
	},
	watch: {
		fullText(value) {
			this.getWordInBrackets(value);
		},
		textToFind() {
			this.searchAudioDescription();
		},
		getCurrentSegment() {
			this.closePopup();
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.listener = async (event) => {
				if (event.keyCode === 27) {
					this.removeTagsReference();
					this.closePopup();
				}
				if (event.keyCode === 38) {
					event.preventDefault();
					if (this.suggestionSelected > 0) {
						this.suggestionSelected--;
					}
				}
				if (event.keyCode === 40) {
					event.preventDefault();
					if (this.suggestionSelected < this.suggestionFinded.length - 1) {
						this.suggestionSelected++;
					}
				}
				if (event.keyCode === 13) {
					event.preventDefault();
					let word = this.suggestionFinded[this.suggestionSelected];
					if (!word && !this.textToFind) {
						this.removeTagsReference();
						this.closePopup();
					} else {
						this.addWordBetweenBrackets(word ? word : this.textToFind);
					}
				}
			};

			document.addEventListener('keydown', this.listener);
		});
	},
	methods: {
		getWordInBrackets(fullText) {
			// Important: the space is important!
			let startIndex = fullText.indexOf('[#') + 2;
			let endIndex = fullText.indexOf('#]');
			this.textToFind = fullText.substring(startIndex, endIndex);
		},
		searchAudioDescription() {
			this.suggestionFinded = this.getAudioDescriptionDictionary.filter((sugestion) => {
				return sugestion.includes(this.textToFind);
			});
		},
		async addWordBetweenBrackets(word) {
			if (!this.fullText.includes(`[#${this.textToFind}#]`)) {
				console.error('Erro: a string não possui o match "[##]"');
				this.closePopup();
				return;
			}
			this.segmentText = this.fullText.replace(`[#${this.textToFind}#]`, `[${word}]`);
			this.$emit('textUpdate', this.segmentText);
			this.closePopup();
		},
		removeTagsReference() {
			this.segmentText = this.fullText.replace(`[#`, ``);
			this.segmentText = this.segmentText.replace(`#]`, ``);
			this.$emit('textUpdate', this.segmentText);
		},
		closePopup() {
			document.removeEventListener('keydown', this.listener);
			setTimeout(() => {
				this.$emit('close');
				this.$store.commit('controller/setAudioDescriptionPopup', false);
			}, 100);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/app.scss';

.popup {
	position: absolute;
	z-index: 3;
	top: -110px;
	right: 12%;
	max-height: 100px;
	min-height: 100px;
	width: 400px;
	box-sizing: border-box;
	color: black;
}

.input-group {
	display: flex;
	align-items: center;
}

.suggestion-list {
	overflow-y: scroll;
	max-height: 100px;
	min-height: 100px;
	width: 100%;
	background-color: $background-secondary;
	scroll-snap-type: y mandatory;
}

.suggestion {
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	border: 1px solid black;
	color: $font-color;
	background-color: rgba(255, 255, 255, 0.202);
	&:hover {
		cursor: pointer;
		background-color: rgba(255, 255, 255, 0);
	}
	span {
		width: 100%;
	}
}

.selected {
	background-color: rgba(255, 255, 255, 0);
	scroll-snap-align: center;
	scroll-snap-stop: always;
}
</style>
