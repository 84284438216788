<template>
	<div>
		<div class="revision-area">
			<b-button @click="closeFeedbackScreen">Video</b-button>
			<b-button v-if="getReviewdByQa && getIsReviewQA" @click="openFeedbackScreen">QA</b-button>
			<SynchronyMode v-if="!isTxtMode" />
		</div>
		<div v-show="!feedbackIsOpen" id="video-area" class="video-area">
			<default-video
				v-if="media_type == 'default'"
				:media_url="media_url"
				:playing="playing"
				:current-time="parseInt(currentTime)"
				:current_language="current_language"
				:subtitle="subtitle"
				:is_translate="is_translate"
				@loadeddata="initCurrentTime"
				@play="onPlay"
				@pause="onPause"
				@waiting="onWaiting"
				@seeking="onSeeking"
				@timeupdate="onTimeUpdate($event.target.currentTime * 1000)"
				@seeked="onSeeked($event.target.currentTime * 1000)"
			/>
			<vimeo-player
				v-if="media_type == 'vimeo'"
				:vimeo_embed_link="vimeoData.vimeo_embed_link"
				:playing="playing"
				:current-time="parseInt(currentTime)"
				:current_language="current_language"
				:subtitle="subtitle"
				:is_translate="is_translate"
				@loadeddata="initCurrentTime"
				@play="onPlay"
				@pause="onPause"
				@waiting="onWaiting"
				@seeking="onSeeking"
				@timeupdate="onTimeUpdate($event.seconds * 1000)"
				@seeked="onSeeked($event.seconds * 1000)"
				@playbackratechange="onPlaybackRateChange"
			/>
			<ReviewerTags />
			<JobIntervalTime />
		</div>
		<div v-if="feedbackIsOpen && getLoadingStages > 4" class="video-area">
			<ReportFeedbackPerSegment v-if="getReviewdByQa && getIsReviewQA" />
		</div>
		<div class="risize left"></div>
		<div class="risize right"></div>
		<SegmentBatchList v-if="$store.getters['batchSegment/getBatchList'].length > 0" />
	</div>
</template>

<script>
import DefaultVideo from './default';
import SynchronyMode from './SynchronyMode';
import ReviewerTags from './ReviewerTags';
import ReportFeedbackPerSegment from '@/components/FeedbackTools/ReportFeedbackPerSegment.vue';
import SegmentBatchList from '@/components/SegmentTable/SegmentBatchList.vue';

const VimeoPlayer = () => import('./vimeo.vue');

import JobIntervalTime from './TaskIntervalTime.vue';

export default {
	components: {
		DefaultVideo,
		VimeoPlayer,
		JobIntervalTime,
		SynchronyMode,
		ReviewerTags,
		ReportFeedbackPerSegment,
		SegmentBatchList,
	},
	data() {
		return {
			playing: false,
			currentTime: 0,
			firstTime: true,
			subtitle: null,
			feedbackScreen: false,
		};
	},
	computed: {
		is_translate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		media_type() {
			return this.$store.getters['firebase/getMediaType'];
		},
		media_url() {
			return this.$store.getters['firebase/getMediaUrl'];
		},
		current_language() {
			return this.$store.getters['firebase/getCurrentLanguage'];
		},
		vimeoData() {
			return this.$store.getters['firebase/getVimeoData'];
		},
		getUpdateTime() {
			return this.$store.getters['video/getUpdateTime'];
		},
		getCurrentSegment() {
			return this.$store.getters['controller/getCurrentSegment'];
		},
		getPlaying() {
			return this.$store.getters['controller/getPlaying'];
		},
		getReplay() {
			return this.$store.getters['video/getReplay'];
		},
		getRewindForward() {
			return this.$store.getters['video/getRewindForward'];
		},
		getVideoTimeChange() {
			return this.$store.getters['video/getVideoTimeChange'];
		},
		getDuration() {
			return this.$store.getters['video/getDuration'];
		},
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getIsDivided() {
			return this.$store.getters['firebase/getIsDivided'];
		},
		hasInterval() {
			return this.getIsDivided ? true : false;
		},
		current_subtitle_segment() {
			return this.$store.getters['firebase/subtitleByTime'](this.getUpdateTime);
		},
		getUpdateFlag() {
			return this.$store.getters['firebase/getUpdateFlag'];
		},
		isTxtMode() {
			return this.$store.getters['firebase/getTask'].type == 'txt' ? true : false;
		},
		feedbackIsOpen() {
			return this.$store.getters['feedback/feedbackIsOpen'];
		},
		getReviewdByQa() {
			return this.$store.getters['firebase/getReviewdByQa'];
		},
		getIsReviewQA() {
			return this.$store.getters['firebase/getIsReviewQA'];
		},
		getLoadingStages() {
			return this.$store.getters['getLoadingStages'];
		},
	},
	watch: {
		getPlaying() {
			this.playing = this.getPlaying;
		},
		getReplay() {
			if (!this.getCurrentSegment) return;
			const newValue = parseInt(this.getCurrentSegment.start);
			if (this.currentTime === newValue) this.currentTime = newValue + 1;
			else this.currentTime = newValue;
			this.$store.dispatch('soundwave/replay', parseInt(this.currentTime));
		},
		getRewindForward() {
			this.currentTime = this.getRewindForward;
			this.$store.dispatch('soundwave/rewindForward', this.currentTime);
		},
		getVideoTimeChange(newValue) {
			this.currentTime = newValue;
		},
		current_subtitle_segment(newValue) {
			this.subtitle = newValue;
		},
		getUpdateFlag() {
			// This is necessary because we need to update the cue when the video is paused
			this.subtitle = {};
			this.$nextTick(() => {
				this.subtitle = this.$store.getters['firebase/subtitleByTime'](this.getUpdateTime);
			});
		},
	},
	mounted() {
		window.addEventListener('beforeunload', (e) => {
			e.preventDefault();
		});

		// Manipula o tempo do player para que o subjob abra no intervalo de trabalho correto
		if (this.getIsDivided) {
			this.currentTime = this.getTask.start_time;
		} else return 0;
	},
	methods: {
		initCurrentTime() {
			this.currentTime = this.lastPlayedTime || 0;
			this.$store.dispatch('video/setLoaded', true);
			this.$store.dispatch('setLoadingStage', 2);
		},
		setVideoCurrentTime(milliseconds) {
			if (this.currentTime === milliseconds) this.currentTime = milliseconds + 1;
			else this.currentTime = milliseconds;
		},
		onPlay() {
			return this.$store.dispatch('controller/playPause', true);
		},
		onPause() {
			return this.$store.dispatch('controller/playPause', false);
		},
		onWaiting() {
			// this.$store.dispatch('controller/playPause', false);
		},
		onSeeking() {
			//this.$store.dispatch('controller/playPause', false);
		},
		isOutOfVideoInterval(milliseconds) {
			if (!this.hasInterval) return false;

			return milliseconds < this.getTask.start_time || milliseconds > this.getTask.end_time;
		},
		isOutOfVideoRange(milliseconds) {
			if (!this.hasInterval) return false;

			const OFFSET = 15000; // 15 seconds
			const start = Math.max(this.getTask.start_time - OFFSET, 0);
			const end = Math.min(this.getTask.end_time + OFFSET, this.getDuration * 1000);

			return milliseconds < start || milliseconds > end;
		},
		onTimeUpdate(milliseconds) {
			if (this.isOutOfVideoInterval(milliseconds)) {
				this.$store.dispatch('controller/setErrorNotification', {
					title: 'Fora do intervalo de trabalho!',
					message:
						'Você está trabalhando fora do intervalo de estipulado para essa revisão. Qualquer revisão acima ou abaixo do intervalo de trabalho não será considerada e/ou remunuerada.',
				});
			}

			if (this.isOutOfVideoRange(milliseconds)) {
				this.$store.dispatch('controller/playPause', false);
				this.playing = false;
				return;
			}
			this.$store.commit('video/setUpdateTime', milliseconds);
		},
		onSeeked(milliseconds) {
			if (this.isOutOfVideoRange(milliseconds)) {
				this.$nextTick(async () => {
					if (milliseconds < this.getTask.start_time) this.setVideoCurrentTime(this.getTask.start_time);
					if (milliseconds > this.getTask.end_time) this.setVideoCurrentTime(this.getTask.end_time);
					this.$store.commit('video/setCurrentTime', milliseconds);
					this.$store.commit('video/setUpdateTime', milliseconds);
					this.$store.dispatch('soundwave/seeked', milliseconds);
				});
				return;
			}

			if (this.firstTime) {
				this.$store.commit('video/setCurrentTime', milliseconds);
				this.firstTime = false;
			}
			this.$store.commit('video/setUpdateTime', milliseconds);
			this.$store.dispatch('soundwave/seeked', milliseconds);
		},
		onPlaybackRateChange(event) {
			this.$store.dispatch('controller/setPlaybackRate', event.playbackRate);
		},
		returnButtonClassSelected(status) {
			if (status) {
				return 'active';
			} else {
				return 'disabled';
			}
		},
		openFeedbackScreen() {
			this.feedbackScreen = true;
			this.$store.dispatch('feedback/openFeedbackModal');
		},
		closeFeedbackScreen() {
			this.feedbackScreen = false;
			this.$store.dispatch('feedback/closeFeedbackModal');
		},
	},
};
</script>

<style lang="scss" scopped>
@import '../../app.scss';

.revision-area {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: row;
	width: 100%;
	height: 10%;

	button {
		height: 70%;
		margin-left: 10px;
		z-index: 5;
	}

	.active {
		background-color: $color-auxiliary;
	}

	.disabled {
		background-color: $secondary;
	}
}

.video-area {
	width: 100%;
	height: 90%;
}

.risize {
	width: 8px;
	height: 100%;
	background-color: $secondary;
	position: absolute;
	cursor: ew-resize;
	display: flex;
	justify-content: center;
	align-items: center;

	&::after {
		content: '||';
		font-size: 0.6rem;
		font-weight: lighter !important;
	}
}

.left {
	left: -5px;
}

.right {
	right: -5px;
}
</style>
