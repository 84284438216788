const state = {
	batchList: [],
};

const getters = {
	getBatchList() {
		return state.batchList;
	},
};

const mutations = {
	addToBatchList(state, segment) {
		state.batchList.push(segment);
	},
	removeFromBatchList(state, segmentId) {
		const index = state.batchList.findIndex((segment) => segment.id === segmentId);
		state.batchList.splice(index, 1);
	},
	clearBatchList(state) {
		state.batchList = [];
	},
};

const actions = {
	async setBatchItalic({ dispatch, getters }) {
		const batchList = getters.getBatchList;
		if (batchList[0].translate_text) {
			batchList.forEach((segment) => {
				if (segment.translate_text.includes('<i>')) {
					segment.translate_text = segment.translate_text.replace('<i>', '');
					segment.translate_text = segment.translate_text.replace('</i>', '');
				} else {
					segment.translate_text = '<i>' + segment.translate_text + '</i>';
				}
			});
		} else {
			batchList.forEach((segment) => {
				if (segment.text.includes('<i>')) {
					segment.text = segment.text.replace('<i>', '');
					segment.text = segment.text.replace('</i>', '');
				} else {
					segment.text = '<i>' + segment.text + '</i>';
				}
			});
		}
		await dispatch('firebase/updateSegmentBatchList', { segmentBatchList: batchList }, { root: true });
	},
	async setBatchBold({ dispatch, getters }) {
		const batchList = getters.getBatchList;
		if (batchList[0].translate_text) {
			batchList.forEach((segment) => {
				if (segment.translate_text.includes('<b>')) {
					segment.translate_text = segment.translate_text.replace('<b>', '');
					segment.translate_text = segment.translate_text.replace('</b>', '');
				} else {
					segment.translate_text = '<b>' + segment.translate_text + '</b>';
				}
			});
		} else {
			batchList.forEach((segment) => {
				if (segment.text.includes('<b>')) {
					segment.text = segment.text.replace('<b>', '');
					segment.text = segment.text.replace('</b>', '');
				} else {
					segment.text = '<b>' + segment.text + '</b>';
				}
			});
		}
		await dispatch('firebase/updateSegmentBatchList', { segmentBatchList: batchList }, { root: true });
	},
	async setBatchUnderline({ dispatch, getters }) {
		const batchList = getters.getBatchList;
		if (batchList[0].translate_text) {
			batchList.forEach((segment) => {
				if (segment.translate_text.includes('<u>')) {
					segment.translate_text = segment.translate_text.replace('<u>', '');
					segment.translate_text = segment.translate_text.replace('</u>', '');
				} else {
					segment.translate_text = '<u>' + segment.translate_text + '</u>';
				}
			});
		} else {
			batchList.forEach((segment) => {
				if (segment.text.includes('<u>')) {
					segment.text = segment.text.replace('<u>', '');
					segment.text = segment.text.replace('</u>', '');
				} else {
					segment.text = '<u>' + segment.text + '</u>';
				}
			});
		}
		await dispatch('firebase/updateSegmentBatchList', { segmentBatchList: batchList }, { root: true });
	},
};

const namespaced = true;

export default {
	namespaced,
	state,
	mutations,
	actions,
	getters,
};
