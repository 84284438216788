const state = {
	feedbackIsOpen: false,
};

const getters = {
	feedbackIsOpen(state) {
		return state.feedbackIsOpen;
	},
};

const mutations = {
	setFeedbackIsOpen(state, status) {
		state.feedbackIsOpen = status;
	},
};

const actions = {
	openFeedbackModal({ commit }) {
		commit('setFeedbackIsOpen', true);
	},
	closeFeedbackModal({ commit }) {
		commit('setFeedbackIsOpen', false);
	},
};

const namespaced = true;

export default {
	namespaced,
	state,
	mutations,
	actions,
	getters,
};
