<template>
	<div v-if="false" class="firebase-notification">
		<b-alert v-model="showDismissibleAlert" variant="warning" dismissible>
			<p>Modo offline desativado. Certifique-se que este computador é confiavél antes de ativar essa opção!</p>
			<p>Para ativar o modo offiline <b-link>clique aqui</b-link>.</p>
		</b-alert>
	</div>
</template>

<script>
import { H } from 'highlight.run';
import Smartlook from 'smartlook-client';
import { setToCCMode, setToAssessmentMode } from '@/configs/rules';

export default {
	data() {
		return {
			taskId: '1639072877877',
			showDismissibleAlert: true,
		};
	},
	computed: {
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getSubtitles() {
			return this.$store.getters['firebase/getSubtitles'];
		},
		getSettings() {
			return this.$store.getters['firebase/getSettings'];
		},
		isLoaded() {
			if (this.getTask != null && this.getSettings != null && this.getSubtitles != null) {
				return true;
			}
			return false;
		},
		getIsCC() {
			return this.$store.getters['firebase/getIsCC'];
		},
		isAssessment() {
			return this.getTask.assessment_id ? true : false;
		},
	},
	watch: {
		isLoaded(newValue) {
			// Emit complet loading event
			if (newValue) {
				this.$store.dispatch('setLoadingStage', 1);
			}
		},
		getTask(value) {
			if (value != null) {
				const taskId = value.id;
				// get id param from url
				const urlParams = new URLSearchParams(window.location.search);
				const access_id = urlParams.get('id');
				// identify user in highlight.io
				H.identify(taskId, {
					access_id: access_id || 'not-found',
				});

				// identify user in smartlook
				if (Smartlook.initialized()) {
					Smartlook.identify(taskId, {
						access_id: access_id || 'not-found',
					});
				}
			}
		},
		getSettings(settings) {
			if (!settings) return;

			// Highlight.IO
			if (settings.highlight_io_options) {
				H.options = {
					...H.options,
					...settings.highlight_io_options,
				};
			}

			if (settings.highlight_io_enabled) {
				H.start({
					silent: process.env.NODE_ENV === 'production',
				});
			} else {
				H.stop();
			}

			// Smartlook
			if (settings.smartlook_enabled) {
				if (!Smartlook.initialized()) {
					const init = Math.floor(Math.random() * 10);
					if (init < 5)
						// 	Entre 0 e 4 aguarda 30 segundos para iniciar
						window.setTimeout(Smartlook.init(process.env.VUE_APP_SMARTLOOK_PROJECT_KEY), 30000);
					// Entre 5 e 7 inicia imediatamente
					else if (init >= 5 && init < 8) Smartlook.init(process.env.VUE_APP_SMARTLOOK_PROJECT_KEY)();
				}
				Smartlook.restart();
			} else {
				if (Smartlook.initialized()) Smartlook.stop();
			}
		},
	},
	async created() {
		await this.$store.dispatch('firebase/getTask'); // Get Tasks

		// Change de rules to CC or Assessment mode
		// @dev: this changes need to be done after the tasks infos are loaded and before the getSubtitles is called
		if (this.getIsCC) setToCCMode(); // Change to CC mode settings
		if (this.isAssessment) setToAssessmentMode(); // Change to Assessment mode settings

		await this.$store.dispatch('firebase/getUserPreference'); // Get all user preferences logged
		await this.$store.dispatch('firebase/getSettings'); // Get Settings
		await this.$store.dispatch('firebase/getSubtitles'); // Get Subtitles
		await this.$store.dispatch('firebase/getSubtitlesQA'); // Get Subtitles saved before QA
		await this.$store.dispatch('firebase/getAudioDescription'); // Get audio description
		this.$store.dispatch('undoRedo/setSnapshot', null, { root: true }); // Create de first snapshot
	},
};
</script>

<style>
.firebase-notification {
	position: fixed;
	bottom: 10px;
	right: 10px;
	max-width: 30%;
	z-index: 3;
}
</style>
