<template>
	<b-modal
		id="announcer-modal"
		title="Gerenciador locutores"
		cancel-title="Cancelar"
		ok-title="Salvar"
		@ok="saveAnnoucer"
	>
		<b-container fluid>
			<b-row class="mb-2">
				<b-button @click="addAnnoucer">Adicionar</b-button>
			</b-row>
			<b-row class="mb-2" align-h="center">
				<b-col cols="2"></b-col>
				<b-col cols="5" align-h="center"><b>Locutor</b></b-col>
				<b-col cols="5" align-h="center"><b>Atalho</b></b-col>
			</b-row>
			<b-row v-for="(annoucer, index) in announcers" :key="annoucer.name" class="mb-2" align-h="center">
				<b-col cols="2"><b-button @click="removeAnnoucer(index)">X</b-button></b-col>
				<b-col cols="5">
					<b-form-input v-model="annoucer.name" autofocus debounce="1000"></b-form-input>
				</b-col>
				<b-col cols="5">
					<b-row align-h="center">
						<span class="trigger">
							<i class="tecla-trigger">ALT</i>
							<b> + </b>
						</span>
						<div class="inputItem">{{ index }}</div>
					</b-row>
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
import _ from 'lodash';
export default {
	data() {
		return {
			announcers: [],
		};
	},
	computed: {
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
	},
	created() {
		this.announcers = this.getTask.announcers ? this.getTask.announcers : [];

		const element = window;
		element.addEventListener(
			'keydown',
			_.debounce((e) => {
				let code = e.keyCode;
				let key = e.key;
				// prevent ALT default
				if (code == 18) {
					e.preventDefault();
				}

				if (code >= 48 && code <= 57 && e.altKey) {
					e.preventDefault();
					if (key < this.announcers.length) {
						let annoucerName = this.announcers[key].name;
						if (!annoucerName || annoucerName == '') return;
						this.$store.dispatch('controller/setAnnoucer', annoucerName);
					}
				}
			}, 500)
		);
	},
	beforeDestroy() {
		const element = window;
		element.removeEventListener('keydown');
	},
	methods: {
		addAnnoucer() {
			if (this.announcers.length >= 10) return;
			this.announcers.push({ name: '' });
		},
		removeAnnoucer(index) {
			this.announcers.splice(index, 1);
			this.saveAnnoucer();
		},
		saveAnnoucer() {
			let task = this.getTask;
			task.announcers = this.announcers;
			this.$store.dispatch('firebase/updateTask', task);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '../../app.scss';

.trigger {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20%;
	margin-right: 10px;
}

.tecla-trigger {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(53, 52, 52);
	border: 1px solid rgb(164, 163, 163);
	border-radius: 5px;
	box-shadow: rgb(35, 35, 35) 0px 0px 0px 1px;
	height: auto;
	color: #fff;
	padding: 2px;
	width: 100%;
	margin-right: 10px;
	margin-left: 10px;
	font-family: 'Times New Roman', Times, serif;
	font-style: initial;
}

.inputItem {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(53, 52, 52);
	border: 1px solid rgb(164, 163, 163);
	border-radius: 5px;
	box-shadow: rgb(35, 35, 35) 0px 0px 0px 1px;
	height: auto;
	color: #fff;
	padding: 2px;
	width: 15%;
	margin-left: 10px;
	font-family: 'Times New Roman', Times, serif;
	font-style: initial;
	text-align: center;
}
</style>
