<template>
	<b-modal
		id="save-segment-with-critical-error-modal"
		:visible="show"
		refs="removeSegmentModal"
		title="Deseja salvar esse segmento com erro crítico?"
		cancel-title="Cancelar"
		ok-title="Sim"
		@ok="saveSegment"
		@close="closeSaveSegmentModal"
		@hide="closeSaveSegmentModal"
	>
		<span class="critical"
			><b
				>Este job está configurado para aceitar salvamentos com erro crítico, tem certeza que deseja continuar?</b
			></span
		>
	</b-modal>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			indexToDelete: null,
		};
	},
	computed: {
		permissionToSaveSegmentWithErrorFLAG() {
			return this.$store.getters['controller/permissionToSaveSegmentWithErrorFLAG'];
		},
		getCurrentSegment() {
			return this.$store.getters['controller/getCurrentSegment'];
		},
	},
	watch: {
		permissionToSaveSegmentWithErrorFLAG(newValue, oldValue) {
			if (newValue != oldValue && newValue != null) this.show = true;
			else this.show = false;
		},
	},
	methods: {
		async saveSegment() {
			await this.$store.dispatch('controller/saveSegmentAction', this.getCurrentSegment);
		},
		closeSaveSegmentModal() {
			this.show = false;
			this.$store.dispatch('controller/saveSegmentFlag', null);
		},
	},
};
</script>

<style lang="scss" scoped></style>
