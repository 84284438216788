// SRT rules
export let MAX_CPS = 23;
export let CPS_RECOMMENDATION = 17;
export let MAX_TOTAL_CARACTERES = 76;
export let MAX_CARACTERES_PER_LINE = 38;
export let MAX_CARACTERES_PER_LINE_BLOCK = true; // Set critical error if line has more than 38 characters
export let MAX_ROWS_PER_SEGMENT = 2;
export let MAX_DURATION_PER_SEGMENT = 8; // IN SECONDS
export let REVIEW_MODE_CC = false;

// Backup
export let MAX_BACKUPS = 6;
export let BACKUPS_INTERVAL = 10; // IN MINUTES

export function setToCCMode() {
	// CC rules
	MAX_CPS = 26;
	CPS_RECOMMENDATION = 17;
	MAX_TOTAL_CARACTERES = 64;
	MAX_CARACTERES_PER_LINE = 32;
	MAX_ROWS_PER_SEGMENT = 2;
	REVIEW_MODE_CC = true;
	MAX_DURATION_PER_SEGMENT = 999;
}

export function setToAssessmentMode() {
	MAX_CARACTERES_PER_LINE_BLOCK = true;
}
