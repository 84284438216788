<template>
	<b-container fluid :class="`layout-${layoutStyle}`">
		<!-- Start Navbar -->
		<Navbar v-if="getLoadingStages >= 2" id="navbar" />

		<!-- Start SegmentManager-->
		<SegmentManager
			v-if="getLoadingStages >= 2 && isSrt"
			id="segment-manager"
			:loading="getLoadingStages >= 2 ? true : false"
			class="segment-manager"
		/>

		<TextEditor v-if="getLoadingStages >= 2 && !isSrt" id="segment-manager" class="segment-manager" />

		<!-- Start VideoManager -->
		<VideoManager v-show="getLoadingStages >= 1 && !hiddeVideo" id="video-manager" class="video-manager" />

		<!-- Start segments edition area-->
		<SegmentEditor v-if="getLoadingStages >= 3 && isSrt" class="segment-editor" />

		<!-- Start Soundwave -->
		<Soundwave v-if="getLoadingStages >= 4 && isSrt" id="soundwave" class="soundwave" />

		<TextSoundwave v-if="getLoadingStages && !isSrt" id="soundwave" class="soundwave" :only-soundwave="hiddeVideo" />

		<!-- Start Layout Modal -->
		<SettingsModal @aplyLayout="setLayout" />
		<AnnouncerModal />
		<!-- Start ControllerManager -->
		<SegmentTools v-if="isSrt" />

		<ErrorNotification />
		<ConfirmSegmentWithError v-if="isSrt" />
		<RemoveSegmentConfirmation v-if="isSrt" />
		<KPI v-if="isSrt" />
		<AutomaticBackup v-if="isSrt" />

		<!-- Video Tutorial -->
		<!-- <VideoTutorial v-if="getLoadingStages >= 5 && videoTutorial && isSrt" /> -->
	</b-container>
</template>

<script>
import Navbar from './Navbar/Navbar.vue';
import SegmentManager from './SegmentTable/SegmentManager.vue';
import VideoManager from './Video/VideoManager.vue';
import SegmentEditor from './SegmentEditor/SegmentEditor.vue';
import Soundwave from './Soundwave.vue';
import AnnouncerModal from '@/components/Modals/AnnouncerModal.vue';
import SegmentTools from './SegmentTable/SegmentTools.vue';
import ErrorNotification from './ErrorNotification/ErrorNotification.vue';
import ConfirmSegmentWithError from './Modals/ConfirmSegmentWithError.vue';
import RemoveSegmentConfirmation from './Modals/RemoveSegmentConfirmation';
import TextEditor from './TextEditor/TextEditor.vue';
import TextSoundwave from './TextEditor/Soundwave.vue';
// import VideoTutorial from './VideoTutorial/VideoTutorial.vue';
import KPI from '@/components/KPI.vue';
import AutomaticBackup from '@/components/AutomaticBackup.vue';
import SettingsModal from '@/components/Modals/SettingsModal.vue';
const interact = require('interactjs');

export default {
	components: {
		Navbar,
		SegmentManager,
		VideoManager,
		SegmentEditor,
		Soundwave,
		SegmentTools,
		ErrorNotification,
		ConfirmSegmentWithError,
		RemoveSegmentConfirmation,
		TextEditor,
		TextSoundwave,
		// VideoTutorial,
		AnnouncerModal,
		KPI,
		AutomaticBackup,
		SettingsModal,
	},
	data() {
		return {
			layoutSelect: 1,
			renderComponent: true,
			hiddeVideo: false,
			timeOut: 0,
			timeOutDefault: 600,
			// videoTutorial: true,
		};
	},
	computed: {
		layoutStyle() {
			return this.layoutSelect;
		},
		getDuration() {
			return this.$store.getters['video/getDuration'];
		},
		getLoadingStages() {
			return this.$store.getters['getLoadingStages'];
		},
		getIsTranslate() {
			return this.$store.getters['firebase/getIsTranslate'];
		},
		getTask() {
			return this.$store.getters['firebase/getTask'];
		},
		getAudioView() {
			return this.$store.getters['getAudioView'];
		},
		isSrt() {
			return this.getTask.type == 'txt' ? false : true;
		},
		getRevisionMode() {
			return this.$store.getters['controller/getRevisionMode'];
		},
		userPreferences() {
			return this.$store.getters['firebase/getUserPreference'];
		},
		getReviewdByQa() {
			return this.$store.getters['firebase/getReviewdByQa'];
		},
	},
	watch: {
		getLoadingStages() {
			if (this.getLoadingStages == 0) return;

			if (this.isSrt) {
				this.setLayout(this.userPreferences !== null ? this.userPreferences.layoutSelect : 1);
			} else {
				this.setLayout('txt');
			}
		},
		getAudioView() {
			if (!this.isSrt) this.setLayout('txt');
		},
		getRevisionMode(value) {
			if (value) this.setLayout();
			if (!value) {
				const layoutSaved = parseInt(localStorage.getItem('layoutSelect'));
				this.setLayout(layoutSaved || 1);
			}
		},
	},
	mounted() {
		// start timeout counter
		if (!this.getReviewdByQa) this.startInactivityMonitoring();

		// Abre o video tutorial
		// this.openVideoTutorial();

		// Função de clicar e arrastar div
		let div = document.querySelector('.video-manager');
		let minWidth = div.offsetWidth;
		interact('.video-manager').resizable({
			edges: { left: true, right: true },
			restrictEdges: {
				endOnly: true, // Somente permite redimensionamento a partir das bordas
			},
			restrictSize: {
				min: { width: minWidth }, // Tamanho mínimo igual ao tamanho original
			},
			listeners: {
				move: function (event) {
					let div = document.getElementById('video-area');
					div.style.pointerEvents = 'none';
					let { x, y } = event.target.dataset;

					x = (parseFloat(x) || 0) + event.deltaRect.left;
					y = parseFloat(y) || 0;

					Object.assign(event.target.style, {
						width: `${event.rect.width}px`,
						height: `${event.rect.height}px`,
					});

					Object.assign(event.target.dataset, { x, y });
				},
				end: function () {
					let div = document.getElementById('video-area');
					div.style.pointerEvents = 'auto';
				},
			},
		});
	},
	methods: {
		setLayout(value) {
			if (this.getRevisionMode) {
				this.layoutSelect = 7;
			} else if (value == 'txt') {
				if (this.getAudioView) {
					this.layoutSelect = -1;
					this.hiddeVideo = true;
				} else {
					this.layoutSelect = 0;
					this.hiddeVideo = false;
				}
			} else {
				this.getIsTranslate ? (this.layoutSelect = value) : (this.layoutSelect = value + 3);
			}
		},
		startInactivityMonitoring() {
			console.log('Start inactivity monitoring...');
			let taskPage = document.getElementById('app');
			this.timeOut = this.timeOutDefault;

			taskPage.addEventListener('click', () => {
				this.timeOut = this.timeOutDefault;
			});

			taskPage.addEventListener('mousemove', () => {
				this.timeOut = this.timeOutDefault;
			});

			taskPage.addEventListener('keypress', () => {
				this.timeOut = this.timeOutDefault;
			});

			setInterval(() => {
				this.timeOut = this.timeOut - 1;
				if (this.timeOut == 0) {
					window.history.back();
				}
			}, 1000);
		},
		// openVideoTutorial() {
		// 	// get in local storage
		// 	const videoTutorial = localStorage.getItem('videoTutorial');
		// 	if (videoTutorial == 'false') {
		// 		this.videoTutorial = false;
		// 	} else {
		// 		this.videoTutorial = true;
		// 	}
		// },
	},
};
</script>

<style lang="scss">
@import '../app.scss';

@mixin layout-base {
	padding: 0px !important;
	width: 100vw;
	height: 100vh;
	display: grid;
	overflow-x: hidden;
	overflow-y: hidden;

	.segment-manager {
		overflow: hidden !important;
		grid-area: segmentManager;
		min-width: 100%;
		min-height: 100%;
	}

	.navbar {
		@include fontConfig;
		grid-area: navbar;
		width: 100%;
		max-width: 100vw !important;
		height: 100%;
		background-color: $background;
		border: 1px solid $background-scrollbar;
		border-top: none;
		border-left: none;
		border-right: none;
		z-index: 100;

		a {
			color: $font-color;
		}
	}

	.video-manager {
		grid-area: videoManager;
		background-color: $primary;
		max-width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 25px;
		color: $font-color;
		padding: 0px 3px 0px 3px;
		border-left: 5px solid #080808;
		border-right: 5px solid #000000;
		border-bottom: 1px solid $background-scrollbar;
		box-sizing: border-box;
		touch-action: none;
		position: relative;
	}

	.segment-editor {
		grid-area: segmentEditor;
		background-color: $background;
		width: 100%;
		height: 100%;
		min-height: 100%;
		z-index: 3;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 25px;
		color: $font-color;
		border: 1px solid $background-scrollbar;
		border-left: none;
		border-right: none;
	}

	.soundwave {
		grid-area: soundwave;
		background-color: $background-secondary;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 25px;
		color: $font-color;
	}
}

// TXT LAYOUT
.layout--1 {
	@include layout-base;
	grid-template:
		'navbar navbar navbar'
		'segmentManager segmentManager segmentManager'
		'segmentManager segmentManager segmentManager'
		'soundwave soundwave soundwave';
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 6vh 60vh 17vh 17vh;
}

.layout-0 {
	@include layout-base;
	grid-template:
		'navbar navbar navbar'
		'segmentManager segmentManager videoManager'
		'segmentManager segmentManager videoManager'
		'soundwave soundwave soundwave';
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 6vh 60vh 17vh 17vh;
}

// SRT LAYOUT
.layout-1 {
	@include layout-base;
	grid-template:
		'navbar navbar navbar'
		'segmentManager segmentManager videoManager'
		'segmentEditor segmentEditor segmentEditor'
		'soundwave soundwave soundwave';
	grid-template-columns: 1fr 1fr 1.5fr;
	grid-template-rows: 6vh 60vh 15vh 19vh;
}

.layout-2 {
	@include layout-base;
	grid-template:
		'navbar navbar navbar'
		'videoManager segmentManager segmentManager '
		'segmentEditor segmentEditor segmentEditor'
		'soundwave soundwave soundwave';
	grid-template-columns: 1.5fr 1fr 1fr;
	grid-template-rows: 6vh 60vh 17vh 17vh;
}

.layout-3 {
	@include layout-base;
	grid-template:
		'navbar navbar navbar'
		'segmentManager segmentManager videoManager'
		'soundwave soundwave soundwave'
		'segmentEditor segmentEditor segmentEditor';

	grid-template-columns: 1fr 1fr 2fr;
	grid-template-rows: 6vh 60vh 17vh 17vh;
}

// SRT LAYOUT ONLY TRANSCRIPTION
.layout-4 {
	@include layout-base;
	grid-template:
		'navbar navbar navbar'
		'segmentManager segmentManager videoManager'
		'segmentEditor segmentEditor videoManager'
		'soundwave soundwave soundwave';
	grid-template-columns: 1fr 1fr 1.5fr;
	grid-template-rows: 6vh 60vh 17vh 17vh;
}

.layout-5 {
	@include layout-base;
	grid-template:
		'navbar navbar navbar'
		'videoManager segmentManager segmentManager '
		'videoManager segmentEditor segmentEditor'
		'soundwave soundwave soundwave';
	grid-template-columns: 1.5fr 1fr 1fr;
	grid-template-rows: 6vh 60vh 17vh 17vh;
}

.layout-6 {
	@include layout-base;
	grid-template:
		'navbar navbar navbar'
		'segmentManager segmentManager videoManager'
		'soundwave soundwave soundwave'
		'segmentEditor segmentEditor segmentEditor';

	grid-template-columns: 1fr 1fr 2fr;
	grid-template-rows: 6vh 60vh 17vh 17vh;
}

// revision mode layout
.layout-7 {
	@include layout-base;
	grid-template:
		'navbar navbar navbar'
		'segmentManager videoManager videoManager'
		'segmentEditor segmentEditor soundwave';

	grid-template-columns: 0fr 2fr 3fr;
	grid-template-rows: 6vh 75vh 17vh;
}
</style>
